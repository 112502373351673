import React, { createContext, useEffect, useState } from "react";

import { Paper, Stack } from "@mui/material";

import Stepper, { SetupStep } from "@/components/stepper/Stepper";

import ActiveStepPane from "./ActiveStepPane";

type SetupChecklistProps = {
  steps: SetupStep[];
  disableChangeStepOnClick?: boolean;
  disableGoToLastInvalidStep?: boolean;
  children?: React.ReactNode;
};

export const SetupChecklistContext = createContext({
  activeIndex: 0,
  prev: () => undefined,
  next: () => undefined,
  steps: [],
});

const useSetupChecklist = (
  steps: SetupStep[],
  disableGoToLastInvalidStep: boolean,
) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const prev = () => setActiveIndex((prev) => prev - 1);
  const next = () => setActiveIndex((prev) => prev + 1);

  useEffect(
    function updateActiveStepBasedOnStepsStatus() {
      if (disableGoToLastInvalidStep) {
        return;
      }
      let updatedIndex = -1;
      for (let i = 0; i < steps.length; i++) {
        if (steps[i].status !== "succeeded") {
          updatedIndex = i;
          break;
        }
      }
      if (updatedIndex === -1) {
        updatedIndex = steps.length - 1;
      }
      setActiveIndex(updatedIndex);
    },
    [steps, setActiveIndex, disableGoToLastInvalidStep],
  );

  return {
    activeIndex,
    setActiveIndex,
    prev,
    next,
  };
};

export const SetupChecklist: React.FC<SetupChecklistProps> = ({
  steps,
  disableGoToLastInvalidStep = false,
  disableChangeStepOnClick = false,
  children,
}: SetupChecklistProps) => {
  const { activeIndex, setActiveIndex, prev, next } = useSetupChecklist(
    steps,
    disableGoToLastInvalidStep,
  );

  const onClickStepper = (_: any, index: number) => {
    if (disableChangeStepOnClick) {
      return;
    }
    setActiveIndex(index);
  };

  let currentStep: JSX.Element;
  if (
    steps[activeIndex]?.disablePrevNextButtonPane == null ||
    steps[activeIndex]?.disablePrevNextButtonPane === true
  ) {
    currentStep = <ActiveStepPane>{children}</ActiveStepPane>;
  } else {
    currentStep = <>{children}</>;
  }

  return (
    <Stack
      display="grid"
      gap="1rem"
      gridTemplateColumns="2fr 3fr"
      alignItems="start"
    >
      <Paper sx={{ px: "2.5rem", py: "4rem" }}>
        <Stepper
          steps={steps}
          activeIndex={activeIndex}
          disableChangeStepOnClick={disableChangeStepOnClick}
          onClick={onClickStepper}
        />
      </Paper>
      <SetupChecklistContext.Provider
        value={{
          activeIndex: activeIndex,
          prev: prev,
          next: next,
          steps: steps,
        }}
      >
        {currentStep}
      </SetupChecklistContext.Provider>
    </Stack>
  );
};
