import { useTranslation } from "react-i18next";

import { useUserContext } from "@/utils/contexts/UserContext";

const useSimulationDataPerUserHeaders = () => {
  const { t } = useTranslation();
  const { current_company } = useUserContext();

  const defaultColumns = [
    {
      name: "firstname",
      label: t("First Name"),
      sx: { paddingLeft: "0px" },
    },
    {
      name: "lastname",
      label: t("Last Name"),
    },
    {
      name: "email",
      label: t("Email"),
    },
    {
      name: "sent",
      label: t("Sent"),
    },
    {
      name: "opened",
      label: t("Opened"),
    },
    {
      name: "reported",
      label: t("Reported"),
      popup: t("Reported rate = number emails reported / number emails opened"),
      popupVisibility: current_company.mailHost === "OFFICE",
    },
    {
      name: "clicked",
      label: t("Clicked"),
      popup: t("Clicked rate = number emails clicked / number emails opened"),
      popupVisibility: current_company.mailHost === "OFFICE",
    },
  ];

  const compromisingActionChoices = [
    {
      name: "credential",
      label: t("Credentials"),
    },
    {
      name: "attachment",
      label: t("Attachments"),
    },
    {
      name: "download",
      label: t("Downloads"),
    },
    {
      name: "permission",
      label: t("Permissions"),
    },
  ];

  const sortChoices = defaultColumns.concat(compromisingActionChoices);

  const compromisingActionColumn = [
    {
      name: "compromisingAction",
      label: t("Compromising actions"),
    },
  ];

  const headers = defaultColumns.concat(compromisingActionColumn);

  return {
    sortChoices,
    headers,
  };
};
export default useSimulationDataPerUserHeaders;
