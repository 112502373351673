import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";

export enum SimulationAccess {
  NO_ACCESS = 0,
  TRIAL_MODE = 1,
  FULL_ACCESS = 2,
}

export const featureFlags = {
  HAS_DEPARTMENT_SUPERVISOR: "has_department_supervisor",
  LEARNING_PACE_LIMIT: "learning_pace_limit",
};
export type FeatureFlag = (typeof featureFlags)[keyof typeof featureFlags];

export type UserContextCompanyType = {
  id: number | null;
  name: string;
  mailHost: "OFFICE" | "GMAIL";
  provider: string;
  awareness_activated: boolean;
  banners_activated: boolean;
  banners_permission_granted: boolean;
  simulation_access: SimulationAccess;
  banners_controller: "OFFICE" | "GMAIL" | "DEMO" | null;
  domain: string;
  feature_flags: FeatureFlag[];
};

export type CurrentUserInfoApi = {
  email: string;
  firstname?: string;
  lastname?: string;
  is_impersonate?: boolean;
  base_company?: UserContextCompanyType;
  companies?: {
    id: number;
    name: string;
  }[];
  current_company?: UserContextCompanyType;
  language: string;
  uses_formal_tone: boolean;
};

type UserContextHelperFunctions = {
  hasFeatureFlag: (flag: FeatureFlag) => boolean;
};

export type UserContextType = CurrentUserInfoApi &
  UserContextHelperFunctions & {
    is_staff: boolean;
    is_admin: boolean;
    is_corporate_admin: boolean;
    permissions?: Permission[];
    is_authenticated: boolean;
    is_anonymous: boolean;
    is_supervisor: boolean;
    supervisor_of: number[];
  };
