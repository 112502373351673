import { generateBrowserPath } from "../common";
import {
  adminTaskSchedulerScriptContents,
  userTaskSchedulerScriptContents,
} from "../content/scheduled";

export function generateAdminScheduledTaskScript(
  browser: string,
  extensionID: string,
  enterpriseToken: string,
  extensionIDFirefox: string = "browser-defender@mantra.ms",
): string {
  const browserSpecificPath = generateBrowserPath(
    browser,
    extensionID,
    extensionIDFirefox,
  );
  return adminTaskSchedulerScriptContents(
    enterpriseToken,
    browserSpecificPath,
    browser,
  );
}

export function generateUserScheduledTaskScript(
  browser: string,
  extensionID: string,
  enterpriseToken: string,
  extensionIDFirefox: string = "browser-defender@mantra.ms",
): string {
  const browserSpecificPath = generateBrowserPath(
    browser,
    extensionID,
    extensionIDFirefox,
  );
  return userTaskSchedulerScriptContents(
    enterpriseToken,
    browserSpecificPath,
    browser,
  );
}
