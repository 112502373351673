import dayjs from "dayjs";
import { useMemo } from "react";

import { useUserPreferences } from "@/utils/useUserPreferencesService";

const useSimulationDashboard = () => {
  const { simulationPreferences, setSimulationDashboard } =
    useUserPreferences();

  const date = simulationPreferences.dashboard.selectedPeriod.date;
  const monthOrWeek =
    simulationPreferences.dashboard.selectedPeriod.monthOrWeek;
  const timeFrame = simulationPreferences.dashboard.timeFrame;

  const dateFrom = useMemo(() => {
    if (date) {
      return dayjs(date);
    }
    const dateFromSelector = dayjs()
      .subtract(dayjs.duration(timeFrame))
      .startOf("day");

    if (monthOrWeek === "week") {
      return dateFromSelector.startOf("week");
    }
    return dateFromSelector;
  }, [timeFrame, date, monthOrWeek]);

  const dateTo = useMemo(() => {
    if (date) {
      return dayjs(date).add(1, monthOrWeek);
    }
    return dayjs().endOf("day");
  }, [date, monthOrWeek]);

  return {
    timeFrame: simulationPreferences.dashboard.timeFrame,
    activeUsersOnly: simulationPreferences.dashboard.activeUsersOnly,
    department: simulationPreferences.dashboard.department,
    monthOrWeek,
    date,
    dateFrom,
    dateTo,
    setMonthOrWeek: (period: "month" | "week") =>
      setSimulationDashboard({ selectedPeriod: { monthOrWeek: period } }),
    setTimeFrame: (timeFrame: string) =>
      setSimulationDashboard({ timeFrame: timeFrame }),
    setDate: (date: string | null) =>
      setSimulationDashboard({ selectedPeriod: { date: date } }),
    setActiveUsersOnly: (activeUsersOnly: boolean) =>
      setSimulationDashboard({ activeUsersOnly: activeUsersOnly }),
    setDepartment: (department: string) =>
      setSimulationDashboard({ department: department }),
  };
};
export default useSimulationDashboard;
