import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { EnumChip, EnumChipDef } from "@/components/EnumChip";
import { DkimStatus } from "@/pages/threats/service/domain";

export const DKIMStatus = ({ dkimStatus }: { dkimStatus: DkimStatus }) => {
  const { t } = useTranslation();
  const format: Record<DkimStatus, EnumChipDef> = {
    pass: { color: "success", label: t("DKIMStatus.label.success") },
    failed: { color: "error", label: t("DKIMStatus.label.error") },
    unknown: { color: "neutral", label: t("DKIMStatus.label.unknown") },
    missing: { color: "neutral", label: t("DKIMStatus.label.missing") },
  };

  return (
    <Stack direction="row">
      <Typography sx={{ lineHeight: 2 }}>{t("DKIM")}:</Typography>
      <EnumChip
        size="xsmall"
        value={dkimStatus}
        defs={format}
        sx={{ marginLeft: ".5rem", alignSelf: "flex-start" }}
      />
    </Stack>
  );
};
