import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import { Card, Grid, Tooltip, Typography } from "@mui/material";

import { CourseCardFooter } from "@/pages/awareness/courses/courses-catalog/CourseCardFooter";
import { CourseStatusChip } from "@/pages/awareness/courses/courses-catalog/CourseStatusChip";
import { USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS } from "@/pages/awareness/courses/utils/translations";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { featureFlags } from "@/types/user";
import { useUserContext } from "@/utils/contexts/UserContext";
import { extractErrorMessage } from "@/utils/misc";

import { selectIsDemoAcme } from "./courseCatalogSlice";

const CourseSendButton = ({
  learningUserInfos,
  isCourseCatalogAdmin,
  isPublicWebchat,
  isCurrentCourse,
  isCourseLimitEnabled,
  course,
  onClick,
  isLoading,
}) => {
  const { t } = useTranslation("translation", {
    lng: learningUserInfos.language,
  });
  const isDemoAcme = useSelector(selectIsDemoAcme);
  const isUserSetup =
    learningUserInfos.bot_installed ||
    isPublicWebchat ||
    learningUserInfos.isDemoAcme;
  const canSendCourse =
    isCourseCatalogAdmin ||
    isCurrentCourse ||
    isPublicWebchat ||
    !isCourseLimitEnabled;
  isDemoAcme || learningUserInfos.finished_courses.includes(course.id);
  const userNotSetupTranslation = useMemo(
    () =>
      USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS[learningUserInfos.language] ||
      USER_NOT_SETUP_COURSE_CATALOG_TRANSLATIONS["en"],
    [learningUserInfos.language],
  );
  const tooltipTitle = useMemo(() => {
    if (!isUserSetup) {
      return userNotSetupTranslation;
    }
    if (!canSendCourse) {
      return t(
        "The courses are sent according to a pace defined by your admin. Reach out to him if you want to access the course now.",
      );
    }
    return "";
  }, [isUserSetup, userNotSetupTranslation, canSendCourse, t]);

  const buttonText = useMemo(() => {
    if (!canSendCourse) {
      return t("Coming soon");
    }
    if (isCurrentCourse) {
      return t("Resume course");
    }
    return t("Start course");
  }, [isCurrentCourse, canSendCourse, t]);

  return (
    <Tooltip arrow title={tooltipTitle}>
      <span>
        <LoadingButton
          disabled={!isUserSetup || !canSendCourse}
          variant={isCurrentCourse ? "outlined" : "contained"}
          loading={isLoading}
          sx={{ width: "140px", fontWeight: "800", fontSize: "14px" }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onClick();
          }}
        >
          {buttonText}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

export const CourseCardsContainer = ({
  learningUserInfos,
  course,
  isPublicWebchat,
  onClickAssignment,
}) => {
  const navigate = useNavigate();
  const isCurrentCourse = learningUserInfos.current_course === course.id;
  const [showSpinner, setShowSpinner] = useState(false);
  const snackbar = useSnackbar();
  const { permissions, hasFeatureFlag } = useUserContext();
  const isCourseCatalogAdmin = permissions.includes(
    Permission.AWARENESS_COURSE_CATALOG_ADMIN,
  );
  const runPublicCourse = useCallback(async () => {
    setShowSpinner(true);
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/public/webchat/course/${
          course.id
        }/start`,
        { language: "fr" },
      );
      setShowSpinner(false);
      navigate(`/demo/webchat/${response.data.id}`);
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  }, [course.id, navigate, snackbar]);

  const toWebChat = useCallback(() => navigate("/webchat"), [navigate]);

  const resumeCourse = useCallback(async () => {
    setShowSpinner(true);
    try {
      if (learningUserInfos.messaging_platform === "webchat") {
        toWebChat();
      } else {
        await axios.post(
          `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/user/notify-me`,
          {},
        );
      }
      setShowSpinner(false);
    } catch (error) {
      if (
        error.response?.data === "Prefer a redirection to the webchat platform"
      ) {
        toWebChat();
      } else {
        setShowSpinner(false);
        snackbar.showMessage(extractErrorMessage(error));
      }
    }
  }, [learningUserInfos.messaging_platform, snackbar, toWebChat]);

  const startCourse = useCallback(async () => {
    setShowSpinner(true);
    try {
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/courses/${
          course.id
        }/send`,
        { language: learningUserInfos.language },
      );
      const messaging_platform = learningUserInfos.messaging_platform;
      setShowSpinner(false);
      if (messaging_platform === "webchat") {
        return toWebChat();
      }
    } catch (error) {
      setShowSpinner(false);
      snackbar.showMessage(extractErrorMessage(error));
    }
  }, [
    course.id,
    learningUserInfos.language,
    learningUserInfos.messaging_platform,
    snackbar,
    toWebChat,
  ]);

  const onClickStartCourse = () => {
    if (isPublicWebchat) {
      return runPublicCourse();
    }
    if (isCurrentCourse) {
      return resumeCourse();
    }
    return startCourse();
  };

  return (
    <Card>
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            variant={"h2"}
            sx={{ paddingLeft: "15px", paddingTop: "20px", fontSize: "24px" }}
          >
            {course.label_name}
            {!isPublicWebchat && !isCourseCatalogAdmin && (
              <CourseStatusChip
                course={course}
                learningUserInfo={learningUserInfos}
              />
            )}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            sx={{ paddingLeft: "18px", fontSize: "14px", color: "#64748B" }}
          >
            {course.description}{" "}
          </Typography>
        </Grid>
        <Grid item sx={{ paddingRight: "30px" }}>
          {(!isCourseCatalogAdmin || isPublicWebchat) && (
            <CourseSendButton
              isCourseLimitEnabled={hasFeatureFlag(
                featureFlags.LEARNING_PACE_LIMIT,
              )}
              learningUserInfos={learningUserInfos}
              isCourseCatalogAdmin={isCourseCatalogAdmin}
              isPublicWebchat={isPublicWebchat}
              isCurrentCourse={isCurrentCourse}
              course={course}
              onClick={onClickStartCourse}
              isLoading={showSpinner}
            />
          )}
        </Grid>
      </Grid>
      <CourseCardFooter
        learningUserInfos={learningUserInfos}
        course={course}
        isPublicWebchat={isPublicWebchat}
        onClickAssignment={onClickAssignment}
      />
    </Card>
  );
};
