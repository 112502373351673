import React from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material";

import { StatsCard } from "@/components/dashboard/StatsCard";

import ClicksPerDimensionChart from "./ClicksPerDimensionChart";

const ClickRatePerDepartment = ({ data, isLoading, isError }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <StatsCard
      title={t("Highest click rate departments")}
      isLoading={isLoading}
      isError={isError}
    >
      {data && (
        <ClicksPerDimensionChart
          labels={Object.keys(data)}
          clickRates={Object.values(data)}
          backgroundColor={theme.palette.charts.primary.main}
        />
      )}
    </StatsCard>
  );
};
export default ClickRatePerDepartment;
