import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import { BigSpinner } from "@/components/Spinner";
import { selectSelectedTemplates } from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignCreate/CustomCampaignSlice";

import { TemplateCard } from "./TemplateCard";
import { useFetchTemplateListQuery } from "./templateApi";
import {
  selectActiveStatus,
  selectDisplayOption,
  selectLanguage,
  selectPage,
  selectSaasSearch,
  selectSearch,
  selectTemplateTypeSearch,
  selectTemplates,
  selectTemplatesPerPage,
  setTemplates,
} from "./templateSlice";
import { DisplayedTemplateEnum, TemplateType } from "./templateUtils";

export const TemplateList = () => {
  const dispatch = useDispatch();
  const page = useSelector(selectPage);
  const templatesPerPage = useSelector(selectTemplatesPerPage);
  const templateSearchValue = useSelector(selectSearch);
  const templatesTypeValue = useSelector(selectTemplateTypeSearch);
  const saasSearchValue = useSelector(selectSaasSearch);
  const activationStatus = useSelector(selectActiveStatus);
  const selectedTemplates = useSelector(selectSelectedTemplates);
  const templates = useSelector(selectTemplates);
  const display_option = useSelector(selectDisplayOption);
  const languageSelected = useSelector(selectLanguage);
  const shouldFetch = () => {
    if (display_option === DisplayedTemplateEnum.SELECTED_TEMPLATES) {
      dispatch(setTemplates(selectedTemplates));
      return false;
    }
    return true;
  };

  const { isFetching, isSuccess, isError } = useFetchTemplateListQuery(
    {
      page,
      templatesPerPage,
      search: templateSearchValue,
      saas: saasSearchValue,
      type: templatesTypeValue,
      activation_status: activationStatus,
      display_option: display_option,
      language: languageSelected === "all" ? null : languageSelected,
    },
    { skip: !shouldFetch() },
  );

  return (
    <Box>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          <BigSpinner />
        </div>
      ) : (
        ""
      )}
      {isSuccess && !isFetching && !isError ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "1rem",
          }}
        >
          {templates.map((template: TemplateType) => (
            <TemplateCard key={template.name} template={template} />
          ))}
        </div>
      ) : (
        ""
      )}
      {isError ? "an error occured" : ""}
    </Box>
  );
};
