import axios from "axios";
import React from "react";

import TrashIconButton from "@/components/buttons/TrashIconButton";
import { extractErrorMessage } from "@/utils/misc";

export function Delete({ tooltip, department, departments, setDepartments }) {
  const onDeleteClick = async () => {
    const departmentIdToDelete = department.id;

    try {
      await axios.delete(
        import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/departments",
        {
          data: { id: department.id, name: department.name },
        },
      );
      setDepartments(departments.filter((d) => d.id !== departmentIdToDelete));
    } catch (error) {
      alert(extractErrorMessage(error));
    }
  };
  return <TrashIconButton onClick={onDeleteClick} title={tooltip} />;
}
