import { createApi } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";

import { downloadAsCSV } from "@/utils/downloadFile";
import { simulationBaseQuery } from "@/utils/rtkQuery";

export type DataPerUserRow = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  active: boolean;
  location: string;
  language_code: string;
  is_office: boolean;
  sent: number;
  opened: number;
  clicked: number;
  reported: number;
  attachment: number;
  credential: number;
  download: number;
  permission: number;
  click_rate: number;
  opened_rate: number;
  reported_rate: number;
  level: string;
  emailmessages: {
    id: number;
    email_status: string;
    sent_date: string;
    learning_followed: boolean;
    type: string;
    attachment: boolean;
    template: string;
  }[];
  departments: string[];
};

type DataPerUser = {
  users: DataPerUserRow[];
  total_count: number;
  page_number: number;
  num_page: number;
};

export type ExportDataPerUserParams = {
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  activeUsersOnly: boolean;
  department?: string | null;
  sort?: string;
  pageNumber?: number;
  perPage?: number;
  search?: string | null;
  csv?: boolean;
};

export type DataPerUserParams = {
  date_from?: string;
  date_to?: string;
  only_active: boolean;
  department?: string | null;
  sort?: string;
  page_number?: number;
  per_page?: number;
  search?: string | null;
  campaign_id?: number;
};

function prepareParams(params: ExportDataPerUserParams): URLSearchParams {
  let urlParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (value == null || value === "") {
      continue;
    } else if (value instanceof dayjs) {
      urlParams.append(key, value.toISOString());
    } else if (key === "pageNumber") {
      urlParams.append(key, String(Number(value) + 1)); // datatable count page starting with 0 but django Paginator starts with 1.
    } else if (key === "department" && value !== "") {
      urlParams.append("filter__departments", String(value));
    } else {
      urlParams.append(key, String(value));
    }
  }
  urlParams.append("only_has_sent_messages", "true");
  return urlParams;
}

export const simulationDashboardApi = createApi({
  reducerPath: "simulation-dashboard-api",
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    dataPerUsers: builder.query<DataPerUser, DataPerUserParams>({
      query: (params) => ({
        url: "/dashboard/users",
        method: "GET",
        params: params,
      }),
    }),
    downloadUsersList: builder.query<void, DataPerUserParams>({
      query: (params) => ({
        url: "/dashboard/users/export",
        method: "GET",
        params: params,
        responseHandler: (response: { text: () => any }) => response.text(),
      }),
      transformResponse: (response) => downloadAsCSV(response, "users.csv"),
    }),
    clickRatePerDepartment: builder.query({
      query: (params) => ({
        url: "/stats/click_rate_per_department",
        method: "GET",
        params: prepareParams(params),
      }),
    }),
    clickRatePerLocation: builder.query({
      query: (params) => ({
        url: "/stats/click_rate_per_location",
        method: "GET",
        params: prepareParams(params),
      }),
    }),
  }),
});

export const {
  useDataPerUsersQuery,
  useLazyDownloadUsersListQuery,
  useClickRatePerDepartmentQuery,
  useClickRatePerLocationQuery,
} = simulationDashboardApi;
