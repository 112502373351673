export const getActiveUserProfileFromEntra = `
  function Get-ActiveUserProfileEntra {
    try {
      $userProfiles = Get-CimInstance -ClassName Win32_UserProfile | 
        Where-Object { -not $_.Special -and $_.Loaded }

      if ($userProfiles) {
        # If multiple profiles are loaded, prefer the one with the most recent LastUseTime
        $activeProfile = $userProfiles | Sort-Object -Property LastUseTime -Descending | Select-Object -First 1

        $sid = $activeProfile.SID
        $username = (New-Object System.Security.Principal.SecurityIdentifier($sid)).Translate([System.Security.Principal.NTAccount]).Value

        return @{
          Username = $username
          SID = $sid
        }
      }
    }
    catch {
      Write-Host "Error getting active user profile: $_"
    }

    return $null
  }
`;

export const getActiveUserProfileFromLocal = `
  function Get-ActiveUserProfileLocal {
    $currentUserSID = [System.Security.Principal.WindowsIdentity]::GetCurrent().User.Value
    Write-Host "Current process user SID: $currentUserSID"

    $currentUserName = [System.Security.Principal.WindowsIdentity]::GetCurrent().Name
    Write-Host "Current process user name: $currentUserName"

    # Try to get the logged-in user using multiple methods
    $loggedInUser = $null
    $loggedInUserSID = $null

    # Method 1: Using Win32_Process
    $explorerProcesses = Get-WmiObject Win32_Process -Filter "Name='explorer.exe'"
    if ($explorerProcesses) {
      $loggedInUser = ($explorerProcesses | ForEach-Object { $_.GetOwner() } | Select-Object -Unique -Expand User)
      Write-Host "Logged-in user from explorer.exe: $loggedInUser"
    }

    # Method 2: Using Win32_ComputerSystem
    if (-not $loggedInUser) {
      $loggedInUser = Get-WmiObject -Class Win32_ComputerSystem | Select-Object -ExpandProperty UserName
      Write-Host "Logged-in user from Win32_ComputerSystem: $loggedInUser"
    }

    # Method 3: Using environment variable (last resort)
    if (-not $loggedInUser) {
      $loggedInUser = $env:USERNAME
      Write-Host "Logged-in user from environment variable: $loggedInUser"
    }

    if ($loggedInUser) {
      try {
        $objUser = New-Object System.Security.Principal.NTAccount($loggedInUser)
        $strSID = $objUser.Translate([System.Security.Principal.SecurityIdentifier])
        $loggedInUserSID = $strSID.Value
        Write-Host "Logged-in user SID: $loggedInUserSID"

        return @{
          Username = $loggedInUser
          SID = $loggedInUserSID
        }
      } catch {
        Write-Host "Error translating user to SID: $_"
      }
    } else {
      Write-Host "No logged-in user found."
      exit
    }
  }
`;

export const refreshHKCUHive = `
  function Refresh-HKUHive {
    param (
      [string]$SID
    )
    try {
      # Unload the hive
      [Microsoft.Win32.Registry]::Users.Close()
      [Microsoft.Win32.Registry]::Users.Flush()

      # Small delay to ensure the unload is complete
      Start-Sleep -Milliseconds 500

      # Reload the hive
      $null = [Microsoft.Win32.Registry]::Users.OpenSubKey($SID, $true)
      [Microsoft.Win32.Registry]::Users.Flush()

      Write-Host "HKU hive refreshed for SID: $SID"
    }
    catch {
      Write-Host "Error refreshing HKU hive: $_"
    }
  }
`;

export const processUserSpecificPath = `
  function Process-UserSpecificPath {
    param(
      [string]$userRegPath,
      [string]$currentUser,
      [string]$loggedInUserSID,
      [string]$enterpriseToken
    )

    Write-Host "Checking registry path: $userRegPath"

    $retryCount = 0
    $maxRetries = 5
    $retryDelay = 5 # seconds

    if (-not (Test-Path $userRegPath)) {
      Write-Host "Registry key not found. Attempting to create: $userRegPath"
      try {
        New-Item -Path $userRegPath -Force | Out-Null
        Refresh-HKUHive -SID $loggedInUserSID
        Write-Host "Registry key creation attempt successful."
      } catch {
        Write-Host "Error creating registry key: $_"
      }
    }

    while (-not (Test-Path $userRegPath) -and $retryCount -lt $maxRetries) {
        Write-Host "Waiting for registry key to be available. Attempt $($retryCount + 1) of $maxRetries"
        Start-Sleep -Seconds $retryDelay
        $retryCount++
    }

    if (Test-Path $userRegPath) {
      Write-Host "Registry key found: $userRegPath"
      New-ItemProperty -Path $userRegPath -Name "EnterpriseToken" -Value $enterpriseToken -Force
      Write-Host "Registry key vakue EnterpriseToken creation attempt successful."
      
      $acl = Get-Acl $userRegPath

      # Remove any existing Deny rules for the user
      $existingRules = $acl.Access | Where-Object { $_.IdentityReference.Value -eq $currentUser }
      foreach ($rule in $existingRules) {
        Write-Host "Existing rule - Identity: $($rule.IdentityReference), Type: $($rule.AccessControlType), Rights: $($rule.RegistryRights)"
    
        if ($rule.AccessControlType -eq 'Deny') {
          $acl.RemoveAccessRule($rule)
          Write-Host "Removed Deny rule for $currentUser"
        }
      }

      $allowRule = New-Object System.Security.AccessControl.RegistryAccessRule(
        $currentUser,
        "FullControl",
        "ContainerInherit,ObjectInherit",
        "None",
        "Allow"
      )

      $acl.AddAccessRule($allowRule)
      Set-Acl -Path $userRegPath -AclObject $acl

      Write-Host "Exception added for the logged-in user ($currentUser) on the registry key: $userRegPath"
    } else {
      Write-Host "Registry key not found for the logged-in user: $userRegPath"

      # Check if the parent keys exist
      $parentPath = Split-Path $userRegPath -Parent
      while (-not (Test-Path $parentPath) -and $parentPath -ne "HKU:\\$loggedInUserSID") {
        $parentPath = Split-Path $parentPath -Parent
      }
      Write-Host "Closest existing parent path: $parentPath"
      
      # List contents of the closest existing parent path
      Get-ChildItem $parentPath -Recurse | ForEach-Object {
        Write-Host "Found item: $($_.Name)"
      }
    }
  }
`;

export const handlePathForActiveUser = (
  enterpriseToken: string,
  browserSpecificPath: string,
) => {
  return `
    if ($activeUser) {
      $loggedInUser = $activeUser.Username
      $loggedInUserSID = $activeUser.SID
      Write-Host "Active user detected Entra/AD - Username: $loggedInUser, SID: $loggedInUserSID"
    } else {
      # Fallback to local accounts
      $activeUser = Get-ActiveUserProfileLocal
      if ($activeUser) {
        $loggedInUser = $activeUser.Username
        $loggedInUserSID = $activeUser.SID
        Write-Host "Active user detected local - Username: $loggedInUser, SID: $loggedInUserSID"
      }
    } 

    $currentUser = $loggedInUser

    # Create a new PSDrive for the user's hive
    New-PSDrive -Name HKU -PSProvider Registry -Root HKEY_USERS | Out-Null
    
    # Construct the full path to the user's registry key
    $regPath = "HKU:\\$loggedInUserSID\\Software\\Policies\\${browserSpecificPath}"

    Write-Host "Processing $browser path: $path"
    Process-UserSpecificPath -userRegPath $regPath -currentUser $currentUser -loggedInUserSID $loggedInUserSID -enterpriseToken ${enterpriseToken}
  `;
};

export const runSetup = (
  enterpriseToken: string,
  browserSpecificPath: string,
  cleanup: string = "",
) => {
  return `
    # Check for admin rights
    if (-NOT ([Security.Principal.WindowsPrincipal][Security.Principal.WindowsIdentity]::GetCurrent()).IsInRole([Security.Principal.WindowsBuiltInRole] "Administrator")) {
      Write-Warning "You might not have Administrator rights to run this script!"
    }

    $activeUser = Get-ActiveUserProfileEntra

    try {
      ${handlePathForActiveUser(enterpriseToken, browserSpecificPath)}
      ${cleanup}
    }
    catch {
      Write-Host "An error occurred: $_"
    }
    finally {
      # Clean up: Remove the HKU PSDrive
      Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
      Write-Host "Script execution completed."
    }
  `;
};

export const runSetupWithScheduledTask = (
  enterpriseToken: string,
  browserSpecificPath: string,
  browser: string,
) => {
  const scheduledTaskRemove = `
    $taskName = "MantraBrowserDefenderSetupFor${browser}"
    Unregister-ScheduledTask -TaskName $taskName -Confirm:$false
    Write-Host "Task self-cleanup: Removed scheduled task '$taskName' to prevent future runs."
  `;

  return runSetup(enterpriseToken, browserSpecificPath, scheduledTaskRemove);
};
