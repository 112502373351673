import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Card, Stack, Typography } from "@mui/material";

import { useTemplateCatalogContext } from "@/pages/templates/TemplateCatalogContextProvider";

import { TemplateContent } from "./TemplateContent";
import { TemplateDrawer } from "./TemplateDrawer";
import TemplateTypeIcon from "./TemplateTypeIcon";
import {
  TemplateType,
  TemplateTypeEnum,
  processLabel,
  processSaasLabel,
} from "./templateUtils";

export const TemplateCard = ({ template }: { template: TemplateType }) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const templateCatalogContext = useTemplateCatalogContext();
  const { t } = useTranslation();
  const isChecked = templateCatalogContext.selectedTemplates.some(
    (selectedTemplate) => selectedTemplate.name === template.name,
  );

  const openDrawer = () => {
    setShouldFetch(true);
    setOpen(true);
  };

  const closeDrawer = () => {
    setShouldFetch(false);
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          minWidth: "300px",
          height: "300px",
          zIndex: "50",
          borderRadius: "10px",
          position: "relative",
          border: "4px solid transparent",
          borderColor: isChecked ? "#5867dd" : "transparent",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box
          className={hover ? "hover" : ""}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(157, 164, 255, 0)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            borderRadius: "8px",
            cursor: "pointer",
            transition: "all 0.25s ease",
            "&:hover": {
              backdropFilter: "blur(20px)",
            },
          }}
          onClick={openDrawer}
        >
          {hover && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "30%",
              }}
            >
              <Button
                variant="contained"
                sx={{ marginBottom: "1rem", height: "1.75rem" }}
                startIcon={<VisibilityIcon />}
              >
                {t("View")}
              </Button>
              {templateCatalogContext.isInSelectionMode && (
                <>
                  <Button
                    variant={"outlined"}
                    startIcon={isChecked ? <RemoveIcon /> : <AddIcon />}
                    size={"small"}
                    onClick={(event) => {
                      templateCatalogContext.selectTemplate(template);
                      event.stopPropagation();
                    }}
                    sx={{
                      marginBottom: "1rem",
                      height: "1.75rem",
                      backgroundColor: "white",
                    }}
                  >
                    {isChecked ? t("Remove") : t("Add")}
                  </Button>
                  {templateCatalogContext.editTemplate && (
                    <Button
                      onClick={(event) => {
                        templateCatalogContext.editTemplate(template);
                        event.stopPropagation();
                      }}
                      sx={{
                        marginBottom: "1rem",
                        height: "1.75rem",
                        fontWeight: "bold",
                      }}
                      startIcon={<EditIcon />}
                    >
                      {t("Edit")}
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
        <Stack sx={{ height: "100%" }}>
          <Box sx={{ height: "100%", overflow: "hidden", borderRadius: "8px" }}>
            <TemplateContent content={template.content} scrolling={false} />
          </Box>
          <Box
            sx={{
              height: "25%",
              padding: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ textTransform: "capitalize", fontWeight: "bold" }}
              >
                {processLabel(template.name)}
              </Typography>
              <Typography>{processSaasLabel(template.saas)}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TemplateTypeIcon type={template.category as TemplateTypeEnum} />
            </Box>
          </Box>
        </Stack>
      </Card>
      <TemplateDrawer
        name={template.name}
        isOpen={open}
        availableLanguages={template.available_languages}
        shouldFetch={shouldFetch}
        closeDrawer={closeDrawer}
        isChecked={isChecked}
        isInSelectionMode={templateCatalogContext.isInSelectionMode}
        enableActivationToggle={!templateCatalogContext.isInSelectionMode}
        onClickBottomButton={() =>
          templateCatalogContext.selectTemplate(template)
        }
      />
    </>
  );
};
