import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Autocomplete, TextField } from "@mui/material";

import EditIconButton from "@/components/buttons/EditIconButton";
import SaveIconButton from "@/components/buttons/SaveIconButton";
import { Delete } from "@/pages/general-settings/commonUserProvisioning/DepartmentsSaas/Delete";
import { featureFlags } from "@/types/user";
import { useUserContext } from "@/utils/contexts/UserContext";

import { useUsersOptions } from "../../permissions-attribution/useUsersOptions";

export function DepartmentItem({ department, departments, setDepartments }) {
  const { is_staff: isStaff, hasFeatureFlag } = useUserContext();
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [departmentNameLocal, setDepartmentNameLocal] = useState(
    department.name,
  );
  const {
    selectedOption,
    setSelectedOption,
    options,
    isLoading,
    onSearchChange,
  } = useUsersOptions([]);

  useEffect(() => {
    setDepartmentNameLocal(department.name);
    setSelectedOption(
      department.supervisor
        ? {
            label: department.supervisor.email,
            id: department.supervisor.id,
            email: department.supervisor.email,
          }
        : null,
    );
    setEditing(false);
  }, [department.name, setSelectedOption, department.supervisor]);

  const handleSave = async () => {
    const payload = {
      name: departmentNameLocal,
      supervisor:
        selectedOption != null
          ? {
              id: selectedOption.id,
              email: selectedOption.email,
            }
          : null,
    };

    await axios.patch(
      `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/departments/${department.id}`,
      payload,
    );

    setDepartments((departments) =>
      departments.map((d) => {
        if (d.id === department.id) {
          d.name = departmentNameLocal;
          if (selectedOption != null) {
            d.supervisor = {
              email: selectedOption.email,
              id: selectedOption.id,
            };
          } else {
            d.supervisor = null;
          }
          return d;
        }
        return d;
      }),
    );
    setEditing(false);
  };

  return (
    <tr key={department.id}>
      <td style={{ width: 220 }}>
        {editing ? (
          <TextField
            size="small"
            value={departmentNameLocal}
            onChange={(event) => setDepartmentNameLocal(event.target.value)}
          />
        ) : (
          <span>{department.name}</span>
        )}
      </td>
      {isStaff || hasFeatureFlag(featureFlags.HAS_DEPARTMENT_SUPERVISOR) ? (
        <td style={{ width: 220 }}>
          {editing ? (
            <Autocomplete
              size="small"
              options={options}
              value={selectedOption}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              loading={isLoading}
              onChange={(_, option) => {
                setSelectedOption(option);
              }}
              onInputChange={(_, value) => onSearchChange(value)}
              renderInput={(params) => (
                <TextField {...params} label="Email" variant="outlined" />
              )}
              loadingText={t("Loading") + "..."}
            />
          ) : (
            <span>{department.supervisor?.email ?? ""}</span>
          )}
        </td>
      ) : (
        ""
      )}
      <td>
        {editing ? (
          <SaveIconButton title={t("Save department")} onClick={handleSave} />
        ) : (
          <EditIconButton
            title={t("Edit department")}
            onClick={() => setEditing(true)}
          />
        )}
      </td>
      <td>
        <Delete
          tooltip="Delete department"
          department={department}
          departments={departments}
          setDepartments={setDepartments}
        />
      </td>
    </tr>
  );
}
