import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { AudienceSelector } from "@/components/AudienceSelector";
import { ButtonSpinner } from "@/components/Spinner";
import { EmailSent } from "@/components/icons/EmailSent";
import {
  selectCourse,
  selectCourseId,
  selectCurrentLanguage,
  selectCurrentLocalizedCourse,
  selectDraftLocalizedCourses,
  selectEditMode,
} from "@/pages/awareness/courses/course-editor/courseSlice";
import CloneEditorHeader from "@/pages/awareness/courses/course-editor/toolbar/CloneEditorHeader";
import DeleteEditorHeader from "@/pages/awareness/courses/course-editor/toolbar/DeleteEditorHeader";
import EditContentEditorHeader from "@/pages/awareness/courses/course-editor/toolbar/EditContentEditorHeader";
import LanguageDropdown from "@/pages/awareness/courses/course-editor/toolbar/LanguagesDropdown";
import SeeSaveEditorHeader from "@/pages/awareness/courses/course-editor/toolbar/SeeSaveEditorHeader";
import { selectIsMantraUser } from "@/utils/contexts/AuthSlice";

import ToolbarImportJsonButton from "../CourseEditorImportJsonButton";
import {
  useTestCourseCompany,
  useTestCourseDepartments,
  useTestCourseUsers,
  useTestLocalizedCourse,
} from "../hooks";

const CourseEditorToolbar = () => {
  const editMode = useSelector(selectEditMode);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const course = useSelector(selectCourse);
  const courseId = useSelector(selectCourseId);

  const draftLocalizedCourses = useSelector(selectDraftLocalizedCourses);
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  const isMantraUser = useSelector(selectIsMantraUser);
  const { sendTestCourseUsers } = useTestCourseUsers();
  const { sendTestCourseDepartments } = useTestCourseDepartments();
  const { sendTestCourseCompany } = useTestCourseCompany();
  const { sendTestLocalizedCourse, isTestLocalizedCourseLoading } =
    useTestLocalizedCourse();

  const snackbar = useSnackbar();

  const [userInfo, setUserInfo] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [courseRecipients, setCourseRecipients] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [openRecipientSelectionDialog, setOpenRecipientSelectionDialog] =
    useState(false);
  const [audienceSelectionMode, setAudienceSelectionMode] = useState("");
  const [
    openCourseExpeditionConfirmationDialog,
    setOpenCourseExpeditionConfirmationDialog,
  ] = useState(false);
  const [userList, setUserList] = useState([]);
  const { t } = useTranslation();

  const getUsersFromLearning = async (e = null) => {
    let input = e?.target.value;
    if (!input) {
      input = "";
    }

    try {
      let urlParams = new URLSearchParams();
      urlParams.append("email", input);
      urlParams.append("parseUserNotSetup", "True");

      const endpoint_url = `${
        import.meta.env.VITE_APP_ENDPOINT_LEARNING
      }/users/search_by_email?${urlParams.toString()}`;
      const response = await axios.get(endpoint_url, {});
      setUserList(response.data);
    } catch (error) {
      const message =
        error.response?.status === 403
          ? t("Forbidden")
          : t("An error occurred. Tech team has been notified.");
      snackbar.showMessage(message);
    }
  };
  const fetchUsers = useCallback(getUsersFromLearning, [snackbar, t]);

  const sendCourse = async () => {
    switch (audienceSelectionMode) {
      case "users":
        if (courseRecipients.length === 1) {
          sendTestLocalizedCourse(
            localizedCourse.id,
            courseRecipients[0].user.id,
          );
        } else if (courseRecipients.length > 1) {
          sendTestCourseUsers(
            courseId,
            courseRecipients.map((x) => x.user.id),
          );
        }
        break;
      case "departments":
        sendTestCourseDepartments(
          courseId,
          selectedDepartments.map((x) => x.department.id),
        );
        break;
      case "company":
        sendTestCourseCompany(courseId);
        break;
    }
  };

  useEffect(() => {
    const fetchUserSelf = async () => {
      try {
        const endpoint_url = `${
          import.meta.env.VITE_APP_ENDPOINT_LEARNING
        }/user`;
        const response = await axios.get(endpoint_url, {});
        setUserInfo(response.data);
        setSelectedUser(response.data);
      } catch (error) {
        const message =
          error.response?.status === 403
            ? t("Forbidden")
            : t("An error occurred. Tech team has been notified.");
        snackbar.showMessage(message);
      }
    };
    fetchUserSelf();
  }, [snackbar, t]);

  const truncateLength = (sequence: string, char_limit: number) => {
    if (sequence && sequence.length > char_limit) {
      return sequence.substring(0, char_limit) + "...";
    }
    return sequence;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        paddingTop: "10px",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
        <LanguageDropdown />
        {editMode ? (
          <SeeSaveEditorHeader />
        ) : isMantraUser ||
          (userInfo && course?.owner_id === userInfo.company_id) ? (
          <EditContentEditorHeader />
        ) : null}
        {!draftLocalizedCourses[currentLanguage] && <ToolbarImportJsonButton />}
        <div
          style={{
            display: "inline-block",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="small"
              style={{
                borderRight: "None",
                borderEndEndRadius: "0",
                borderTopRightRadius: "0",
              }}
              variant="outlined"
              color="neutral"
              startIcon={
                isTestLocalizedCourseLoading ? <ButtonSpinner /> : <EmailSent />
              }
              onClick={() =>
                sendTestLocalizedCourse(localizedCourse.id, userInfo.id)
              }
            >
              <span>{t("Send now to")}</span>
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="neutral"
              style={{
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
              }}
              onClick={() => setOpenRecipientSelectionDialog(true)}
            >
              {selectedUser && userInfo && selectedUser.email === userInfo.email
                ? t("me")
                : truncateLength(selectedUser?.email, 15)}
              <ArrowDropDownIcon />
            </Button>
          </div>
        </div>
        <CloneEditorHeader />
        <DeleteEditorHeader />
      </Box>
      <Dialog
        open={openRecipientSelectionDialog}
        onClose={() => setOpenRecipientSelectionDialog(false)}
      >
        <DialogTitle>{localizedCourse.name}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <Typography>{t("Send now to")}</Typography>
          <AudienceSelector
            audienceSelectionMode={audienceSelectionMode}
            setAudienceSelectionMode={setAudienceSelectionMode}
            selectedUsers={courseRecipients}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            setSelectedUsers={setCourseRecipients}
            fetchUsers={fetchUsers}
            userList={userList}
          />
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={() => setOpenRecipientSelectionDialog(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              disabled={!audienceSelectionMode}
              variant={"contained"}
              onClick={() => {
                setOpenCourseExpeditionConfirmationDialog(true);
                setOpenRecipientSelectionDialog(false);
              }}
            >
              {t("Send")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCourseExpeditionConfirmationDialog}
        onClose={() => setOpenCourseExpeditionConfirmationDialog(false)}
      >
        <DialogContent>
          <DialogContentText>
            {t(
              "You're about to send {{courseName}} {{audience}} users already following a different course will lose their progress to start this one.",
              {
                courseName: localizedCourse.name,
                audience:
                  audienceSelectionMode === "users"
                    ? t("to {{count}} users", {
                        count: courseRecipients.length,
                      }) + ", "
                    : "",
              },
            )}
          </DialogContentText>
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={() => setOpenCourseExpeditionConfirmationDialog(false)}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                sendCourse();
                setOpenCourseExpeditionConfirmationDialog(false);
                setOpenRecipientSelectionDialog(false);
              }}
            >
              {t("Send")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CourseEditorToolbar;
