import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import Tabs, {
  TabsContextProvider,
  useTabsContext,
} from "@/components/controls/Tabs";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import { Preferences } from "@/pages/browser-defender/setup/Preferences";
import { SetupChecklist } from "@/pages/browser-defender/setup/SetupChecklist";

const SetupInner: React.FC = () => {
  const { currentTabComponent } = useTabsContext();
  const { t } = useTranslation();
  return (
    <>
      <StickyHeader height="120px">
        <Typography variant="h2">{t("Browser Defender Setup")}</Typography>
        <Tabs />
      </StickyHeader>
      {currentTabComponent}
    </>
  );
};

export const Setup: React.FC = () => {
  const { t } = useTranslation();
  const [activeFeature, setActiveFeature] = useState(true);

  const displayInactiveFeatureInfo = () => {
    setActiveFeature(false);
  };

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  const tabs = [
    {
      anchor: "setup-checklist",
      label: t("Setup Checklist"),
      component: (
        <SetupChecklist inactiveFeatureCallback={displayInactiveFeatureInfo} />
      ),
    },
    {
      anchor: "preferences",
      label: t("Preferences"),
      component: (
        <Preferences inactiveFeatureCallback={displayInactiveFeatureInfo} />
      ),
    },
  ];

  return (
    <TabsContextProvider value={{ tabs: tabs }}>
      <SetupInner />
    </TabsContextProvider>
  );
};
