import React from "react";
import { useTranslation } from "react-i18next";

import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Tooltip, useTheme } from "@mui/material";

import { FeedbackLG } from "@/pages/threats/service/domain";

type ThreatFeedbackCommentIconProps = {
  feedback: FeedbackLG;
};

export const ThreatFeedbackCommentIcon = ({
  feedback,
}: ThreatFeedbackCommentIconProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const comment = feedback?.comment
    ? t("threat.feedback.tooltip_with_comment", {
        date: feedback.date,
        feedback_giver_email: feedback.email,
        comment: feedback.comment,
      })
    : t("threat.feedback.tooltip", {
        date: feedback.date,
        feedback_giver_email: feedback.email,
      });

  return (
    <Tooltip
      title={comment}
      placement="right-end"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-7, -20],
              },
            },
          ],
        },
      }}
    >
      <ChatOutlinedIcon htmlColor={theme.palette.info.main} />
    </Tooltip>
  );
};
