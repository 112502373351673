import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export type ThreatSource = "user" | "system";
export type DkimStatus = "pass" | "failed" | "missing" | "unknown";
export type FeedbackType = "spam" | "safe" | "suspicious" | "unsafe";

export const useFeedbackNames = () => {
  const { t } = useTranslation();
  return (feedback: FeedbackType) => {
    const FeedbackName: Record<FeedbackType, ReactNode> = {
      spam: t("banners.feedback.spam"),
      safe: t("banners.feedback.safe"),
      suspicious: t("banners.feedback.suspicious"),
      unsafe: t("banners.feedback.unsafe"),
    };
    return FeedbackName[feedback];
  };
};

export type BannerLevel = "info" | "suspicious" | "very-suspicious";

export type ColumnSort = {
  column: string;
  direction: "asc" | "desc";
};

export type ListOptions = {
  archived: boolean;
  searchTerm: string;
  sort: ColumnSort[];
  source: ThreatSource | null;
  bannerLevel: BannerLevel | "none" | null;
  hasAttachments: boolean | null;
};

export type Mailbox = {
  name: string | null;
  email: string;
};

export type FeedbackLG = {
  type: FeedbackType;
  comment: string | null;
  email: string | null;
  name: string | null;
  date: string | null;
};

export type Attachment = {
  name: string;
  size: number;
  contentType: string;
};

export type EmailHeaderNameValue = {
  name: string;
  value: string;
};

export type ThreatDetails = {
  id: number;
  headers: EmailHeaderNameValue[];
  body: string;
  attachments: Attachment[];
  dkimStatus: DkimStatus;
};
