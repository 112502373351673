import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Box, Button } from "@mui/material";

import TrashIcon from "@/components/icons/TrashIcon";

import { DeleteCourseModal } from "../DeleteCourseModal";
import { selectIsProvidedByMantra } from "../courseSlice";

const DeleteEditorHeader = () => {
  const isProvidedByMantra = useSelector(selectIsProvidedByMantra);
  const [displayDeleteCourseModal, setDisplayDeleteCourseModal] =
    useState(false);

  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {!isProvidedByMantra ? (
        <>
          <Button
            size="small"
            variant="outlined"
            color="neutral"
            startIcon={<TrashIcon />}
            onClick={() => setDisplayDeleteCourseModal(true)}
            sx={{ whiteSpace: "nowrap" }}
          >
            {t("Delete Course")}
          </Button>
          <DeleteCourseModal
            show={displayDeleteCourseModal}
            close={() => setDisplayDeleteCourseModal(false)}
          />
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

export default DeleteEditorHeader;
