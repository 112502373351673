import { debounce } from "chart.js/helpers";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Box,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { SearchField } from "@/components/SearchField";
import { languageListWithoutFormal } from "@/utils/misc";

import { useFetchSaasListQuery } from "./templateApi";
import {
  selectActiveStatus,
  selectDisplayOption,
  selectLanguage,
  selectSaasSearch,
  selectSearch,
  selectTemplateTypeSearch,
  setActiveStatus,
  setDisplayOption,
  setLanguage,
  setSaasSearch,
  setSearch,
  setTemplateTypeSearch,
} from "./templateSlice";
import {
  ActiveStatusEnum,
  DisplayedTemplateEnum,
  SaasType,
  TemplateTypeEnum,
} from "./templateUtils";

export const TemplateSearchbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Need useState here because of the debounce.
  // A call is made when one of the values in the store change.
  // And we want to be able to display what the user type without
  // making a call right away.

  const storeSearchValue = useSelector(selectSearch);
  const storeTemplateTypeValue = useSelector(selectTemplateTypeSearch);
  const storeSaasSearchValue = useSelector(selectSaasSearch);
  const storeLanguageSelected = useSelector(selectLanguage);
  const storeActiveStatus = useSelector(selectActiveStatus);
  const storeDisplayOption = useSelector(selectDisplayOption);

  const [searchValue, setSearchValue] = useState(storeSearchValue);

  const { data, isError, isFetching } = useFetchSaasListQuery();

  const debouncedSearch = useMemo(
    () =>
      debounce((search: any) => {
        if (search.length > 0) {
          dispatch(setSearch({ search: search[0] }));
        } else {
          dispatch(setSearch({ search: null }));
        }
      }, 250),
    [dispatch],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const tooltipContent = (
    <Box>
      <Typography>
        <strong>{t("Active")}</strong> - {t("Candidate for simulations")}
      </Typography>
      <br />
      <Typography>
        <strong>{t("Blocked.male")}</strong> -{" "}
        {t("Selected in Company Scan but individually blocked")}
      </Typography>
      <br />
      <Typography>
        <strong>{t("Not included")}</strong> -{" "}
        {t(
          "Toggle can be enabled by adding the corresponding tag in Company Scan",
        )}
      </Typography>
    </Box>
  );

  return (
    <Stack spacing={2} direction="row">
      <SearchField
        value={searchValue}
        onChange={handleSearchChange}
        sx={{ width: "220px" }}
      />
      <Autocomplete
        disablePortal
        value={storeSaasSearchValue}
        onChange={(e: any, newValue: SaasType | null) => {
          dispatch(setSaasSearch({ saas: newValue }));
        }}
        options={data ?? []}
        getOptionLabel={(option: SaasType) => option.name ?? ""}
        disabled={isFetching || isError}
        size="small"
        renderInput={(params) => (
          <TextField {...params} sx={{ minWidth: "200px" }} label="Tags" />
        )}
        loadingText={t("Loading") + "..."}
      />
      <TextField
        label={t("Payload")}
        size="small"
        value={storeTemplateTypeValue}
        onChange={(e) => {
          dispatch(setTemplateTypeSearch(e.target.value));
        }}
        select
        sx={{ minWidth: "100px" }}
      >
        {Object.values(TemplateTypeEnum).map((type) => (
          <MenuItem key={type} value={type}>
            {type[0].toUpperCase() + type.slice(1)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        size="small"
        value={storeActiveStatus}
        onChange={(e) => {
          dispatch(setActiveStatus(e.target.value));
        }}
        select
        sx={{ minWidth: "130px" }}
        label={
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            {t("Activation Status")}
            <Tooltip title={tooltipContent} arrow>
              <InfoIcon sx={{ ml: 1, fontSize: "medium", cursor: "pointer" }} />
            </Tooltip>
          </Box>
        }
      >
        <MenuItem value={ActiveStatusEnum.ALL}>{t("All")}</MenuItem>
        <MenuItem value={ActiveStatusEnum.ACTIVE}>{t("Active")}</MenuItem>
        <MenuItem value={ActiveStatusEnum.BLOCKED}>
          {t("Blocked.male")}
        </MenuItem>
        <MenuItem value={ActiveStatusEnum.NOT_INCLUDED}>
          {t("Not included")}
        </MenuItem>
      </TextField>
      <TextField
        size="small"
        label={t("Available in")}
        value={storeLanguageSelected}
        onChange={(e) => {
          dispatch(setLanguage(e.target.value));
        }}
        select
        sx={{ minWidth: "130px" }}
      >
        <MenuItem value={"all"}>{t("All")}</MenuItem>
        {languageListWithoutFormal.map((lang) => (
          <MenuItem key={lang.code} value={lang.code}>
            {lang.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        size="small"
        label={t("Display")}
        value={storeDisplayOption}
        onChange={(e) => {
          dispatch(setDisplayOption(e.target.value));
        }}
        select
        sx={{ minWidth: "130px" }}
      >
        <MenuItem value={DisplayedTemplateEnum.ALL}>{t("All")}</MenuItem>
        <MenuItem value={DisplayedTemplateEnum.BEST_PERFORMERS}>
          {t("Recommended audit templates")}
        </MenuItem>
        <MenuItem value={DisplayedTemplateEnum.SELECTED_TEMPLATES}>
          {t("Templates currently selected")}
        </MenuItem>
      </TextField>
    </Stack>
  );
};
