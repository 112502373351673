import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert, IconButton, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { useUserContext } from "@/utils/contexts/UserContext";

const headers = [
  { label: "Version", alignment: "left" as const },
  { label: "Browser", alignment: "left" as const },
  { label: "Platform", alignment: "left" as const },
  { label: "Latest heartbeat", alignment: "left" as const },
  { label: "Status", alignment: "center" as const },
  { label: "ID", alignment: "center" as const },
];

const sortingColumns = [
  { label: "Version", name: "version" },
  { label: "Browser", name: "browser" },
  { label: "Platform", name: "platform" },
  { label: "Latest heartbeat", name: "latest_heartbeat" },
  { label: "Status", name: "status" },
];

const filterColumns = ["version", "browser", "platform", "status"];

export default function ExtensionsTable({ user_email }) {
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });
  const [errorMessage, setErrorMessage] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchDeploymentDetails = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path =
        info_user.email === "demo@acme.com"
          ? "/demo/user/deployment_details"
          : "/user/deployment_details";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.post(
          url,
          { email: user_email },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      setData({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [getAccessTokenSilently, info_user, user_email]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchDeploymentDetails();
  }, [fetchDeploymentDetails, info_user]);

  useEffect(() => {
    let filteredValues;

    if (!searchTerm) {
      filteredValues = data.values;
    } else {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    filteredValues.sort((p1, p2) => {
      let condition = 1;
      for (let sortRule of sorting) {
        const value1 = p1[sortRule.column] ? p1[sortRule.column] : "";
        const value2 = p2[sortRule.column] ? p2[sortRule.column] : "";

        condition *=
          value1.toLowerCase() < value2.toLowerCase()
            ? -1 * sortRule.direction
            : value1.toLowerCase() > value2.toLowerCase()
              ? 1 * sortRule.direction
              : 0;
      }
      return condition;
    });

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData]);

  async function handleVisibility(visibility, extension_uuid, extension_id) {
    let url = visibility
      ? "/admin_actions/settings/extensions/unhide"
      : "/admin_actions/settings/extensions/hide";

    let resource;
    try {
      const accessToken = await getAccessTokenSilently();
      resource = await axios.post(
        `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${url}`,

        { extension_uuid: extension_uuid, extension_id: extension_id },

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      setErrorMessage("An error occurred while saving your configuration!");
      Sentry.captureException(error);
      return;
    }

    const newData = data.values.map((item, index) => {
      if (item.extension_uuid === extension_uuid) {
        return { ...data.values[index], is_hidden: resource.data.is_hidden };
      }
      return item;
    });

    setData({ ...data, values: newData });
  }

  function setExtensionAsHidden(extension_uuid, extension_id) {
    handleVisibility(false, extension_uuid, extension_id);
  }

  function setExtensionAsVisible(extension_uuid, extension_id) {
    handleVisibility(true, extension_uuid, extension_id);
  }

  return (
    <GenericCard title={"Deployments"} isLoading={data.loading}>
      {errorMessage && (
        <Alert
          severity="error"
          onClose={() => setErrorMessage("")}
          sx={{ marginBottom: "1rem" }}
        >
          {errorMessage}
        </Alert>
      )}
      <CollapsibleTable
        headers={headers}
        sortingColumns={sortingColumns}
        setSorting={setSorting}
        setSearchTerm={setSearchTerm}
        defaultOrder={[{ column: "version", direction: -1 }]}
        rows={data.filteredValues.map((row, index) => {
          return (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                align={headers[0].alignment}
              >
                {row.version}
              </TableCell>
              <TableCell align={headers[1].alignment}>
                {row.browser} [{row.browser_version}]
              </TableCell>
              <TableCell align={headers[2].alignment}>
                {row.platform} [{row.platform_version}]
              </TableCell>
              <TableCell align={headers[3].alignment}>
                {row.latest_heartbeat}
              </TableCell>
              <TableCell align={headers[4].alignment}>
                {row.extension_status_label}
              </TableCell>
              <TableCell align={headers[5].alignment}>
                {row.extension_uuid}
              </TableCell>
              <TableCell align="center">
                <Tooltip
                  title={
                    row.is_hidden
                      ? "Show extension in user details"
                      : "Hide extension from user details"
                  }
                >
                  <IconButton
                    onClick={() =>
                      row.is_hidden
                        ? setExtensionAsVisible(
                            row.extension_uuid,
                            row.extension_id,
                          )
                        : setExtensionAsHidden(
                            row.extension_uuid,
                            row.extension_id,
                          )
                    }
                  >
                    {row.is_hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      />
    </GenericCard>
  );
}
