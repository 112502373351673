import * as React from "react";
import { useTranslation } from "react-i18next";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Popper, TablePagination, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DataPerUsersRow from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersRow";

import useSimulationDataPerUserHeaders from "../hooks/useSimulationDataPerUserHeaders";

const TableHeaderColumnCell = ({ column, displayPopup }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  return (
    <TableCell
      sx={column.sx}
      key={column.name}
      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      {column.popup !== undefined && displayPopup ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
          <Typography>{column.label}</Typography>
          <HelpOutlineIcon sx={{ fontSize: "1.2rem", opacity: "50%" }} />
        </Box>
      ) : (
        <Typography>{column.label}</Typography>
      )}
      {column.popup !== undefined && displayPopup && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          sx={{
            pointerEvents: "none",
          }}
          placement="top"
        >
          <Paper sx={{ background: "black" }}>
            <Typography
              sx={{
                p: 1,
                fontSize: "0.8rem",
                width: "200px",
                textAlign: "center",
              }}
              color="white"
            >
              {column.popup}
            </Typography>
          </Paper>
        </Popper>
      )}
    </TableCell>
  );
};

export default function DataPerUsersTable({
  data,
  rowsPerPage,
  setRowsPerPage,
  pageNumber,
  setPageNumber,
}) {
  const { t } = useTranslation();
  const { headers } = useSimulationDataPerUserHeaders();

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingRight: "8px", paddingLeft: "8px" }} />
              {headers.map((column) => (
                <TableHeaderColumnCell
                  key={column.name}
                  column={column}
                  displayPopup={
                    column.popupVisibility ? column.popupVisibility : true
                  }
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.users.map((row) => {
              return <DataPerUsersRow key={row.id} rowData={row} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        labelRowsPerPage={t("Rows per page") + " :"}
        component="div"
        count={data.total_count}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onPageChange={(_, newPage) => {
          setPageNumber(newPage);
        }}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPageNumber(0);
        }}
      />
    </Box>
  );
}
