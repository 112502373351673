import {
  getActiveUserProfileFromEntra,
  getActiveUserProfileFromLocal,
  processUserSpecificPath,
  refreshHKCUHive,
  runSetupWithScheduledTask,
} from "./functions";

export const adminTaskSchedulerScriptContents = (
  enterpriseToken: string,
  browserSpecificPath: string,
  browser: string,
) => {
  return `
    # Create a script block containing all the functions
    $scriptBlock = @'
      ${getActiveUserProfileFromEntra}

      ${getActiveUserProfileFromLocal}

      ${refreshHKCUHive}

      ${processUserSpecificPath}

      ${runSetupWithScheduledTask(enterpriseToken, browserSpecificPath, browser)}

      # When this script runs in the scheduled task, we want to execute the Run-Setup function
      Run-Setup
'@

    # Convert the script block to Base64 to avoid command line length limitations and escaping issues
    $bytes = [System.Text.Encoding]::Unicode.GetBytes($scriptBlock)
    $encodedCommand = [Convert]::ToBase64String($bytes)

    # Create the scheduled task that will run our encoded script
    $taskName = "MantraBrowserDefenderSetupFor${browser}"
    $taskDescription = "Configures registry settings for Mantra Browser Defender extension"

    # Define what the scheduled task will do:
    # It will run PowerShell with our encoded script as an argument
    $Action = New-ScheduledTaskAction -Execute "powershell.exe" -Argument "-NoProfile -ExecutionPolicy Bypass -EncodedCommand $encodedCommand"

    # Define when the scheduled task will run
    # 1. 1 minute after logon
    $logonTrigger = New-ScheduledTaskTrigger -AtLogOn
    $logonTrigger.Delay = "PT1M"  # 1 minute delay

    # 2. Run once immediately (for currently logged-on user)
    $immediateTrigger = New-ScheduledTaskTrigger -Once -At (Get-Date).AddMinutes(1)

    # 3. Every 2 minutes after that
    $repeatTrigger = New-ScheduledTaskTrigger -Once -At (Get-Date) -RepetitionInterval (New-TimeSpan -Minutes 2) -RepetitionDuration (New-TimeSpan -Days 5)

    # Combine all triggers
    $triggers = @($logonTrigger, $immediateTrigger, $repeatTrigger)

    # Define who will run the scheduled task (SYSTEM account with highest privileges)
    $Principal = New-ScheduledTaskPrincipal -UserId "SYSTEM" -LogonType ServiceAccount -RunLevel Highest

    # Register the scheduled task in Windows Task Scheduler
    Register-ScheduledTask -TaskName $taskName -Action $Action -Trigger $triggers -Principal $Principal -Description $taskDescription -Force

    Write-Host "Scheduled task '$taskName' has been created and started."
  `;
};

export const userTaskSchedulerScriptContents = (
  enterpriseToken: string,
  browserSpecificPath: string,
  browser: string,
) => {
  return `
    # Create a script block containing the code to be executed by the scheduled task
    $scriptBlock = @'
      # Script to configure Chrome extension registry settings
      # Create a new PSDrive for the user's hive
      New-PSDrive -Name HKU -PSProvider Registry -Root HKEY_USERS | Out-Null

      try {
        # Construct the full path to the user's registry key
        $sid = (New-Object System.Security.Principal.NTAccount($env:USERNAME)).Translate([System.Security.Principal.SecurityIdentifier]).Value
        
        # Create 
        $regPath = "HKU:\\$sid\\Software\\Policies\\${browserSpecificPath}"

        # Verify the path was created successfully
        if (Test-Path $regPath -ErrorAction SilentlyContinue) {
          Write-Host "Registry path exists: $regPath"
        
          # Write the EnterpriseToken to the registry
          New-ItemProperty -Path $regPath -Name "EnterpriseToken" -Value ${enterpriseToken} -Force
          Write-Host "Set EnterpriseToken successfully"
          
          # Get the user email or UPN via whoami
          $userDomainInfo = whoami /upn
          Write-Host "User identity: $userDomainInfo"
          
          # Write the UPN to the registry
          New-ItemProperty -Path $regPath -Name "EnterpriseUserId" -Value $userDomainInfo -Force
          Write-Host "Set EnterpriseUserId successfully"
          
          # Self-cleanup: Delete the scheduled task after successful execution
          $taskName = "MantraBrowserDefenderUserConfigFor${browser}"
          Unregister-ScheduledTask -TaskName $taskName -Confirm:$false
          Write-Host "Task completed successfully and removed itself"

          Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
          
          # Return success
          exit 0
        } else {
          Write-Host "Registry path does not exist: $regPath"
          Write-Host "Will try again later when the path is created by another script"
          # Exit with code 0 but DO NOT delete the task so it runs again later

          Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
          exit 0
        }
      } catch {
        Write-Host "An error occurred: $_"
        
        Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
        
        # Return failure
        exit 1
      }
'@

    # Convert the script block to Base64 to avoid command line length limitations and escaping issues
    $bytes = [System.Text.Encoding]::Unicode.GetBytes($scriptBlock)
    $encodedCommand = [Convert]::ToBase64String($bytes)

    # Create the scheduled task that will run our encoded script
    $taskName = "MantraBrowserDefenderUserConfigFor${browser}"
    $taskDescription = "Configures user-specific registry settings for Mantra Browser Defender extension"

    # Define what the scheduled task will do:
    # It will run PowerShell with our encoded script as an argument
    $Action = New-ScheduledTaskAction -Execute "powershell.exe" -Argument "-NoProfile -ExecutionPolicy Bypass -WindowStyle Hidden -EncodedCommand $encodedCommand"

    # Define when the scheduled task will run:
    # 1. 10 minutes after logon
    $logonTrigger = New-ScheduledTaskTrigger -AtLogOn
    $logonTrigger.Delay = "PT5M"  # 5 minutes delay

    # 2. Run once immediately (for currently logged-on user)
    $immediateTrigger = New-ScheduledTaskTrigger -Once -At (Get-Date).AddMinutes(2)

    # 3. Every hour after that
    $hourlyTrigger = New-ScheduledTaskTrigger -Once -At (Get-Date) -RepetitionInterval (New-TimeSpan -Hours 1)

    # Combine all triggers
    $triggers = @($logonTrigger, $immediateTrigger, $hourlyTrigger)

    # Define who will run the scheduled task (current user)
    $Principal = New-ScheduledTaskPrincipal -GroupId "INTERACTIVE" -RunLevel Highest

    # Register the scheduled task in Windows Task Scheduler
    Register-ScheduledTask -TaskName $taskName -Action $Action -Trigger $triggers -Principal $Principal -Description $taskDescription -Force

    Write-Host "Scheduled task '$taskName' has been created and will run 5 minutes after logon and hourly thereafter."
  `;
};
