import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CardActionArea, Grid } from "@mui/material";

import { CourseCardsContainer } from "@/pages/awareness/courses/courses-catalog/CourseCardsContainer";
import {
  selectHasFilter,
  selectUserInfos,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";

export const DraggableCourseCardContainer = ({
  course,
  index,
  onClickAssignment,
}) => {
  const learningUserInfos = useSelector(selectUserInfos);
  const navigate = useNavigate();
  const hasFilter = useSelector(selectHasFilter);
  return (
    <Draggable
      key={"" + course.id}
      draggableId={"" + course.id}
      index={index}
      isDragDisabled={hasFilter}
      disableInteractiveElementBlocking={true}
      shouldRespectForcePress={true}
    >
      {(provided) => (
        <Grid
          key={course.id}
          item
          xs={12}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ marginLeft: "3rem", marginRight: "3rem" }}
        >
          <CardActionArea
            disableRipple
            sx={{ marginTop: "1rem" }}
            onClick={() => navigate(`/awareness/courses/${course.id}`)}
          >
            <CourseCardsContainer
              isPublicWebchat={false}
              learningUserInfos={learningUserInfos}
              course={course}
              onClickAssignment={onClickAssignment}
            />
          </CardActionArea>
        </Grid>
      )}
    </Draggable>
  );
};
