import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { CorporateSwitch } from "@/components/menu/CorporateSwitch";
import MenuStyle from "@/components/menu/Menu.module.css";
import MenuItem, { MenuItemType } from "@/components/menu/MenuItem";
import menuConfig from "@/components/menu/menuConfig";
import { getActivePaths } from "@/components/menu/menuUtils";
import { useUserContext } from "@/utils/contexts/UserContext";

import BeamerNotificationBell from "./BeamerNotificationBell";

export default function Menu() {
  const userContext = useUserContext();
  const { logout } = useAuth0();
  const [activePaths, setActivePaths] = useState<MenuItemType[]>([]);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const location = useLocation();
  const changeRouteOnMobileCallback = () => setIsMobileMenuVisible(false);

  const filterMenuItems = (item: MenuItemType) => {
    if (item.isVisible) {
      return item.isVisible(userContext);
    }
    if (userContext.is_supervisor && item.isVisibleBySupervisor) {
      return true;
    }
    if (item.permissions) {
      return item.permissions.some((x) => userContext.permissions.includes(x));
    }
    return true;
  };
  // get active menu items
  // according to current location
  useEffect(() => {
    setActivePaths(getActivePaths(menuConfig, location.pathname));
  }, [location, setActivePaths]);

  // use proper config depending on
  // user role

  type WindowBeamer = typeof window & { Beamer: any };

  const beamer =
    import.meta.env.VITE_APP_SHOULD_USE_BEAMER === "true" &&
    userContext.is_admin === true ? (
      <BeamerNotificationBell
        key={(window as WindowBeamer)?.Beamer?.notificationNumber}
      />
    ) : (
      ""
    );

  return (
    <div className={MenuStyle.container}>
      <div className={MenuStyle.header}>
        <img
          className={MenuStyle.headerLogo}
          src="/media/logos/mantra_logo.svg"
          alt="Mantra logo"
        />
        {beamer}
        <div
          className={MenuStyle.openMenuButton}
          onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
        >
          <img src="/media/icons/svg/menu/menu-open-button.svg" alt="menu" />
        </div>
      </div>
      <div
        className={`${isMobileMenuVisible ? "" : MenuStyle.hiddenMenuMobile}`}
      >
        <CorporateSwitch />
      </div>
      <div
        className={`${MenuStyle.mainContent} ${
          isMobileMenuVisible ? "" : MenuStyle.hiddenMenuMobile
        }`}
      >
        {userContext.email &&
          menuConfig
            .filter(filterMenuItems)
            .map((e: MenuItemType) => (
              <MenuItem
                item={e}
                isTop={true}
                key={e.pathname}
                activePaths={activePaths}
                onChangePage={changeRouteOnMobileCallback ?? null}
                filterMenuItems={filterMenuItems}
              />
            ))}
      </div>
      {userContext.email && (
        <div
          className={`${MenuStyle.footer} ${
            isMobileMenuVisible ? "" : MenuStyle.hiddenMenuMobile
          }`}
        >
          <div className={MenuStyle.footerTitle}>{userContext.email}</div>
          <div
            onClick={() =>
              logout({
                logoutParams: { returnTo: import.meta.env.VITE_APP_URL },
              })
            }
          >
            <img
              className={MenuStyle.icon}
              src="/media/icons/svg/menu/log-out.svg"
              alt="logout"
            />
          </div>
        </div>
      )}
    </div>
  );
}
