import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";

import GenericCard from "@/pages/browser-defender/components/GenericCard";
import { GeneralInfoTable } from "@/pages/browser-defender/userDetails/GeneralInfoTable";
import { useUserContext } from "@/utils/contexts/UserContext";

export function GeneralInfoCardDemo({ user_email }) {
  const [user_details, setUserDetails] = useState({ data: {}, loading: true });

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchUserDetails = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path = "/user/general_details";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.post(
          url,
          { email: user_email },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
      } catch (e) {
        if (e.response.status === 400) {
          return;
        }
      }

      const now = new Date();
      now.setHours(now.getHours() - 1);
      const heartbeat = now.toLocaleDateString();
      now.setDate(now.getDate() - 360);
      const initialInstallation = now.toLocaleDateString();
      now.setDate(now.getDate() - 60);
      const latestInstallation = now.toLocaleDateString();

      setUserDetails({
        data: {
          ...resource.data,
          groups: ["Commercial"],
          latest_heartbeat: `${heartbeat.replaceAll("/", "-")} 11:45`,
          first_installation: `${initialInstallation.replaceAll(
            "/",
            "-",
          )} 09:32`,
          latest_installation: `${latestInstallation.replaceAll(
            "/",
            "-",
          )} 15:56`,
        },
        loading: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [getAccessTokenSilently, user_email]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchUserDetails();
  }, [fetchUserDetails, info_user]);

  return (
    <GenericCard title={"General information"} isLoading={user_details.loading}>
      <GeneralInfoTable data={user_details.data} />
    </GenericCard>
  );
}
