import { createApi } from "@reduxjs/toolkit/query/react";

import { setCampaignData } from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignCreate/CustomCampaignSlice";
import { simulationBaseQuery } from "@/utils/rtkQuery";

export const customCampaignApi = createApi({
  reducerPath: "CustomCampaignApi",
  refetchOnMountOrArgChange: true,
  baseQuery: simulationBaseQuery(),
  endpoints: (builder) => ({
    fetchTemplateContent: builder.mutation({
      query: (payload: { name: string; language: string }) =>
        `templates/get_raw_by_name/?name=${payload.name}&language_code=${payload.language}`,
    }),
    fetchListCustomCampaigns: builder.query({
      query: (payload: void) => "custom-campaigns/",
    }),
    fetchCustomCampaignResult: builder.query({
      query: (id: string) => `custom-campaigns/${id}/results`,
    }),
    fetchCustomCampaign: builder.query({
      query: (id: string) => `custom-campaigns/${id}`,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const response = await queryFulfilled;
        dispatch(setCampaignData(response.data));
      },
    }),
    createCustomCampaign: builder.mutation({
      query: (payload: {
        campaignName: string;
        startDate: string;
        endDate: string;
        templates: string[];
        targetUserAddresses: string[];
        targetDepartmentIds: number[];
        targetAudience: string;
        simulationPerUser: number;
      }) => ({
        url: `custom-campaigns/`,
        method: "POST",
        body: {
          campaign_name: payload.campaignName,
          start_date: payload.startDate,
          end_date: payload.endDate,
          templates: payload.templates,
          target_user_addresses: payload.targetUserAddresses,
          target_department_ids: payload.targetDepartmentIds,
          target_audience: payload.targetAudience,
          simulations_per_user: payload.simulationPerUser,
        },
      }),
    }),
    editCustomCampaign: builder.mutation({
      query: (payload: {
        campaignName: string;
        id: number;
        startDate: string;
        endDate: string;
        templates: string[];
        targetUserAddresses: string[];
        targetDepartmentIds: number[];
        targetAudience: string;
        simulationPerUser: number;
      }) => ({
        url: `custom-campaigns/${payload.id}`,
        method: "PUT",
        body: {
          campaign_name: payload.campaignName,
          start_date: payload.startDate,
          end_date: payload.endDate,
          templates: payload.templates,
          target_user_addresses: payload.targetUserAddresses,
          target_department_ids: payload.targetDepartmentIds,
          target_audience: payload.targetAudience,
          simulations_per_user: payload.simulationPerUser,
        },
      }),
    }),
    scheduleCustomCampaign: builder.mutation({
      query: (id) => ({
        url: `custom-campaigns/${id}/simulations`,
        method: "POST",
      }),
    }),
    cancelCustomCampaign: builder.mutation({
      query: (id) => ({
        url: `custom-campaigns/${id}/simulations`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchCustomCampaignQuery,
  useFetchCustomCampaignResultQuery,
  useFetchTemplateContentMutation,
  useFetchListCustomCampaignsQuery,
  useCreateCustomCampaignMutation,
  useEditCustomCampaignMutation,
  useScheduleCustomCampaignMutation,
  useCancelCustomCampaignMutation,
} = customCampaignApi;
