import { merge } from "lodash";

import {
  DefaultUserPreferences,
  UserPreferences,
  UserPreferencesSchema,
} from "@/utils/userPreferences";

export const loadUserPreferences = (): UserPreferences => {
  const localStorageUserPreferences = JSON.parse(
    localStorage.getItem("user-preferences"),
  );
  let result = UserPreferencesSchema.safeParse(localStorageUserPreferences);
  if (result.success) {
    return result.data;
  }
  const mergedUserPreferences = merge(
    DefaultUserPreferences,
    localStorageUserPreferences,
  );
  result = UserPreferencesSchema.safeParse(mergedUserPreferences);
  if (result.success) {
    return result.data;
  }
  return DefaultUserPreferences;
};
