import { createApi } from "@reduxjs/toolkit/query/react";

import { learningBaseQuery, simulationBaseQuery } from "@/utils/rtkQuery";

import {
  updateChecklistState,
  updateIsLoading,
  updateMessagingPlatformInstalledStatus,
  updateTeamsPoliciesReviewed,
} from "./SetupChecklistSlice";
import { MessagingPlatform } from "./SetupChecklistTypes";

type AwarenessSetupStatusResponse = {
  messaging_platform: MessagingPlatform;
  one_course_assigned: boolean;
  course_delivery_activated: boolean;
  messaging_platform_installed: boolean;
};

type SimulationSetupStatusResponse = {
  has_mail_permissions: boolean;
  has_confirmed_awareness_teams_policies: boolean;
};

export type UpdateMessagingPlatformRequest = {
  messaging_platform: MessagingPlatform;
};

type AwarenessTeamsPoliciesParams = {
  has_confirmed_awareness_teams_policies: boolean;
};

export const awarenessChecklistApiFromSimulation = createApi({
  reducerPath: "awareness-checklist-api-from-simulation",
  baseQuery: simulationBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    setupStatus: builder.query<SimulationSetupStatusResponse, void>({
      query: () => ({
        url: "company/setup-status",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(updateIsLoading(true));
        const { data } = await queryFulfilled;
        dispatch(
          updateMessagingPlatformInstalledStatus(data.has_mail_permissions),
        );
        dispatch(
          updateTeamsPoliciesReviewed(
            data.has_confirmed_awareness_teams_policies,
          ),
        );
        dispatch(updateIsLoading(false));
      },
    }),
    getTeamsPolicy: builder.query<AwarenessTeamsPoliciesParams, void>({
      query: () => ({
        url: "company/setup/awareness/status",
        method: "GET",
      }),
    }),
    updateTeamsPolicy: builder.mutation<void, AwarenessTeamsPoliciesParams>({
      query: (data) => ({
        url: "company/setup/awareness/status",
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const awarenessChecklistApi = createApi({
  reducerPath: "awareness-checklist-api",
  baseQuery: learningBaseQuery(),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    setupStatus: builder.query<AwarenessSetupStatusResponse, void>({
      query: () => ({
        url: "company/setup/status",
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(updateIsLoading(true));
          const { data } = await queryFulfilled;
          dispatch(updateChecklistState(data));
          dispatch(
            awarenessChecklistApiFromSimulation.endpoints.setupStatus.initiate(
              undefined,
              { forceRefetch: true },
            ),
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateMessagingPlatform: builder.mutation<
      void,
      UpdateMessagingPlatformRequest
    >({
      query: (data) => ({
        url: "company/setup/messaging-platform",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            awarenessChecklistApi.endpoints.setupStatus.initiate(undefined, {
              forceRefetch: true,
            }),
          );
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { useSetupStatusQuery, useUpdateMessagingPlatformMutation } =
  awarenessChecklistApi;

export const { useUpdateTeamsPolicyMutation } =
  awarenessChecklistApiFromSimulation;
