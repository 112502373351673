import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { FullPageSpinner } from "@/components/Spinner";
import { TemplateContent } from "@/pages/templates/TemplateContent";
import { useUserContext } from "@/utils/contexts/UserContext";

import { useSendTemplateToSelfMutation } from "./templateApi";
import {
  useCheckDenylistStatusQuery,
  useFetchTemplateContentQuery,
  useModifyTemplateDenylistMutation,
} from "./templateApi";
import { ActiveStatusEnum, processLabel } from "./templateUtils";

type TemplateDrawerProps = {
  name: string;
  isOpen: boolean;
  availableLanguages: string[];
  closeDrawer: () => void;
  shouldFetch: boolean;
  isChecked: boolean;
  onClickBottomButton: () => void;
  isInSelectionMode: boolean;
  enableActivationToggle: boolean;
};

export const TemplateDrawer = ({
  name,
  isOpen,
  availableLanguages,
  closeDrawer,
  shouldFetch,
  isChecked,
  onClickBottomButton,
  isInSelectionMode,
  enableActivationToggle,
}: TemplateDrawerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [sendTemplateToSelf, { isLoading: sendToMyselfInProgress }] =
    useSendTemplateToSelfMutation();

  const { language: userLanguage } = useUserContext();
  const [language, setLanguage] = useState(
    availableLanguages.includes(userLanguage)
      ? userLanguage
      : availableLanguages[0],
  );

  const { data, isError, isFetching, isSuccess } = useFetchTemplateContentQuery(
    {
      name: name,
      language: language,
    },
    { skip: !shouldFetch },
  );

  const [isActivated, setIsActivated] = useState(false);
  const [isToggleLocked, setIsToggleLocked] = useState(true);
  const [displayCompanyScanHelper, setDisplayCompanyScanHelper] =
    useState(false);
  const [tooltipContent, setTooltipContent] = useState("");

  const {
    data: denylistStatusData,
    isLoading: isDenylistStatusLoading,
    refetch: refetchDenylistStatus,
  } = useCheckDenylistStatusQuery(name, {
    skip: !isOpen, // query only when drawer is open
  });

  useEffect(() => {
    if (data && data.tags) {
      if (data.tags.length === 1) {
        setTooltipContent(
          `Add ${data.tags[0]} to your tag list in Company Scan to activate this template`,
        );
      } else if (data.tags.length > 1) {
        setTooltipContent(
          `Add one of the corresponding tags (${data.tags.join(
            ", ",
          )}) to your tag list in Company Scan to activate this template`,
        );
      } else {
        setTooltipContent(
          "This template doesn't have a tag associated to it, and therefore cannot be included via the Company Scan page.",
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      refetchDenylistStatus();
    }
  }, [isOpen, refetchDenylistStatus]);

  useEffect(() => {
    if (denylistStatusData) {
      if (denylistStatusData.status === ActiveStatusEnum.BLOCKED) {
        setIsActivated(false);
        setIsToggleLocked(false);
      } else if (denylistStatusData.status === ActiveStatusEnum.ACTIVE) {
        setIsActivated(true);
        setIsToggleLocked(false);
      } else if (denylistStatusData.status === ActiveStatusEnum.NOT_INCLUDED) {
        setIsActivated(false);
        setIsToggleLocked(true);
        setDisplayCompanyScanHelper(true);
      }
    }
  }, [denylistStatusData]);

  const [modifyTemplateDenylist] = useModifyTemplateDenylistMutation();

  const handleToggle = async () => {
    setIsToggleLocked(true);
    try {
      await toggleTemplate();
    } catch (error) {
      console.error("Failed to update template status:", error);
    } finally {
      setIsToggleLocked(false);
    }
  };

  const toggleTemplate = async () => {
    setIsToggleLocked(true);
    try {
      // switching to other state
      const newState = isActivated ? "disallowed" : "allowed";
      await modifyTemplateDenylist({
        templateName: name,
        state: newState,
      }).unwrap();
      setIsActivated(!isActivated);
    } catch (error) {
      console.error("Failed to update template status:", error);
    } finally {
      setIsToggleLocked(false);
    }
  };

  const handleSendTemplate = async () => {
    if (data) {
      try {
        await sendTemplateToSelf({
          template_name: name,
          template_language: language,
        }).unwrap();
        enqueueSnackbar(t("The email was sent successfully"), {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar(error.data.error, {
          variant: "error",
          style: { whiteSpace: "pre-line" },
        });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5) !important",
        },
      }}
    >
      <Box
        width="50vw"
        height="100vh"
        display="grid"
        gridTemplateRows="1fr 85vh 1fr"
      >
        <Stack
          sx={{
            background: theme.palette.background.paper,
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.divider,
          }}
          px="2rem"
          direction="row"
          alignItems="center"
        >
          <Typography variant="h4" sx={{ mr: 2 }}>
            {processLabel(name)}
          </Typography>
          <TextField
            select
            label="Language"
            size="small"
            value={language}
            disabled={availableLanguages.length < 2}
            onChange={(e) => setLanguage(e.target.value)}
            sx={{
              mr: 2,
              minWidth: "70px",
              "& .MuiInputBase-root": {
                width: "100%",
              },
            }}
          >
            {availableLanguages.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {enableActivationToggle && (
            <Box marginLeft="auto">
              <Tooltip
                title={displayCompanyScanHelper ? tooltipContent : ""}
                arrow
                placement="top"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={isActivated}
                        onChange={handleToggle}
                        disabled={isDenylistStatusLoading || isToggleLocked}
                      />
                    }
                    label={"Activated"}
                  />
                </FormGroup>
              </Tooltip>
            </Box>
          )}
        </Stack>
        <Box overflow="scroll" sx={{ display: "flex" }}>
          {isFetching ? <FullPageSpinner /> : ""}
          {isSuccess && !isFetching ? (
            <TemplateContent content={data.content} minHeight="70vh" />
          ) : (
            ""
          )}
          {isError ? t("An error occurred") : ""}
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            borderTop: "1px solid #E2E8F0",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            m="1rem"
            spacing={2}
          >
            <Button
              variant="outlined"
              startIcon={<SendIcon />}
              size="small"
              onClick={handleSendTemplate}
              disabled={sendToMyselfInProgress}
            >
              {sendToMyselfInProgress ? t("Sending...") : t("Send to myself")}
            </Button>
            {isInSelectionMode && (
              <Button
                variant="contained"
                startIcon={isChecked ? <RemoveIcon /> : <AddIcon />}
                size="small"
                onClick={onClickBottomButton}
              >
                {isChecked ? t("Remove") : t("Add")}
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};
