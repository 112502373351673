import React from "react";
import { Link } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";

import { EnumChip } from "@/components/EnumChip";
import { AttachmentsIcon } from "@/components/icons/AttachmentsIcon";
import { ClickRate } from "@/components/icons/ClickRate";
import { CredentialsIcon } from "@/components/icons/CredentialsIcon";
import { DownloadsIcon } from "@/components/icons/DownloadsIcon";
import { PermissionsIcon } from "@/components/icons/PermissionsIcon";
import {
  StyledBox,
  TableCellWithoutBorder,
} from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersRow";

export const ChipStatus = ({
  status,
  is_phished,
}: {
  status: string;
  is_phished: boolean;
}) => {
  if (status === "learning_followed") {
    if (is_phished) {
      status = "compromised";
    } else {
      status = "clicked";
    }
  }
  const statusConfig = {
    sent: { label: "Sent", color: "#909090" },
    reported: { label: "Reported", color: "#65C58B" },
    opened: { label: "Opened", color: "primary.main" },
    compromised: { label: "Compromised", color: "#F6285F" },
    clicked: { label: "Clicked", color: "#F2994A" },
  };

  return (
    <EnumChip value={status.toLowerCase()} defs={statusConfig} size="small" />
  );
};

export default function DataPerUserCollapseRow({ emailMessage }) {
  const EmailMessagesTypeIcon = ({ type }) => {
    const customStyle = { width: "15px", height: "15px", color: "#98A5B3" };
    switch (type) {
      case "Attachment":
        return <AttachmentsIcon sx={customStyle} />;
      case "Download":
        return <DownloadsIcon sx={customStyle} />;
      case "Permission":
        return <PermissionsIcon sx={customStyle} />;
      case "Credentials":
        return <CredentialsIcon sx={customStyle} />;
      default:
        return <ClickRate sx={customStyle} />;
    }
  };

  return (
    <TableRow>
      <TableCellWithoutBorder>
        <Typography variant={"body_small"}>{emailMessage.sent_date}</Typography>
      </TableCellWithoutBorder>
      <TableCellWithoutBorder>
        <Link
          to={`/dashboard/emailmessage?id=${emailMessage.id || emailMessage}`}
          style={{ color: "black" }}
        >
          <StyledBox
            sx={{
              border: "1px solid",
              borderColor: "#a7bef1",
              color: "#1662ff",
              backgroundColor: "#1662FF1A",
              maxWidth: "100%",
            }}
          >
            <Typography variant={"body_small"} noWrap>
              {emailMessage.template}
            </Typography>
          </StyledBox>
        </Link>
      </TableCellWithoutBorder>
      <TableCellWithoutBorder>
        <Stack direction="row" alignItems="center" gap="1px">
          <EmailMessagesTypeIcon type={emailMessage.type} />
          <Typography
            sx={{ paddingLeft: "5px" }}
            variant="body_small"
            color="text.secondary"
          >
            {emailMessage.type}
          </Typography>
        </Stack>
      </TableCellWithoutBorder>
      <TableCellWithoutBorder>
        {" "}
        <ChipStatus
          status={emailMessage.email_status}
          is_phished={emailMessage.is_phished}
        />
      </TableCellWithoutBorder>
    </TableRow>
  );
}
