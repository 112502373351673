import React from "react";

import { Chip, Grid, Tooltip } from "@mui/material";

import { capitalize } from "@/utils/strings";

export default function RiskChip({ danger_level, colors }) {
  const formatColor = Object.keys(colors).includes(danger_level)
    ? colors[danger_level]
    : colors["default"];

  const danger_label = formatColor.label ?? capitalize(danger_level);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <Tooltip title={danger_label}>
          <Chip
            icon={formatColor.icon}
            size="medium"
            sx={{
              color: formatColor.textColor,
              bgcolor: formatColor.bgColor,
              borderColor: formatColor.textColor,
              padding: "0 6px",
              width: "auto",
            }}
            variant="outlined"
            label={danger_label}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
}
