import { createSlice } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import { TemplateType } from "@/pages/templates/templateUtils";
import { RootState } from "@/store";

interface UserData {
  email: string;
  id: number;
}
export interface UsersSelected {
  label: string;
  user: UserData;
}
interface DepartmentData {
  id: number;
  name: string;
}
export interface DepartmentsSelected {
  label: string;
  department: DepartmentData;
}

export enum CreationStep {
  PARAMETER = "PARAMETER",
  TEMPLATE_SELECTION = "TEMPLATE_SELECTION",
  PREVIEW = "PREVIEW",
  VALIDATED = "VALIDATED",
}

export type Audience = "users" | "departments" | "everyone";

interface CustomCampaign {
  startDate?: Dayjs;
  endDate?: Dayjs;
  campaignName: string;
  id?: number;
  simulationsPerUser: number;
  selectedTemplates: Array<TemplateType>;
  selectedUsers: Array<UsersSelected>;
  selectedDepartments: Array<DepartmentsSelected>;
  targetedAudience: Audience;
  activeIndex: number;
  previewData: PreviewDataType;
  scheduleCampaignConfirmationOpen: boolean;
}

interface EmailScheduleType {
  recipient: string;
  templateTitle: string;
  tag: string;
  language: string;
  payload: string;
  sendingDate: string;
}
interface PreviewDataType {
  totalUsers: number;
  totalSimulation: number;
  listEmails: Array<EmailScheduleType>;
}
const getNextValidDate = (startFrom) => {
  let date = dayjs(startFrom);
  while (date.day() === 0 || date.day() === 6) {
    date = date.add(1, "day");
  }
  return date;
};

const startDate = getNextValidDate(dayjs().add(1, "day"));
const endDate = getNextValidDate(startDate.add(1, "day"));

const initialState: CustomCampaign = {
  id: null,
  startDate: startDate,
  endDate: endDate,
  campaignName: "",
  simulationsPerUser: 1,
  selectedTemplates: [],
  selectedUsers: [],
  selectedDepartments: [],
  targetedAudience: "everyone",
  activeIndex: 0,
  previewData: {
    totalUsers: 0,
    totalSimulation: 0,
    listEmails: [],
  },
  scheduleCampaignConfirmationOpen: false,
};

const NewCampaignSlice = createSlice({
  name: "newCampaign",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setCampaignData: (state, action) => {
      state.campaignName = action.payload.name;
      state.id = action.payload.id;
      state.startDate = action.payload.start_date;
      state.endDate = action.payload.end_date;
      state.targetedAudience = action.payload.target.target_audience;
      state.selectedUsers = action.payload.target.target_user_addresses;
      state.selectedDepartments = action.payload.target.target_department_ids;
      state.selectedTemplates = action.payload.templates;
      state.activeIndex = 0;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setSimulationsPerUser: (state, action) => {
      state.simulationsPerUser = action.payload;
    },
    setTargetedAudience: (state, action) => {
      state.targetedAudience = action.payload;
    },
    setSelectedDepartments: (state, action) => {
      state.selectedDepartments = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setPreviewData: (state, action) => {
      state.previewData.listEmails = [];
      action.payload.forEach((x) => {
        state.previewData.listEmails.push({
          recipient: x.recipient,
          templateTitle: x.template_title,
          tag: x.tag,
          language: x.language,
          payload: x.payload,
          sendingDate: x.sending_date,
        });
      });
      state.previewData.totalSimulation = state.previewData.listEmails.length;
      state.previewData.totalUsers = new Set(
        state.previewData.listEmails.map((data) => data.recipient),
      ).size;
    },
    setCampaignParameter: (state, action) => {
      state.campaignName = action.payload.campaignName;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.simulationsPerUser = action.payload.simulationsPerUser;
    },
    setSelectedTemplates: (state, action) => {
      const index = state.selectedTemplates.findIndex((x) => {
        return x.name === action.payload.name;
      });
      if (index !== -1) {
        state.selectedTemplates.splice(index, 1);
      } else {
        state.selectedTemplates.push(action.payload);
      }
    },
    setActiveStep: (state, action) => {
      state.activeIndex = action.payload;
    },
    setNextStep: (state) => {
      state.activeIndex += 1;
    },
    setCampaignName: (state, action) => {
      state.campaignName = action.payload;
    },
    setPrevStep: (state) => {
      state.activeIndex -= 1;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    resetState: (state) => {
      state = initialState;
      return state;
    },
    setScheduleCampaignConfirmationOpen: (state, action) => {
      state.scheduleCampaignConfirmationOpen = action.payload;
    },
  },
});

export const {
  setStartDate,
  setCampaignName,
  setEndDate,
  resetState,
  setCampaignData,
  setSimulationsPerUser,
  setTargetedAudience,
  setSelectedDepartments,
  setSelectedUsers,
  setSelectedTemplates,
  setNextStep,
  setPrevStep,
  setPreviewData,
  setActiveStep,
  setId,
  setCampaignParameter,
  setScheduleCampaignConfirmationOpen,
} = NewCampaignSlice.actions;

export const selectCustomCampaign = (state: RootState): CustomCampaign => {
  return state.customCampaign;
};

export const selectEmailToSchedule = (
  state: RootState,
): Array<EmailScheduleType> => {
  return state.customCampaign.previewData.listEmails;
};

export const selectStartDate = (state: RootState): Dayjs => {
  return state.customCampaign.startDate;
};
export const selectEndDate = (state: RootState): Dayjs => {
  return state.customCampaign.endDate;
};

export const selectActiveIndex = (state: RootState): number => {
  return state.customCampaign.activeIndex;
};
export const selectSelectedTemplates = (
  state: RootState,
): Array<TemplateType> => {
  return state.customCampaign.selectedTemplates;
};

export const selectUniqueUsers = (state: RootState): number => {
  return state.customCampaign.previewData.totalUsers;
};

export const selectTotalSimulations = (state: RootState): number => {
  return state.customCampaign.previewData.totalSimulation;
};

export const selectScheduleCampaignConfirmationOpen = (
  state: RootState,
): boolean => {
  return state.customCampaign.scheduleCampaignConfirmationOpen;
};

export default NewCampaignSlice.reducer;
