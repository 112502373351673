export const userScriptContents = (
  enterpriseToken: string,
  browserSpecificPath: string,
) => {
  return `
    $regPath = "HKCU:\\Software\\Policies\\${browserSpecificPath}"
    # Ensure the registry path exists
    New-Item -Path $regPath -Force

    # Write the EnterpriseToken to the registry
    New-ItemProperty -Path $regPath -Name "EnterpriseToken" -Value ${enterpriseToken} -Force
  `;
};
