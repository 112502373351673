import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FullPageSpinner } from "@/components/Spinner";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import {
  selectStepperFromChecklistState,
  setStepperState,
  useStatusQuery,
} from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import { StepperStateKey } from "@/pages/phishing-simulation/setup/SetupChecklistTypes";
import AttributeTemplatesToDepartments from "@/pages/phishing-simulation/setup/stepComponents/AttributeTemplatesToDepartments";
import CompanyScan from "@/pages/phishing-simulation/setup/stepComponents/CompanyScan";
import MailingPermissions from "@/pages/phishing-simulation/setup/stepComponents/MailingPermissions";
import MapGroupsToDepartments from "@/pages/phishing-simulation/setup/stepComponents/MapGroupsToDepartments";
import ProvisionAtLeastOneOtherUser from "@/pages/phishing-simulation/setup/stepComponents/ProvisionAtLeastOneOtherUser";
import ProvisioningPermissions from "@/pages/phishing-simulation/setup/stepComponents/ProvisioningPermissions";
import { ReportButtonDeployment } from "@/pages/phishing-simulation/setup/stepComponents/ReportButtonDeployment";
import TestDelivery from "@/pages/phishing-simulation/setup/stepComponents/TestDelivery";

const SimulationCurrentComponent: React.FC = () => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case StepperStateKey.MAILING_PERMISSIONS:
      return <MailingPermissions />;
    case StepperStateKey.PROVISIONING_PERMISSIONS:
      return <ProvisioningPermissions />;
    case StepperStateKey.PROVISION_ONE_USER:
      return <ProvisionAtLeastOneOtherUser />;
    case StepperStateKey.REPORT_BUTTON_DEPLOYMENT:
      return <ReportButtonDeployment />;
    case StepperStateKey.SIMULATION_DELIVERY_TEST:
      return <TestDelivery />;
    case StepperStateKey.COMPANY_SCAN:
      return <CompanyScan />;
    case StepperStateKey.MAP_GROUPS_DEPARTMENTS:
      return <MapGroupsToDepartments />;
    case StepperStateKey.ATTRIBUTE_TEMPLATES_DEPARTMENTS:
      return <AttributeTemplatesToDepartments />;
  }
};

export const SimulationSetupChecklist: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useStatusQuery();
  const steps = useSelector(selectStepperFromChecklistState);

  useEffect(() => {
    if (!isLoading && !isError) dispatch(setStepperState(data));
  }, [isLoading, isError, data, dispatch]);

  if (isError) {
    return <>{t("An error occurred")}</>;
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <SetupChecklist steps={steps}>
      <SimulationCurrentComponent />
    </SetupChecklist>
  );
};
