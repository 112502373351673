import { generateBrowserPath } from "../common";
import {
  adminPlatformScriptContents,
  userPlatformScriptContents,
} from "../content/platform";

export function generateAdminPlatformScript(
  browser: string,
  extensionID: string,
  enterpriseToken: string,
  extensionIDFirefox: string = "browser-defender@mantra.ms",
): string {
  const browserSpecificPath = generateBrowserPath(
    browser,
    extensionID,
    extensionIDFirefox,
  );
  return adminPlatformScriptContents(enterpriseToken, browserSpecificPath);
}

export function generateUserPlatformScript(
  browser: string,
  extensionID: string,
  enterpriseToken: string,
  extensionIDFirefox: string = "browser-defender@mantra.ms",
): string {
  const browserSpecificPath = generateBrowserPath(
    browser,
    extensionID,
    extensionIDFirefox,
  );
  return userPlatformScriptContents(enterpriseToken, browserSpecificPath);
}
