import React from "react";
import { useTranslation } from "react-i18next";

import { EnumChip } from "@/components/EnumChip";

export const CourseStatusChip = ({ course, learningUserInfo }) => {
  const { t } = useTranslation("translation", {
    lng: learningUserInfo.language,
  });

  const courseStatus = {
    ongoing: { label: t("ONGOING"), color: "#BE7A0C" },
    completed: { label: t("COURSE COMPLETED"), color: "#147B1E" },
    viewed: { label: t("COURSE FOLLOWED"), color: "#6DAF5F" },
    todo: { label: t("TO COMPLETE"), color: "#317AE4" },
  };

  let status = "todo";
  if (learningUserInfo.current_course === course.id) {
    status = "ongoing";
  } else if (learningUserInfo.finished_courses.includes(course.id)) {
    status = "completed";
  } else if (learningUserInfo.viewed_courses.includes(course.id)) {
    status = "viewed";
  }

  return (
    <EnumChip
      value={status}
      defs={courseStatus}
      size="small"
      style={{ borderRadius: "10px", width: "auto" }}
      sx={{ marginLeft: "10px" }}
    />
  );
};
