import React from "react";
import { useTranslation } from "react-i18next";

import { Box, IconButton, Tooltip, Typography, alpha } from "@mui/material";

import {
  Brave,
  Chrome,
  Edge,
  Generic,
  Opera,
} from "@/components/icons/BrowserLogos";
import { useExtensionsStatusColors } from "@/pages/browser-defender/components/extentionStatus";

function DeploymentDetailsTooltip({ data }) {
  const { t } = useTranslation();
  const statusColors = useExtensionsStatusColors();

  return (
    <Box sx={{ minWidth: "200px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>V{data.version}</Typography>
        <Typography
          style={{
            color: statusColors[data.extension_status].color,
            fontWeight: "bold",
          }}
        >
          {statusColors[data.extension_status].label}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
        <Typography style={{ fontWeight: "500", marginRight: "0.5rem" }}>
          {t("Last seen") + ": "}
        </Typography>
        <Typography>{data.latest_heartbeat}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "500", marginRight: "0.5rem" }}>
          {t("Browser") + ": "}
        </Typography>
        <Typography>
          {data.browser ? data.browser : t("Unknown")}{" "}
          {data.browser_version && `[${data.browser_version}]`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography style={{ fontWeight: "500", marginRight: "0.5rem" }}>
          {t("Platform") + ": "}
        </Typography>
        <Typography>
          {data.platform ? data.platform : t("Unknown")}{" "}
          {data.platform_version && `[${data.platform_version}]`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function ExtensionDeploymentDetails({ data }) {
  const statusColors = useExtensionsStatusColors();

  function getBrowserIcon(deployment, index) {
    let icon = <Generic color="primary" />;

    if (
      deployment.browser &&
      deployment.browser.toLowerCase().includes("brave")
    ) {
      icon = <Brave />;
    } else if (
      deployment.browser &&
      deployment.browser.toLowerCase().includes("chrome")
    ) {
      icon = <Chrome />;
    } else if (
      deployment.browser &&
      deployment.browser &&
      deployment.browser.toLowerCase().includes("edge")
    ) {
      icon = <Edge />;
    } else if (
      deployment.browser &&
      deployment.browser.toLowerCase().includes("opera")
    ) {
      icon = <Opera />;
    }

    return (
      <Tooltip
        key={index}
        title={<DeploymentDetailsTooltip data={deployment} />}
      >
        <IconButton
          style={{
            border: "1px",
            backgroundColor: alpha(
              statusColors[deployment.extension_status].color,
              0.1,
            ),
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Box sx={{ textAlign: "center" }}>
      {data.active_deployments.map((item, index) =>
        getBrowserIcon(item, index),
      )}
    </Box>
  );
}
