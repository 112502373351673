import React from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";

import { ModalCourseAssignation } from "@/pages/awareness/courses/courses-catalog/ModalCourseAssignation";
import {
  selectHasFilter,
  selectOnboardingCourseBySearch,
  selectOnboardingCourses,
  selectRunCourses,
  selectRunCoursesBySearch,
  setOnboardingCourses,
  setRunCourses,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { DraggableCourseCardContainer } from "@/pages/awareness/courses/courses-catalog/demo-course-catalog/DraggableCourseCardContainer";
import { SectionTitleComponent } from "@/pages/awareness/courses/courses-catalog/demo-course-catalog/SectionTitleComponent";
import { CoursesType } from "@/pages/awareness/courses/utils/courseTypes";

import { move, reorder } from "./helpers";

export const CourseCardsContainerListDraggable = ({
  isPublicWebchat,
  courses,
  learningUserInfos,
}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const courseOnboardingFiltered = useSelector(selectOnboardingCourseBySearch);
  const courseOnboarding = useSelector(selectOnboardingCourses);
  const courseRunModeFiltered = useSelector(selectRunCoursesBySearch);
  const hasFilter = useSelector(selectHasFilter);
  const courseRunMode = useSelector(selectRunCourses);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getProperState = (droppable_id: string) => {
    if (droppable_id === "droppable_onboarding") {
      return courseOnboarding;
    }
    return courseRunMode;
  };

  const setProperState = (droppable_id: string, items: CoursesType[]) => {
    if (droppable_id === "droppable_onboarding") {
      dispatch(setOnboardingCourses(items));
    } else {
      dispatch(setRunCourses(items));
    }
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items: CoursesType[] = reorder(
        getProperState(source.droppableId),
        source.index,
        destination.index,
      );
      setProperState(source.droppableId, items);
    } else {
      const result = move(
        getProperState(source.droppableId),
        getProperState(destination.droppableId),
        source,
        destination,
      );
      dispatch(setOnboardingCourses(result["droppable_onboarding"]));
      dispatch(setRunCourses(result["droppable_run"]));
    }
  };

  const onClickAssignment = (course: CoursesType) => {
    setSelectedCourse(course);
    setOpenModal(true);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {hasFilter && (
        <Typography sx={{ marginLeft: "3rem", color: "red" }}>
          {t("Unable to drag and drop while filters are applied")}
        </Typography>
      )}
      <SectionTitleComponent title={"ONBOARDING TRACK"} />
      <Droppable
        droppableId="droppable_onboarding"
        key={"droppable_onboarding"}
      >
        {(provided) => (
          <Grid
            container
            sx={{ minHeight: "20px" }}
            spacing={2}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {courseOnboardingFiltered.map((course: CoursesType, index) => (
              <DraggableCourseCardContainer
                key={index}
                course={course}
                index={index}
                onClickAssignment={onClickAssignment}
              />
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
      <SectionTitleComponent title={"RUN MODE"} />
      <Droppable droppableId="droppable_run" key={"droppable_run"}>
        {(provided) => (
          <Grid
            container
            spacing={2}
            sx={{ minHeight: "20px" }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {courseRunModeFiltered.map((course: CoursesType, index) => (
              <DraggableCourseCardContainer
                key={index}
                course={course}
                index={index}
                onClickAssignment={onClickAssignment}
              />
            ))}

            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
      {selectedCourse && (
        <ModalCourseAssignation
          openModal={openModal}
          learningUserInfos={learningUserInfos}
          setOpenModal={setOpenModal}
          selectedCourse={selectedCourse}
        />
      )}
    </DragDropContext>
  );
};
