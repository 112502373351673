import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import css from "@/pages/landing-pages/components/LandingPage.module.css";

export type SupportedLanguage = "en" | "fr";

const LandingPageLayout = (props: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <div className={css.message_container}>
        <img
          src="/media/logos/logo_mantra_loading_screen.png"
          alt="Mantra logo"
        />
        {props.children}
        <p className={css.small_message}>{t("landing.close")}</p>
      </div>
      <div className={css.footer}>
        <p>
          {t("landing.footer")}{" "}
          <a href="mailto:team@mantra.ms">{"team@mantra.ms"}</a>
        </p>
      </div>
    </div>
  );
};

export default LandingPageLayout;
