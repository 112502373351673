import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Stack, Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import { useTemplateCatalogContext } from "@/pages/templates/TemplateCatalogContextProvider";

import { TemplateSearchbar } from "./TemplateSearchbar";

export const TemplateHeader = () => {
  const catalogContext = useTemplateCatalogContext();
  const { t } = useTranslation();

  return (
    <>
      <StickyHeader height="110px" zIndex={100}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack direction="column" gap={3}>
            <Typography
              variant="h2"
              style={{
                maxHeight: "2rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {catalogContext.isInSelectionMode
                ? t("Template Selection")
                : t("Template Catalog")}
            </Typography>
            <TemplateSearchbar />
          </Stack>
          {catalogContext.createTemplate &&
            catalogContext.isInSelectionMode && (
              <Button
                variant={"outlined"}
                sx={{ height: "4rem" }}
                size="small"
                onClick={() => catalogContext.createTemplate()}
              >
                {t("Create template")}
              </Button>
            )}
        </Box>
      </StickyHeader>
    </>
  );
};
