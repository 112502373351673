import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  enabled: !import.meta.env.DEV, // disable sentry in dev
  dsn: "https://aa38ae0dbc1d42ba8247872f43d5ac85@o401641.ingest.sentry.io/5261681",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  release: import.meta.env.VITE_APP_CI_COMMIT_SHA || "Manual",
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
  attachStacktrace: true, // doc: Attaches stacktraces to pure capture message / log integrations
  ignoreErrors: [
    "Can't find variable: gmo", // https://stackoverflow.com/questions/79434372/sentry-errors-variable-not-found-gmo-invalid-or-unexpected-token-due-to-go
    "Failed to execute 'removeChild' on 'Node'",
    "Failed to execute 'insertBefore' on 'Node'",
    "Non-Error promise rejection captured", // spurious error triggered by extensions https://github.com/getsentry/sentry-javascript/issues/3440
  ],
});
