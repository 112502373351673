import {
  getActiveUserProfileFromEntra,
  getActiveUserProfileFromLocal,
  refreshHKCUHive,
} from "./functions";

export const detectAdminScriptContents = (browserSpecificPath: string) => {
  return `
    ${getActiveUserProfileFromEntra}

    ${getActiveUserProfileFromLocal}

    ${refreshHKCUHive}

    function Detect-UserSpecificPathExists {
      param(
        [string]$userRegPath
      )

      if (Test-Path $userRegPath) {
        Write-Host "Registry key exists."
        Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
        Exit 0
      } else {
        Write-Host "Registry key missing."
        Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
        Exit 1  # Non-zero exit code triggers remediation
      }
    }

    $activeUser = Get-ActiveUserProfileEntra

    if ($activeUser) {
      $loggedInUser = $activeUser.Username
      $loggedInUserSID = $activeUser.SID
      Write-Host "Active user detected Entra/AD - Username: $loggedInUser, SID: $loggedInUserSID"
    } else {
      # Fallback to local accounts
      $activeUser = Get-ActiveUserProfileLocal
      if ($activeUser) {
        $loggedInUser = $activeUser.Username
        $loggedInUserSID = $activeUser.SID
        Write-Host "Active user detected local - Username: $loggedInUser, SID: $loggedInUserSID"
      }
    } 

    $currentUser = $loggedInUser

    # Create a new PSDrive for the user's hive
    New-PSDrive -Name HKU -PSProvider Registry -Root HKEY_USERS | Out-Null

    $userRegPath = "HKU:\\$loggedInUserSID\\$sid\\Software\\Policies\\${browserSpecificPath}"

    # Process the path
    Write-Host "Processing $browser path: $userRegPath"
    Detect-UserSpecificPathExists -userRegPath $userRegPath
  `;
};

export const detectUserScriptContents = (browserSpecificPath: string) => {
  return `
    function Detect-UserSpecificPathExists {
      param(
        [string]$userRegPath
      )

      if (-not (Test-Path $userRegPath)) {
        Write-Host "Registry key missing. - no remediation needed"
        Exit 0
      }

      $enterpriseToken = Get-ItemProperty -Path $userRegPath -Name "EnterpriseToken" -ErrorAction SilentlyContinue
      $enterpriseUserId = Get-ItemProperty -Path $userRegPath -Name "EnterpriseUserId" -ErrorAction SilentlyContinue


      if ($enterpriseToken -and $enterpriseUserId) {
        Write-Host "Registry key and required values exist. - no remediation needed"
        Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
        Exit 0  # no remediation needed
      } else {
        Write-Host "Registry key required values do not exist. - remediation needed"
        Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
        Exit 1  # Exit code 1 indicates remediation needed
      }
    
    }

    $sid = (New-Object System.Security.Principal.NTAccount($env:USERNAME)).Translate([System.Security.Principal.SecurityIdentifier]).Value

    # Create a new PSDrive for the user's hive
    New-PSDrive -Name HKU -PSProvider Registry -Root HKEY_USERS | Out-Null

    $userRegPath = "HKU:\\$sid\\Software\\Policies\\${browserSpecificPath}"

    # Process the path
    Write-Host "Processing $browser path: $userRegPath"
    Detect-UserSpecificPathExists -userRegPath $userRegPath
  `;
};
