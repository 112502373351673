import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch, useSelector } from "react-redux";

import { courseEditorApi } from "@/api";
import { usersApi } from "@/apis/usersApi";
import { learningApi } from "@/learningApi";
import { listenerMiddleware } from "@/listenerMiddleware";
import { courseSharingApi } from "@/pages/awareness/courses/corporate-course-sharing/courseSharingApi";
import CourseSharingReducer from "@/pages/awareness/courses/corporate-course-sharing/courseSharingSlice";
import CourseReducer from "@/pages/awareness/courses/course-editor/courseSlice";
import CourseCatalogReducer from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import AwarenessChecklistReducer from "@/pages/awareness/setup/SetupChecklistSlice";
import { teamsInstallationApi } from "@/pages/awareness/setup/Teams/teamsInstallationApi";
import { awarenessUsersSettingsApi } from "@/pages/awareness/userApi";
import AwarenessUsersSettingsReducer from "@/pages/awareness/userSlice";
import { bannersUsersApi } from "@/pages/banners/remote-apis/bannerUsersApi";
import {
  bannersSetupChecklistApi,
  bannersSetupChecklistReducer,
} from "@/pages/banners/setup/BannerSetupChecklist";
import PermissionsReducer from "@/pages/general-settings/permissions-attribution/permissionsSlice";
import { permissionsApi } from "@/pages/general-settings/permissions-attribution/remoteApis";
import { customCampaignApi } from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignApi";
import CustomCampaignReducer from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignCreate/CustomCampaignSlice";
import SimulationChecklistReducer, {
  simulationSetupChecklistApi,
} from "@/pages/phishing-simulation/setup/SetupChecklistSlice";
import { templateApi } from "@/pages/templates/templateApi";
import TemplateReducer from "@/pages/templates/templateSlice";
import { threatsApi } from "@/pages/threats/api";
import { threatReducer } from "@/pages/threats/service/threatsSlice";
import AuthReducer from "@/utils/contexts/AuthSlice";
import { userApi } from "@/utils/contexts/userApi";
import { loadUserPreferences } from "@/utils/storePersistence";
import { userPreferences } from "@/utils/userPreferencesSlice";

import {
  awarenessChecklistApi,
  awarenessChecklistApiFromSimulation,
} from "./pages/awareness/setup/awarenessChecklistApi";
import { leaderboardApi } from "./pages/my-progress/leaderboard/LeaderboardApi";
import { simulationDashboardApi } from "./pages/phishing-simulation/dashboard/api";

const store = configureStore({
  preloadedState: {
    userPreference: loadUserPreferences(),
  },
  reducer: {
    auth: AuthReducer,
    awarenessChecklist: AwarenessChecklistReducer,
    awarenessUser: AwarenessUsersSettingsReducer,
    bannersChecklist: bannersSetupChecklistReducer,
    courseCatalog: CourseCatalogReducer,
    courseEditor: CourseReducer,
    courseSharing: CourseSharingReducer,
    simulationChecklist: SimulationChecklistReducer,
    template: TemplateReducer,
    customCampaign: CustomCampaignReducer,
    threats: threatReducer,
    userPreference: userPreferences.reducer,
    usersWithPermissions: PermissionsReducer,
    [awarenessChecklistApi.reducerPath]: awarenessChecklistApi.reducer,
    [awarenessChecklistApiFromSimulation.reducerPath]:
      awarenessChecklistApiFromSimulation.reducer,
    [awarenessUsersSettingsApi.reducerPath]: awarenessUsersSettingsApi.reducer,
    [customCampaignApi.reducerPath]: customCampaignApi.reducer,
    [bannersSetupChecklistApi.reducerPath]: bannersSetupChecklistApi.reducer,
    [bannersUsersApi.reducerPath]: bannersUsersApi.reducer,
    [courseEditorApi.reducerPath]: courseEditorApi.reducer,
    [courseSharingApi.reducerPath]: courseSharingApi.reducer,
    [leaderboardApi.reducerPath]: leaderboardApi.reducer,
    [learningApi.reducerPath]: learningApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [simulationSetupChecklistApi.reducerPath]:
      simulationSetupChecklistApi.reducer,
    [teamsInstallationApi.reducerPath]: teamsInstallationApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [threatsApi.reducerPath]: threatsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [simulationDashboardApi.reducerPath]: simulationDashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .prepend(listenerMiddleware.middleware)
      .concat(
        awarenessUsersSettingsApi.middleware,
        bannersSetupChecklistApi.middleware,
        bannersUsersApi.middleware,
        courseEditorApi.middleware,
        courseSharingApi.middleware,
        customCampaignApi.middleware,
        leaderboardApi.middleware,
        learningApi.middleware,
        permissionsApi.middleware,
        simulationSetupChecklistApi.middleware,
        teamsInstallationApi.middleware,
        templateApi.middleware,
        threatsApi.middleware,
        userApi.middleware,
        usersApi.middleware,
        learningApi.middleware,
        awarenessChecklistApi.middleware,
        awarenessChecklistApiFromSimulation.middleware,
        simulationDashboardApi.middleware,
      ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const appDispatch = useDispatch.withTypes<AppDispatch>();
export const appSelector = useSelector.withTypes<RootState>();

setupListeners(store.dispatch);

export default store;
