import { cloneDeep } from "lodash";

import { T } from "@/components/LocalizedString";
import { SetupStep } from "@/components/stepper/Stepper";

import {
  AwarenessSetupChecklist,
  AwarenessStepperStateKey,
  MessagingPlatform,
} from "../SetupChecklistTypes";

export class SetupStepper {
  readonly steps: SetupStep[];

  constructor(state: AwarenessSetupChecklist) {
    const defaultStepperState: SetupStep[] = [];
    defaultStepperState[AwarenessStepperStateKey.MESSAGING_PLATFORM] = {
      label: T("Messaging Platform"),
      status: "uncompleted",
      mandatory: true,
      disablePrevNextButtonPane: false,
    };
    defaultStepperState[AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED] =
      {
        label: T("Deploy Alex"),
        status: "uncompleted",
        mandatory: true,
      };
    defaultStepperState[AwarenessStepperStateKey.ONE_COURSE_ASSIGNED] = {
      label: T("Assign your first course"),
      status: "uncompleted",
      mandatory: false,
    };
    defaultStepperState[AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED] = {
      label: T("Activate automatic course delivery"),
      status: "uncompleted",
      mandatory: false,
    };
    defaultStepperState[AwarenessStepperStateKey.TEAMS_POLICIES] = {
      label: T("Teams Policies"),
      status: "uncompleted",
      mandatory: true,
    };

    this.steps = cloneDeep(defaultStepperState);
    this.initStepper(state);
    this.filterSteps(state);
  }

  initStepper(state: AwarenessSetupChecklist) {
    const keys = Object.values(AwarenessStepperStateKey).filter(
      (key) => !isNaN(Number(key)),
    );
    keys.forEach((key) => {
      stepsInitializer?.[key]?.(state, this.steps[key]);
    });
  }

  filterSteps(state: AwarenessSetupChecklist) {
    if (state.messagingPlatform !== MessagingPlatform.TEAMS) {
      this.steps.splice(AwarenessStepperStateKey.TEAMS_POLICIES, 1);
    }
  }
}

const stepsInitializer: ((
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => void)[] = [];
stepsInitializer[AwarenessStepperStateKey.MESSAGING_PLATFORM] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.messagingPlatform != null) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.MESSAGING_PLATFORM_INSTALLED] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.messagingPlatformInstalled === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.ONE_COURSE_ASSIGNED] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.oneCourseAssigned === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.COURSE_DELIVERY_ACTIVATED] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState.courseDeliveryActivated === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "error";
  }
};

stepsInitializer[AwarenessStepperStateKey.TEAMS_POLICIES] = (
  checklistSliceState: AwarenessSetupChecklist,
  currentStepState: SetupStep,
) => {
  if (checklistSliceState?.teamsPoliciesReviewed === true) {
    currentStepState.status = "succeeded";
  } else {
    currentStepState.status = "uncompleted";
  }
};
