import { isEmpty } from "lodash";
import { useSnackbar } from "material-ui-snackbar-provider";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";

import { useCreateDraftFromLocalizedCourseMutation } from "@/api";
import { ButtonSpinner } from "@/components/Spinner";
import PencilIcon from "@/components/icons/PencilIcon";
import { useTestLocalizedCourse } from "@/pages/awareness/courses/course-editor/hooks";

import {
  selectCurrentLanguage,
  selectCurrentLocalizedCourse,
  selectDraftLocalizedCourses,
  toggleEditMode,
} from "../courseSlice";

const EditContentEditorHeader = () => {
  const [createDraft, { isLoading: isLoadingDraft }] =
    useCreateDraftFromLocalizedCourseMutation();

  const draftLocalizedCourses = useSelector(selectDraftLocalizedCourses);
  const dispatch = useDispatch();
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  useTestLocalizedCourse();
  const currentLanguage = useSelector(selectCurrentLanguage);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const onClickEditContent = async () => {
    const currentLanguageDraft = draftLocalizedCourses[currentLanguage];
    if (!isEmpty(currentLanguageDraft)) {
      dispatch(toggleEditMode());
    } else if (localizedCourse?.id != null) {
      const response = await createDraft({
        id: localizedCourse?.id,
      });
      // @ts-ignore
      if (response.error === undefined) {
        dispatch(toggleEditMode());
        snackbar.showMessage(
          t("New draft of published course successfully created"),
        );
      } else {
        snackbar.showMessage(t("An error occurred. Please retry"));
      }
    } else {
      snackbar.showMessage(t("An error occurred. Please retry"));
    }
  };

  return (
    <Button
      size="small"
      variant="outlined"
      color="neutral"
      onClick={onClickEditContent}
      disabled={isLoadingDraft}
      startIcon={isLoadingDraft ? <ButtonSpinner /> : <PencilIcon />}
    >
      {t("Edit content")}
    </Button>
  );
};

export default EditContentEditorHeader;
