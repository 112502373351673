import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import BarChartStacked from "@/pages/browser-defender/components/BarChartStacked";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import TimeFrameDropdown from "@/pages/browser-defender/components/TimeFrameDropdown";
import DataTable from "@/pages/browser-defender/dangerousBrowsing/DataTable";
import StatCards from "@/pages/browser-defender/dangerousBrowsing/StatCards";
import { useUserContext } from "@/utils/contexts/UserContext";

export function DangerousBrowsing() {
  const theme = useTheme();
  const [activeFeature, setActiveFeature] = useState(true);
  const [dateFromNbWeeksAgo, setDateFromNbWeeksAgo] = useState(12);
  const [distribution, setDistribution] = useState({
    values: [],
    interval: 12,
    loading: true,
  });
  const [logsData, setLogsData] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });

  const [stats, setStats] = useState({
    values: {},
    loading: true,
    error: false,
  });
  const { t } = useTranslation();

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();

  const fetchDistribution = useCallback(
    async (interval) => {
      try {
        setDistribution((prev) => ({ ...prev, loading: true, values: [] }));
        const accessToken = await getAccessTokenSilently();

        const url = new URL(
          `${
            import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
          }/distribution/unsafe_navigation`,
        );

        let resource;

        try {
          resource = await axios.get(`${url}?interval=${interval}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          if (e.response.status === 400) {
            setActiveFeature(false);
            return;
          }
        }

        setDistribution((prev) => ({
          ...prev,
          values: resource.data.distribution,
          loading: false,
        }));
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [getAccessTokenSilently],
  );

  const fetchLogs = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/logs/unsafe_navigation`;

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setActiveFeature(false);
          return;
        }
      }

      setLogsData({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [getAccessTokenSilently]);

  const fetchStats = useCallback(
    async (interval) => {
      try {
        const accessToken = await getAccessTokenSilently();
        const url = new URL(
          `${
            import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
          }/stats/unsafe_navigation`,
        );

        let resource;
        try {
          resource = await axios.get(`${url}?interval=${interval}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } catch (e) {
          if (e.response.status === 400) {
            setStats((prev) => ({
              ...prev,
              loading: false,
              error: true,
            }));
            setActiveFeature(false);
            return;
          }
        }

        setStats({
          values: resource.data,
          loading: false,
          error: false,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchStats(dateFromNbWeeksAgo);
    fetchDistribution(dateFromNbWeeksAgo);
    fetchLogs();
  }, [fetchStats, fetchDistribution, fetchLogs, dateFromNbWeeksAgo, info_user]);

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  return (
    <Box>
      <StickyHeader>
        <Stack direction="row" gap={3} justifyContent="end" alignItems="center">
          <Typography
            variant="h2"
            style={{
              marginRight: "auto",
              maxHeight: "2rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {t("Dangerous Browsing")}
          </Typography>
          <TimeFrameDropdown
            dateFromNbWeeksAgo={dateFromNbWeeksAgo}
            setDateFromNbWeeksAgo={setDateFromNbWeeksAgo}
            setSelectedPeriod={() => {}}
            intervalType="months"
          />
        </Stack>
      </StickyHeader>
      <Grid container alignItems="stretch">
        <Grid item xs={12}>
          <GenericCard
            title={t("Event Distribution")}
            isLoading={distribution.loading}
            padding="0"
          >
            {distribution.values && (
              <BarChartStacked
                data={distribution.values}
                labels={[
                  {
                    name: t("Total"),
                    key: "count",
                    color: theme.palette.charts.primary.main,
                  },
                  {
                    name: t("Visited"),
                    key: "completed",
                    color: theme.palette.charts.secondary.main,
                  },
                ]}
              />
            )}
          </GenericCard>
        </Grid>
        <StatCards data={stats} />
        <Grid item xs={12}>
          <GenericCard title={t("Event Logs")} isLoading={logsData.loading}>
            <DataTable data={logsData} setData={setLogsData} />
          </GenericCard>
        </Grid>
      </Grid>
    </Box>
  );
}
