import React from "react";
import { useTranslation } from "react-i18next";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, Typography } from "@mui/material";

export type ServiceAccountStatusProps = {
  is_account_set_up: boolean;
  account_id: string | null;
};

const ServiceAccountStatus = ({
  is_account_set_up,
  account_id,
}: ServiceAccountStatusProps) => {
  const { t } = useTranslation();
  return is_account_set_up ? (
    <Box>
      <Box display="flex" flexDirection="row" gap="1rem">
        <Typography>{t("User Service Account setup")}</Typography>
        <CheckCircleOutlinedIcon color="success" />
        <Typography>
          {t("AccountID")}: {account_id}
        </Typography>
      </Box>
      <Box>
        <Typography></Typography>
      </Box>
    </Box>
  ) : (
    <Box display="flex" flexDirection="row" gap="1rem">
      <Typography>{t("User Service Account not setup")}</Typography>
      <WarningAmberOutlinedIcon color="orange" />
    </Box>
  );
};

export default ServiceAccountStatus;
