import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LaunchIcon from "@mui/icons-material/Launch";
import TableCell from "@mui/material/TableCell";

import CollapsibleTable from "@/pages/browser-defender/components/CollapsibleTable";
import ExtensionDeploymentDetails from "@/pages/browser-defender/components/ExtensionDeploymentDetails";
import StyledTableRow from "@/pages/browser-defender/styles/StyledTableRow";
import { ExtensionStatus } from "@/pages/browser-defender/users/ExtensionStatus";

const filterColumns = ["firstname", "lastname", "email", "active_deployments"];

export default function DataTable({
  data,
  setData,
  userStatus,
  userStatusLabel,
  setUserStatus,
}) {
  const { t } = useTranslation();

  const headers = [
    { label: t("First Name"), alignment: "left" as const },
    { label: t("Last Name"), alignment: "left" as const },
    { label: t("Email"), alignment: "left" as const },
    { label: t("Status"), alignment: "center" as const },
    { label: t("Active deployments"), alignment: "center" as const },
  ];

  const sortingColumns = [
    { label: t("First Name"), name: "firstname" },
    { label: t("Last Name"), name: "lastname" },
    { label: t("Email"), name: "email" },
  ];
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    let filteredValues;

    if (!searchTerm) {
      filteredValues = data.values;
    } else {
      filteredValues = data.values.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          if (attribute === "active_deployments") {
            let matches = [];
            if (searchTerm === "unprotected") {
              return row[attribute].length === 0;
            }

            const actualSearchTerm =
              searchTerm === "with_partial_defense"
                ? "partially_configured"
                : searchTerm;

            matches = row[attribute].filter((deployment) => {
              return (
                deployment.extension_status.toLowerCase() ===
                actualSearchTerm.toLowerCase()
              );
            });

            return matches.length > 0;
          }

          let value;

          try {
            value = row[attribute].toLowerCase();
          } catch {
            value = row[attribute];
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    filteredValues.sort((p1, p2) => {
      let condition = 1;
      for (let sortRule of sorting) {
        const value1 = p1[sortRule.column] ? p1[sortRule.column] : "";
        const value2 = p2[sortRule.column] ? p2[sortRule.column] : "";

        condition *=
          value1.toLowerCase() < value2.toLowerCase()
            ? -1 * sortRule.direction
            : value1.toLowerCase() > value2.toLowerCase()
              ? 1 * sortRule.direction
              : 0;
      }
      return condition;
    });

    setData({
      values: data.values,
      filteredValues: filteredValues,
      loading: false,
    });
  }, [searchTerm, sorting, data.values, setData, userStatus]);

  useEffect(() => {
    if (userStatus) {
      setSearchTerm(userStatus);
    }
  }, [userStatus]);

  function clearUserStatusFilter() {
    setUserStatus("");
    setSearchTerm("");
  }

  return (
    <CollapsibleTable
      headers={headers}
      sortingColumns={sortingColumns}
      setSorting={setSorting}
      setSearchTerm={setSearchTerm}
      customFilter={userStatusLabel}
      clearCustomFilter={clearUserStatusFilter}
      defaultOrder={[{ column: "lastname", direction: 1 }]}
      rows={data.filteredValues.map((row, index) => {
        return (
          <StyledTableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" align={headers[0].alignment}>
              {row.firstname}
            </TableCell>
            <TableCell align={headers[1].alignment}>{row.lastname}</TableCell>
            <TableCell align={headers[2].alignment}>
              <Link
                to="/browser-defender/user-details"
                state={{ email: row.email }}
              >
                <LaunchIcon sx={{ fontSize: "15px" }} /> {row.email}
              </Link>
            </TableCell>
            <TableCell align={headers[3].alignment}>
              <ExtensionStatus data={row.active_deployments} />
            </TableCell>
            <TableCell
              align={headers[4].alignment}
              sx={{
                width: "25%",
                maxWidth: "25%",
                textOverflow: "ellipsis",
                whiteSpace: "break-word",
              }}
            >
              <ExtensionDeploymentDetails data={row} />
            </TableCell>
          </StyledTableRow>
        );
      })}
    />
  );
}
