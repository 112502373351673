import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
  TextField,
  alpha,
} from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";

import { Spinner } from "@/components/Spinner";
import SortComponent from "@/components/sort/SortComponent";
import DataPerUsersTable from "@/pages/phishing-simulation/dashboard/DataPerUsersTable/DataPerUsersTable";

import useSimulationDataPerUser from "./hooks/useSimulationDataPerUser";
import useSimulationDataPerUserHeaders from "./hooks/useSimulationDataPerUserHeaders";

export default function UsersTable({ campaignId }: { campaignId?: number }) {
  const { t } = useTranslation();
  const { sortChoices } = useSimulationDataPerUserHeaders();
  const {
    search,
    changeSearch,
    data,
    isError,
    isLoading,
    onClickDownload,
    changeSort,
    sort,
    rowsPerPage,
    setRowsPerPage,
    pageNumber,
    setPageNumber,
  } = useSimulationDataPerUser(campaignId);

  if (isError) return <div>{t("An error occurred")}</div>;

  return (
    <>
      <Card>
        <CardHeader
          title={t("Data per user")}
          action={
            <Button variant={"outlined"} onClick={onClickDownload}>
              {t("Export users")}
            </Button>
          }
        />

        <CardContent>
          {isLoading && !data?.users && <Spinner />}

          <Box height={"20px"}>
            {isLoading && data?.users && <LinearProgress />}
          </Box>

          {data?.users && (
            <>
              <Stack direction="row" gap=".5rem" marginBottom="10px">
                <TextField
                  InputProps={{
                    startAdornment: <GridSearchIcon />,
                  }}
                  value={search}
                  size={"small"}
                  label={t("Search")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeSearch(e.target.value)
                  }
                  sx={(theme) => ({
                    background:
                      search?.length > 0
                        ? alpha(
                            theme.palette.primary.main,
                            theme.palette.action.selectedOpacity,
                          )
                        : "",
                    borderRadius: "5px",
                  })}
                />
                <SortComponent
                  initOrderBy={sort}
                  setOrderBy={changeSort}
                  orderChoices={sortChoices}
                />
              </Stack>
              <DataPerUsersTable
                data={data}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
}
