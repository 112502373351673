import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/system";

import {
  selectIsMantraUser,
  selectUsesFormalTone,
} from "@/utils/contexts/AuthSlice";
import { languageDisplayName } from "@/utils/misc";

import NewLanguageSelector from "../NewLanguageSelector";
import {
  changeLanguage,
  selectAllAvailableLanguages,
  selectAvailableDraftsLanguages,
  selectAvailablePublishedLanguages,
  selectCurrentLanguage,
  selectIsProvidedByMantra,
} from "../courseSlice";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 550,
  paddingLeft: theme.spacing(4),
  "&.Mui-disabled": {
    fontWeight: 200,
  },
  "&.AvailableDraftLanguage": {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: theme.spacing(1),
      transform: "translateY(-50%)",
      display: "block",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      background: "#ffa5a5",
    },
  },
  "&.OnlyDraftLanguage": {
    color: "rgb(180, 180, 180)",
  },
}));

export const useVisibleLanguageTabs = () => {
  const allAvailableLanguages = useSelector(selectAllAvailableLanguages);
  const availablePublishedLanguages = useSelector(
    selectAvailablePublishedLanguages,
  );
  const availableDraftLanguages = useSelector(selectAvailableDraftsLanguages);
  const useFormalTone = useSelector(selectUsesFormalTone);
  const isMantraUser = useSelector(selectIsMantraUser);

  let language_filter: undefined | ((language: string) => boolean) = undefined;
  if (isMantraUser) {
    language_filter = (language: string) => true;
  } else if (useFormalTone) {
    language_filter = (language: string) => language !== "fr";
  } else {
    language_filter = (language: string) => language !== "fr_FR@formal";
  }

  return {
    allAvailableLanguages: allAvailableLanguages
      .filter(language_filter)
      .sort((a, b) =>
        languageDisplayName(a, isMantraUser).localeCompare(
          languageDisplayName(b, isMantraUser),
        ),
      ),
    availableDraftLanguages: availableDraftLanguages.filter(language_filter),
    availablePublishedLanguages:
      availablePublishedLanguages.filter(language_filter),
  };
};

const LanguageDropdown = () => {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const {
    allAvailableLanguages,
    availablePublishedLanguages,
    availableDraftLanguages,
  } = useVisibleLanguageTabs();
  const isProvidedByMantra = useSelector(selectIsProvidedByMantra);
  const isMantraUser = useSelector(selectIsMantraUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const displayRedDot = (language: string) =>
    (!isProvidedByMantra || isMantraUser) &&
    availableDraftLanguages.includes(language);
  const displayOnlyDraftGrey = (language: string) =>
    (!isProvidedByMantra || isMantraUser) &&
    !availablePublishedLanguages.includes(language);

  return (
    <Box
      sx={{
        marginRight: "auto",
        display: "flex",
        alignItems: "flex-start",
        maxWidth: "75%",
      }}
    >
      <FormControl size="small" sx={{ minWidth: 150 }}>
        <InputLabel id="language-select-label">{t("Language")}</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={currentLanguage}
          onChange={(e) => dispatch(changeLanguage(e.target.value))}
          label={t("Language")}
        >
          {allAvailableLanguages.map((language: string, index: number) => (
            <StyledMenuItem
              key={language + index}
              value={language}
              className={`${displayRedDot(language) ? "AvailableDraftLanguage" : ""} ${
                displayOnlyDraftGrey(language) ? "OnlyDraftLanguage" : ""
              }`}
            >
              {languageDisplayName(language, isMantraUser)}
            </StyledMenuItem>
          ))}
        </Select>
        {displayRedDot(currentLanguage) && (
          <FormHelperText sx={{ color: "error.main" }}>
            {t("This language has unsaved changes.")}
          </FormHelperText>
        )}
        {isProvidedByMantra && !isMantraUser && (
          <FormHelperText>
            {t(
              "This is a Mantra course. Clone it if you want to edit content.",
            )}
          </FormHelperText>
        )}
      </FormControl>
      {(!isProvidedByMantra || isMantraUser) && <NewLanguageSelector />}
    </Box>
  );
};

export default LanguageDropdown;
