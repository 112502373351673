import { generateBrowserPath } from "../common";
import {
  detectAdminScriptContents,
  detectUserScriptContents,
} from "../content/remediation";

export function generateAdminRemediationDetectScript(
  browser: string,
  extensionID: string,
  extensionIDFirefox: string = "browser-defender@mantra.ms",
): string {
  const browserSpecificPath = generateBrowserPath(
    browser,
    extensionID,
    extensionIDFirefox,
  );
  return detectAdminScriptContents(browserSpecificPath);
}

export function generateUserRemediationDetectScript(
  browser: string,
  extensionID: string,
  extensionIDFirefox: string = "browser-defender@mantra.ms",
): string {
  const browserSpecificPath = generateBrowserPath(
    browser,
    extensionID,
    extensionIDFirefox,
  );
  return detectUserScriptContents(browserSpecificPath);
}
