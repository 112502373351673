import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import css from "@/pages/general-settings/GSuite/ServiceAccount.module.css";

const getClassNameFromStatus = (status) => {
  if (status === "available") return css.green;
  if (status === "not_available") return css.red;
  return css.gray;
};

export function ServiceAccount() {
  const { getAccessTokenSilently } = useAuth0();
  const [status, setStatus] = useState("unknown");
  const { t } = useTranslation();

  useEffect(() => {
    let mounted = true;
    (async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        const response = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/users/sync/gsuite/key_status",
          { headers: { Authorization: `Bearer ${accessToken}` } },
        );
        if (mounted) {
          setStatus(
            response.data.exists === true ? "available" : "not_available",
          );
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [status, getAccessTokenSilently]);

  const getTranslatedStatus = () => {
    switch (status) {
      case "available":
        return t("Available");
      default:
        return t("Not available - action required from Mantra staff");
    }
  };

  return (
    <Box>
      <span className={getClassNameFromStatus(status)}>
        {getTranslatedStatus()}
      </span>
    </Box>
  );
}
