import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  APIBannerUser,
  BannerToggleUsers,
} from "@/pages/banners/remote-apis/bannerUsers";
import { EngageSwitch } from "@/pages/banners/users/EngageSwitch";

type UsersBannersListTableProps = {
  users: Array<APIBannerUser>;
  handleEngageOrDisengageUser: (params: BannerToggleUsers) => void;
  deactivateAllUserActions: boolean;
};

export function UsersBannersListTable({
  users,
  handleEngageOrDisengageUser,
  deactivateAllUserActions,
}: UsersBannersListTableProps) {
  const { t } = useTranslation();
  const baseOptions = { filter: false, sort: true, searchable: true };

  const columns = [
    { name: "firstname", label: t("First Name"), options: baseOptions },
    { name: "lastname", label: t("Last Name"), options: baseOptions },
    { name: "email", label: t("Email"), options: baseOptions },
    { name: "language", label: t("Language"), options: baseOptions },
    {
      name: "departments",
      label: t("Departments"),
      options: {
        ...baseOptions,
        customBodyRender: (value) => value.join(", "),
        sortCompare: (order) => (v1, v2) => {
          const o = order === "desc" ? 1 : -1;
          let res = 0;

          if (v1.data.length === 0) {
            res -= 1;
          }
          if (v2.data.length === 0) {
            res += 1;
          }

          if (v1.data.length !== 0 && v2.data.length !== 0) {
            res = v1.data[0].localeCompare(v2.data[0]);
          }

          return res * o;
        },
      },
    },
    {
      name: "banners_engagement_status",
      label: t("Engaged"),
      options: {
        ...baseOptions,
        empty: true,
        sortCompare: (order) => (v1, v2) => {
          /* We filter by a precise order, allowing state_started to be displayed first, before finished states.
                              This allows the administrator to see pending actions first. */
          const state_order = {
            disengage_started: 0,
            disengaged: 1,
            engaged: 2,
            engage_started: 3,
            disabled: 4,
          };
          const o = order === "desc" ? 1 : -1;
          const value_v1 = state_order[v1.data];
          const value_v2 = state_order[v2.data];

          return (value_v1 - value_v2) * o;
        },
        customBodyRenderLite: (dataIndex) => (
          <EngageSwitch
            status={users[dataIndex].banners_engagement_status}
            email={users[dataIndex].email}
            disable_reason={users[dataIndex].disable_reason}
            handleEngageOrDisengageUser={handleEngageOrDisengageUser}
            disabled={deactivateAllUserActions}
          />
        ),
      },
    },
  ];
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const options = {
    rowsPerPage,
    onChangeRowsPerPage: setRowsPerPage,
    print: false,
    rowsPerPageOptions: [10, 50, 100, 500],
    selectableRows: "none" as const,
    textLabels: {
      toolbar: {
        search: t("Search"),
        downloadCsv: t("Download CSV"),
        viewColumns: t("View Columns"),
        filterTable: t("Filter Table"),
      },
      pagination: {
        next: t("Next Page"),
        previous: t("Previous Page"),
        rowsPerPage: t("Rows per page") + ":",
        displayRows: "of",
      },
    },
  };

  return (
    <MUIDataTable title="" data={users} columns={columns} options={options} />
  );
}
