import { z } from "zod";

export const UserPreferencesSchema = z.object({
  simulation: z.object({
    dashboard: z.object({
      timeFrame: z.string().default("P6M"),
      activeUsersOnly: z.boolean().default(true),
      department: z.string().default(""),
      selectedPeriod: z.object({
        monthOrWeek: z.union([z.literal("month"), z.literal("week")]),
        date: z.string().optional(),
      }),
      dataPerUser: z.object({
        search: z.string().default(""),
        rowsPerPage: z.number().default(10),
        sort: z.string().default("lastname"),
      }),
    }),
  }),
  banners_dashboard_period: z.string().default("P7D"),
});

export type UserPreferences = z.infer<typeof UserPreferencesSchema>;

export const DefaultUserPreferences: UserPreferences =
  UserPreferencesSchema.parse({
    simulation: {
      dashboard: {
        timeFrame: "P6M",
        activeUsersOnly: true,
        department: "",
        hasDate: false,
        selectedPeriod: {
          monthOrWeek: "month",
          date: undefined,
        },
        dataPerUser: {
          search: "",
          rowsPerPage: 10,
          sort: "lastname",
        },
      },
    },
  });
