import MUIDataTable from "mui-datatables";
import React from "react";
import { useTranslation } from "react-i18next";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Tooltip, Typography } from "@mui/material";

type SimulationResultType =
  | "sent"
  | "not_opened"
  | "opened"
  | "clicked"
  | string;

const SimulationResultStatus = ({
  status,
}: {
  status: SimulationResultType;
}) => {
  const { t } = useTranslation();

  const props = {
    sent: {
      bgColor: "rgba(69, 143, 247, 0.2)",
      textColor: "#124896",
      label: t("sent"),
    },
    not_opened: {
      bgColor: "rgba(69, 143, 247, 0.2)",
      textColor: "#124896",
      label: t("not opened"),
    },
    opened: {
      bgColor: "rgba(71, 0, 247, 0.2)",
      textColor: "#083297",
      label: t("opened"),
    },
    clicked: {
      bgColor: "rgba(255, 49, 111, 0.2)",
      textColor: "#B50A3E",
      label: t("clicked"),
    },
    default: {
      bgColor: "rgba(0, 194, 174, 0.2)",
      textColor: "#06584F",
      label: null,
    },
  };

  const prop = Object.keys(props).includes(status)
    ? props[status]
    : props["default"];

  const label = prop.label ?? status;

  return (
    <Box
      sx={{
        color: prop.textColor,
        bgcolor: prop.bgColor,
        fontWeight: "600",
        p: "0.5rem",
        fontSize: "0.7rem",
        display: "inline-flex",
        textTransform: "uppercase",
      }}
    >
      {label}
    </Box>
  );
};

export function EmailTableComponent(props) {
  const { t } = useTranslation();

  const SCENARIO_ACTIONS = {
    simple_text_email: "Simple text email",
    login_pages: "🔑 Credentials",
    software_update: "⬇️ Downloads",
    permission_grant: "✅ Permissions",
    attachment: "📎 Attachments",
    click: "👉 Click on link",
  };

  let columns = [
    {
      name: "template_scenario",
      label: t("Compromising action"),
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let row_data = props.data[dataIndex];
          return SCENARIO_ACTIONS[row_data.template_scenario] ?? "";
        },
      },
    },
    {
      name: "sender",
      label: t("Sender"),
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          let row_data = props.data[dataIndex];
          return row_data.from_name + " <" + row_data.from_email + ">";
        },
      },
    },
    {
      name: "subject",
      label: t("Subject.default"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "send_date",
      label: t("Last sending date"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { style: { textAlign: "center" } };
        },
        setCellHeaderProps: () => {
          return { style: { textAlign: "center" } };
        },
        customBodyRenderLite: (dataIndex) => {
          let sentDate = props.data[dataIndex].sent_date;

          if (props.data[dataIndex].send_date === "no_date") {
            return (
              <Tooltip
                title={t(
                  "This is not a real simulation. Send date is irrelevant",
                )}
              >
                <Typography
                  align="center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  {t("No date")}
                  <HelpOutlineIcon
                    sx={{
                      fontSize: "1.2rem",
                      opacity: "50%",
                      marginLeft: "3px",
                    }}
                  />
                </Typography>
              </Tooltip>
            );
          }

          return (
            <Typography align="center" style={{ width: "100%" }}>
              {new Date(sentDate).toLocaleString()}
            </Typography>
          );
        },
        customHeadLabelRender: (columnMeta) => {
          if (!props.customHeaders) {
            return columnMeta.label;
          }

          if (columnMeta.name in props.customHeaders) {
            return props.customHeaders[columnMeta.name];
          }

          return columnMeta.label;
        },
      },
    },
    {
      name: "status",
      label: t("Status"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return { style: { textAlign: "center" } };
        },
        setCellHeaderProps: () => {
          return { style: { textAlign: "center" } };
        },
        customBodyRenderLite: (dataIndex) => {
          let status = props.data[dataIndex].status;

          if (!status) {
            return "-";
          }
          return <SimulationResultStatus status={status} />;
        },
      },
    },
  ];

  if (props.additionalColumns) {
    columns.push.apply(columns, props.additionalColumns);
  }

  return (
    <MUIDataTable
      data={props.data}
      columns={columns}
      options={props.tableDisplayOptions}
      title={t("Example Simulations")}
    />
  );
}
