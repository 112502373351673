import React from "react";

import { Chip, ChipProps, Theme, alpha, useThemeProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export type EnumChipDef = {
  color: string;
  label: string;
};

function decodeColorFromTheme(theme: Theme, askedColor: string): string {
  const color = theme.unstable_sx({ color: askedColor });
  if (typeof color.color !== "string") {
    // @ts-ignore
    return color.color.main;
  }
  return color.color;
}

export const ColoredChip = styled(Chip)<{ chipColor: string }>(({
  theme,
  chipColor,
}) => {
  const color = decodeColorFromTheme(theme, chipColor);

  return {
    backgroundColor: alpha(color, 0.1),
    borderColor: color,
    color: color,
  };
});

interface EnumChipProps extends ChipProps {
  value: string;
  defs: { [k: string]: EnumChipDef };
}

export const EnumChip = React.forwardRef<HTMLDivElement, EnumChipProps>(
  (inProps, ref) => {
    const props = useThemeProps({ props: inProps, name: "MuiChip" });
    const { value, defs, ...others } = props;
    const format = defs[value];

    return (
      <ColoredChip
        ref={ref}
        variant="outlined"
        label={format.label}
        chipColor={format.color}
        {...others}
      />
    );
  },
);
