import * as React from "react";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface BasicSelectProps {
  items: string[];
  tag?: string | null;
  selectedItem: string | string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
}

const BasicSelect = ({
  items,
  tag,
  selectedItem = "",
  handleChange,
}: BasicSelectProps): JSX.Element => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size="small">
        <InputLabel>{tag}</InputLabel>
        <Select
          multiple={false}
          value={selectedItem}
          label="Age"
          onChange={handleChange}
        >
          {items.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BasicSelect;
