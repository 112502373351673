import React from "react";
import { useSearchParams } from "react-router-dom";

import { Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import { useFetchCustomCampaignQuery } from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignApi";
import UsersTable from "@/pages/phishing-simulation/dashboard/UsersTable";

export default function PanelCustomCampaignDetails() {
  const [queryParams] = useSearchParams();
  const id = queryParams.get("id");
  const { data, isLoading } = useFetchCustomCampaignQuery(id);
  return (
    <>
      <StickyHeader>
        {!isLoading && <Typography variant="h2">{data.name}</Typography>}
      </StickyHeader>
      <UsersTable campaignId={parseInt(id)} />
    </>
  );
}
