import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import InfoTooltip from "@/components/InfoTooltip";
import { T } from "@/components/LocalizedString";
import { ButtonSpinner } from "@/components/Spinner";
import ListItemsFormField from "@/components/forms/ListItemsFormField";
import { useUserContext } from "@/utils/contexts/UserContext";

const ROUTE = `${
  import.meta.env.VITE_APP_ENDPOINT_SIMULATION
}/groups/sync/provisioned`;

const ROUTE_GROUP_EXIST = `${
  import.meta.env.VITE_APP_ENDPOINT_SIMULATION
}/provisioning/external_groups`;

const PROVISION_TOOLTIP_CONTENT = T(
  "You can enter group names one by one here. You may also paste a list of group names separated by a comma or line breaks. They will all be added to the list.",
);
export type ProvisionedGroup = {
  name: string;
  id: number;
};

const useProvisionedGroup = () => {
  const [groups, setGroups] = useState<ProvisionedGroup[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [isErrorGroups, setIsErrorGroups] = useState(false);
  const [isAddingGroup, setIsAddingGroup] = useState(false);
  const { t } = useTranslation();
  const validate = (name: string) =>
    groups.find((group) => group.name === name) == null && name.length > 0;

  useEffect(() => {
    setIsLoadingGroups(true);
    axios
      .get(ROUTE)
      .then((response) => {
        setGroups(response.data.provisioned);
      })
      .catch(() => {
        setIsErrorGroups(true);
      })
      .finally(() => {
        setIsLoadingGroups(false);
      });
  }, []);

  const deleteGroup = async (key: number) => {
    const indexGroupToDelete = groups.findIndex((group) => group.id === key);
    const groupToDelete = { ...groups.find((group) => group.id === key) };
    setGroups((groups) => {
      return groups.filter((group) => group.id !== key);
    });
    try {
      await axios.delete(`${ROUTE}/${key}`);
    } catch (e) {
      setGroups((groups) => {
        const groupsCopy = [...groups];
        groupsCopy.splice(indexGroupToDelete, 0, groupToDelete);
        return groupsCopy;
      });
      throw e;
    }
  };

  const checkExistingGroups = async (groupNames: string[]) => {
    const response = await axios.get(`${ROUTE_GROUP_EXIST}`, {
      params: { group_names: groupNames.join(",") },
    });
    return response.data;
  };

  const addGroupToServer = async (groupName: string) => {
    const response = await axios.post(ROUTE, { name: groupName });
    return {
      id: response.data.id,
      name: groupName,
    };
  };

  const updateGroupsState = (newGroups: ProvisionedGroup[]) => {
    setGroups((groups) => [...groups, ...newGroups]);
  };

  const addGroup = async (groupName: string): Promise<void> => {
    setIsAddingGroup(true);
    try {
      await checkExistingGroups([groupName]);

      const newGroup = await addGroupToServer(groupName);
      updateGroupsState([newGroup]);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        throw new Error(
          t(
            "Error while trying to add provisioned group, group {{groupName}} does not exist. No group added.",
            { groupName: groupName },
          ),
        );
      } else {
        const errorMsg =
          error.message ||
          t("Error while trying to add provisioned group, you may try again");
        throw new Error(errorMsg);
      }
    } finally {
      setIsAddingGroup(false);
    }
  };

  const bulkAddGroups = async (groupNames: string[]): Promise<void> => {
    setIsAddingGroup(true);
    try {
      const uniqueNewGroups = getUniqueNewGroups(groupNames, groups);

      if (uniqueNewGroups.length === 0) {
        throw new Error(t("no new group entered"));
      }

      const { existing, non_existing } =
        await checkExistingGroups(uniqueNewGroups);

      const newGroups = await Promise.all(existing.map(addGroupToServer));
      updateGroupsState(newGroups);

      if (non_existing.length > 0) {
        throw new Error(
          t(
            "The following groups do not exist and were not added: {{groups}}",
            {
              groups: non_existing.join(", "),
            },
          ),
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        throw new Error(
          t("None of the entered groups exist. No groups were added."),
        );
      } else {
        throw error;
      }
    } finally {
      setIsAddingGroup(false);
    }
  };

  const getUniqueNewGroups = (
    newGroups: string[],
    existingGroups: ProvisionedGroup[],
  ): string[] => {
    const groupsHashset = new Set(newGroups);
    const groupNamesHashset = new Set(
      existingGroups.map((group) => group.name),
    );
    return Array.from(
      new Set([...groupsHashset].filter((x) => !groupNamesHashset.has(x))),
    );
  };

  const modifyGroup = async (groupName: string, key: string) => {
    try {
      await checkExistingGroups([groupName]);

      await axios.patch(`${ROUTE}/${key}`, { name: groupName });
      setGroups((groups) => {
        const groupsCopy = [...groups];
        const group = groupsCopy.find((group) => Number(key) === group.id);
        group.name = groupName;
        return groupsCopy;
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        throw new Error(t("The group does not exist"));
      } else {
        throw new Error(
          t("Unable to modify the group name. Please try again."),
        );
      }
    }
  };

  return {
    groups,
    addGroup,
    bulkAddGroups,
    deleteGroup,
    isLoadingGroups,
    isAddingGroup,
    modifyGroup,
    isErrorGroups,
    setGroups,
    validate,
  };
};

export function InnerGroupProvision() {
  const { t } = useTranslation();

  const {
    groups,
    addGroup,
    deleteGroup,
    isLoadingGroups,
    isErrorGroups,
    validate,
    bulkAddGroups,
    modifyGroup,
  } = useProvisionedGroup();

  const items = useMemo(() => {
    return groups.map((user) => ({
      value: user.name,
      key: user.id.toString(),
    }));
  }, [groups]);

  if (isLoadingGroups) {
    return <ButtonSpinner />;
  }

  if (isErrorGroups) {
    return (
      <Typography color="error">
        {t("Error: unable to fetch provisioned groups")}
      </Typography>
    );
  }

  return (
    <ListItemsFormField
      newItemPlaceholder={t("group1")}
      items={items}
      addItem={addGroup}
      bulkAddItems={bulkAddGroups}
      deleteItem={deleteGroup}
      setItem={modifyGroup}
      validate={validate}
      itemErrorMessage={t(
        "Error while trying to add provisioned group, you may try again",
      )}
      itemSuccessMessage={t("Successfully uploaded")}
      editErrorMessage={t(
        "Unable to modify the group name, verify that no provisioned group have the same name",
      )}
      bulkAddErrorMessage={t(
        "Unable to add emails, verify that those are valid provisioned group names",
      )}
      bulkSomeDuplicatedEntriesMessage={(validEntries: number) => {
        t("{{validEntries}} provisioned groups were added", {
          validEntries: validEntries,
        });
      }}
      tooltipPlaceholder={<InfoTooltip content={PROVISION_TOOLTIP_CONTENT} />}
    />
  );
}

export const GroupProvision = () => {
  const { current_company } = useUserContext();
  const { t } = useTranslation();

  return (
    <Box>
      {t(
        "Add one group name per line below to specify which groups and their members should be imported from your {{provider}}. Only users who belong to the listed groups will be imported. Leave empty to import all groups and their members.",
        {
          provider:
            current_company.provider === "GOOGLE"
              ? "Google Workspace"
              : "Azure AD",
        },
      )}
      <br />
      <InnerGroupProvision />
    </Box>
  );
};
