import { appDispatch, appSelector } from "@/store";
import { DefaultUserPreferences } from "@/utils/userPreferences";
import {
  selectBannersDashboardPeriod,
  selectSimulationPreferences,
  setBannersDashboardPeriod,
  setSimulationDashboard,
} from "@/utils/userPreferencesSlice";

export const useUserPreferences = () => {
  const dispatch = appDispatch();
  return {
    simulationPreferences: appSelector(selectSimulationPreferences),
    setSimulationDashboard: (
      o: typeof DefaultUserPreferences.simulation.dashboard,
    ) => dispatch(setSimulationDashboard(o)),
    bannersDashboardPeriod: appSelector(selectBannersDashboardPeriod),
    setBannersDashboardPeriod: (new_period: string) =>
      dispatch(setBannersDashboardPeriod(new_period)),
  };
};
