import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { ButtonSpinner } from "../Spinner";

export const ConfirmationDialog = ({
  isShown,
  onConfirm,
  onConfirmText,
  onCancel,
  onCancelText,
  title,
  content = "",
  isLoading = false,
  disabled = false,
  onClose = () => false,
  children = undefined,
}) => (
  <Dialog
    open={isShown}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle
      variant={"h3"}
      sx={{ fontSize: "24px" }}
      id="alert-dialog-title"
    >
      {title}
    </DialogTitle>
    <Divider />
    <DialogContent>{children ?? content}</DialogContent>
    <Divider />
    <DialogActions>
      {isLoading ? <ButtonSpinner sx={{ marginRight: "1rem" }} /> : ""}
      <Button
        onClick={onCancel}
        variant="outlined"
        autoFocus
        disabled={disabled}
      >
        {onCancelText}
      </Button>
      <Button onClick={onConfirm} variant="contained" disabled={disabled}>
        {onConfirmText}
      </Button>
    </DialogActions>
  </Dialog>
);
