import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import SplashScreen from "@/components/SplashScreen";
import { useMarkAsSafe } from "@/pages/landing-pages/api";
import css from "@/pages/landing-pages/components/LandingPage.module.css";
import LandingPageLayout, {
  SupportedLanguage,
} from "@/pages/landing-pages/components/LandingPageLayout";
import {
  browserLanguage,
  useOnlyOnceEffect,
} from "@/pages/landing-pages/utils";

type MarkAsSafePageProps = {
  lang: SupportedLanguage;
  isLoading: boolean;
  isError: boolean;
  error: any;
  senderAddress: string;
};

export const MarkAsSafePage = ({
  lang,
  isError,
  isLoading,
  error,
  senderAddress,
}: MarkAsSafePageProps) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(lang);
    })();
  }, [i18n, lang]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <LandingPageLayout>
      {isError ? (
        <>
          {error?.response?.status === 403 ? (
            <div>{t("landing.MarkAsSafe.ErrorNotAllowed")}</div>
          ) : (
            <div>{t("landing.UnknownError")}</div>
          )}
          <div>{t("landing.MarkAsSafe.notMarked")}</div>
        </>
      ) : (
        <>
          <p className={css.sender_email}>{senderAddress}</p>
          <p className={css.medium_message}>{t("landing.MarkAsSafe.marked")}</p>
          <p className={css.small_message}>
            {t("landing.MarkAsSafe.dontDisplayAgain")}
          </p>
        </>
      )}
    </LandingPageLayout>
  );
};

export function MarkAsSafe() {
  const [searchParams] = useSearchParams();
  const { markAsSafe, isLoading, isError, error } = useMarkAsSafe();

  const browser_language = browserLanguage();

  const senderAddress = searchParams.get("sender_address");

  useOnlyOnceEffect(() => {
    markAsSafe({
      sender_address: senderAddress,
      recipient_address: searchParams.get("recipient_address"),
    });
  });

  return (
    <MarkAsSafePage
      lang={browser_language}
      isLoading={isLoading}
      isError={isError}
      error={error}
      senderAddress={senderAddress}
    />
  );
}
