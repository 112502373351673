import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import { DeleteConfirmationDialog } from "@/components/modals/DeleteConfirmationDialog";

export const ResetStats = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const title = t("Erase Learning data");
  const dialogContent = t(
    "Clicking on “Confirm” will erase all data related to Learning. You will lose all data related to the courses. Once clicked, you won’t be able to download previous learning raw data. This action is non-reversible.",
  );

  async function resetAllStatsForCompany(): Promise<void> {
    try {
      setButtonLoading(true);
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/company/certifications/reset`,
        {},
      );
      snackbar.showMessage(t("Data erased."));
      setOpenDialog(false);
    } catch (e) {
      snackbar.showMessage(t("An error occurred"));
    }
    setButtonLoading(false);
  }

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex">
            <Typography variant="h6">{title}</Typography>
          </Box>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <Typography>
            {t(
              "Clicking on this button will erase all data related to Learning. This action is non-reversible.",
            )}
          </Typography>
          <Button
            variant="contained"
            color={"error"}
            sx={{ marginTop: "1rem" }}
            onClick={() => setOpenDialog(true)}
            disabled={buttonLoading}
          >
            {t("Erase all Learning data")}
          </Button>
        </Box>
      </CardContent>
      <DeleteConfirmationDialog
        isShown={openDialog}
        onConfirm={resetAllStatsForCompany}
        onConfirmText={t("Confirm")}
        onCancel={() => setOpenDialog(false)}
        onCancelText={t("Cancel")}
        title={title}
        content={dialogContent}
      />
    </Card>
  );
};
