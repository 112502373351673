import { keyframes } from "@emotion/react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const animation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const CycleAppearingAnimation = styled(Box)({
  animation: `${animation} 3s infinite ease`,
});

export default CycleAppearingAnimation;
