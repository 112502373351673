import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export default function CustomCampaignList({ listCampaigns }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  function handleButtonClick(params) {
    let pathname = location.pathname + "/details";

    if (params.row.state === "draft") {
      pathname = location.pathname + "/create";
    }

    navigate({
      pathname: pathname,
      search: "?id=" + params.id,
    });
  }

  const columns: GridColDef[] = [
    {
      field: "campaign_name",
      headerName: t("Campaign Name"),
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.value}</Typography>
            {params.row.state === "draft" && (
              <Typography
                variant={"body_small"}
                color="text.secondary"
                sx={{ paddingLeft: "0.25rem" }}
              >
                {`- ${t("Draft")}`}
              </Typography>
            )}
          </>
        );
      },
    },
    { field: "start_date", headerName: t("Start date"), flex: 0.2 },
    { field: "end_date", headerName: t("End date"), flex: 0.2 },
    {
      field: "simulations_sent_count",
      headerName: t("Sent"),
      flex: 0.2,
      renderCell: (params) => {
        if (params.row.state === "draft") {
          return "-";
        }
        return params.value + " / " + params.row.total_scheduled_simulations;
      },
    },

    {
      field: "simulations_opened_rate",
      headerName: t("Open Rate"),
      flex: 0.2,
    },
    {
      field: "simulations_clicked_rate",
      headerName: t("Click Rate"),
      flex: 0.2,
    },
    {
      field: "actions",
      width: 20,
      renderHeader: () => {
        return "";
      },
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleButtonClick(params)}>
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={listCampaigns}
      sx={{
        height: "100%",
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
        },
      }}
      columns={columns}
      disableColumnMenu
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "start_date",
              sort: "desc",
            },
          ],
        },
        pagination: {
          paginationModel: {
            pageSize: 20,
          },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
    />
  );
}
