import {
  getActiveUserProfileFromEntra,
  getActiveUserProfileFromLocal,
  processUserSpecificPath,
  refreshHKCUHive,
  runSetup,
} from "./functions";

export const adminPlatformScriptContents = (
  enterpriseToken: string,
  browserSpecificPath: string,
) => {
  return `
    ${getActiveUserProfileFromEntra}

    ${getActiveUserProfileFromLocal}

    ${refreshHKCUHive}

    ${processUserSpecificPath}
    
    ${runSetup(enterpriseToken, browserSpecificPath)}
`;
};

export const userPlatformScriptContents = (
  enterpriseToken: string,
  browserSpecificPath: string,
) => {
  return `
    $sid = (New-Object System.Security.Principal.NTAccount($env:USERNAME)).Translate([System.Security.Principal.SecurityIdentifier]).Value
    
    # Create a new PSDrive for the user's hive
    New-PSDrive -Name HKU -PSProvider Registry -Root HKEY_USERS | Out-Null

    # Ensure the registry path exists
    $regPath = "HKU:\\$sid\\Software\\Policies\\${browserSpecificPath}"
    if (!(Test-Path $regPath)) {
      New-Item -Path $regPath -Force
    }

    # Write the EnterpriseToken to the registry
    New-ItemProperty -Path $regPath -Name "EnterpriseToken" -Value ${enterpriseToken} -Force
    
    # Get the user email or UPN via whoami
    # If this does not work for you, please modify the script so that the $userDomainInfo variable contains the email address of the current user
    $userDomainInfo = whoami /upn
        
    # Write the UPN to the registry
    New-ItemProperty -Path $regPath -Name "EnterpriseUserId" -Value $userDomainInfo -Force

    # Remove the PSDrive when you're done
    Remove-PSDrive -Name HKU -ErrorAction SilentlyContinue
  `;
};
