import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { merge } from "lodash";

import { listenerMiddleware } from "@/listenerMiddleware";
import { RootState } from "@/store";
import { DefaultUserPreferences } from "@/utils/userPreferences";

export const userPreferences = createSlice({
  name: "user preferences",
  initialState: DefaultUserPreferences,
  reducers: {
    setSimulationDashboard: (
      state,
      action: PayloadAction<
        Partial<typeof DefaultUserPreferences.simulation.dashboard>
      >,
    ) => {
      state.simulation.dashboard = merge(
        state.simulation.dashboard,
        action.payload,
      );
    },
    setBannersDashboardPeriod: (state, action: PayloadAction<string>) => {
      state.banners_dashboard_period = action.payload;
    },
  },
});

export const selectSimulationPreferences = (state: RootState) => {
  return state.userPreference.simulation;
};
export const selectBannersDashboardPeriod = (state: RootState): string => {
  return state.userPreference.banners_dashboard_period;
};
export const { setSimulationDashboard, setBannersDashboardPeriod } =
  userPreferences.actions;

listenerMiddleware.startListening({
  matcher: isAnyOf(...Object.values(userPreferences.actions)),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    const preferences = state.userPreference;
    localStorage.setItem("user-preferences", JSON.stringify(preferences));
  },
});
