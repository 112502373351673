import styled from "@emotion/styled";
import React, { ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Button, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

import { ChecklistPane } from "@/components/stepper/ChecklistPane";
import { useSendEmail } from "@/pages/banners/remote-apis/bannerTemplates";
import { useEngagedUsersQuery } from "@/pages/banners/remote-apis/bannerUsersApi";
import {
  useAcknowledgeTestEmailsMutation,
  useStatusQuery,
  useTemplateNamesQuery,
} from "@/pages/banners/setup/BannerSetupChecklist";

const StyledAutocomplete = styled(Autocomplete)`
  & .MuiChip-root {
    width: initial;
  }
`;

function Tags({ options, label, onChange, selected }) {
  return (
    <StyledAutocomplete
      multiple
      limitTags={5}
      options={options}
      getOptionLabel={(option: string) => option}
      filterSelectedOptions
      disableCloseOnSelect
      onChange={onChange}
      value={selected}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}

type SampleEmailsProps = { title: ReactNode };

export const SampleEmails: React.FC<SampleEmailsProps> = ({
  title,
}: SampleEmailsProps) => {
  const { t } = useTranslation();
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [acknowledgeTestEmails] = useAcknowledgeTestEmailsMutation();

  const {
    data: users,
    isLoading: usersPending,
    isError: isUsersError,
  } = useEngagedUsersQuery();

  const {
    data: templates,
    error: templatesError,
    isError: isTemplateError,
    isLoading: templatesPending,
  } = useTemplateNamesQuery();

  const { isSending, sendEmails } = useSendEmail();

  const { data, isLoading, isError } = useStatusQuery();

  const buttonActivated =
    !isSending &&
    !isUsersError &&
    !isTemplateError &&
    selectedUsers.length !== 0 &&
    selectedTemplates.length !== 0;

  const handleSend = async (e) => {
    e.preventDefault();
    await sendEmails({
      templateNames: selectedTemplates,
      userEmails: selectedUsers,
    });
    await acknowledgeTestEmails(true);
  };

  const ErrorMessage = (error) => {
    return (
      <div>
        {error.error?.data?.error === 'The field "ceo" is required.' ? (
          <span style={{ color: "red" }}>
            <Trans i18nKey="ErrorMessageSampleEmailsBanners">
              To use this feature, the
              <Link to="/phishing-simulation/company">Key People</Link> must be
              filled out.
            </Trans>
          </span>
        ) : (
          <span>{t("Unable to load the scenario list...")}</span>
        )}
      </div>
    );
  };

  if (isError) return <>{t("Error")}</>;

  if (isLoading) return <>{t("Loading")}</>;

  return (
    <ChecklistPane title={title}>
      <form onSubmit={handleSend}>
        <div>
          {t(
            "See how banners look like in your inbox by sending fake phishing emails triggering them.",
          )}
        </div>
        <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
          {templatesPending ? (
            <span>{t("Loading scenario list...")}</span>
          ) : templatesError ? (
            <ErrorMessage error={templatesError} />
          ) : (
            <Tags
              options={templates}
              label={"Phishing scenario"}
              selected={selectedTemplates}
              onChange={(_, newValue) => setSelectedTemplates(newValue)}
            />
          )}
        </div>
        <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
          {usersPending ? (
            <span>Loading user list...</span>
          ) : isUsersError ? (
            <span>Unable to load the user list...</span>
          ) : (
            <Tags
              options={users.map((u) => u.email)}
              label={t("Target users")}
              selected={selectedUsers}
              onChange={(_, newValue) => setSelectedUsers(newValue)}
            />
          )}
        </div>
        <div>
          <Button variant="contained" type="submit" disabled={!buttonActivated}>
            {t("Send sample emails")}
          </Button>
        </div>
      </form>
      <Box>
        <Typography>
          {t("Test emails already sent")}:
          {data.sent_emails_done ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <CancelOutlinedIcon color="error" />
          )}
        </Typography>
      </Box>
    </ChecklistPane>
  );
};
