import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import SplashScreen from "@/components/SplashScreen";
import { useReportThreat } from "@/pages/landing-pages/api";
import css from "@/pages/landing-pages/components/LandingPage.module.css";
import LandingPageLayout, {
  SupportedLanguage,
} from "@/pages/landing-pages/components/LandingPageLayout";
import {
  browserLanguage,
  useOnlyOnceEffect,
} from "@/pages/landing-pages/utils";

type ReportAsThreatPageProps = {
  lang: SupportedLanguage;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

export const ReportAsThreatPage = ({
  lang,
  isError,
  isLoading,
  error,
}: ReportAsThreatPageProps) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      await i18n.changeLanguage(lang);
    })();
  }, [i18n, lang]);

  if (isLoading) {
    return <SplashScreen />;
  }
  return (
    <LandingPageLayout>
      {isError ? (
        error?.response?.status === 403 ? (
          <div>{t("landing.ReportThreat.ErrorNotAllowed")}</div>
        ) : (
          <div>{t("landing.UnknownError")}</div>
        )
      ) : (
        <Trans i18nKey="landing.ReportThreat.message">
          <p className={css.message}>
            The email has been forwarded to your IT department
          </p>
          <p className={css.sub_message}>
            We recommend you to wait for their analysis
          </p>
          <p className={css.sub_message}>
            before interacting with this email or its sender
          </p>
        </Trans>
      )}
    </LandingPageLayout>
  );
};

export function ReportAsThreat() {
  const [searchParams] = useSearchParams();
  const { reportThreat, isLoading, isError, error } = useReportThreat();
  const browser_language = browserLanguage();

  useOnlyOnceEffect(() => {
    reportThreat({
      report_id: searchParams.get("report_id"),
      report_data: searchParams.get("report_data"),
    });
  });

  return (
    <ReportAsThreatPage
      lang={browser_language}
      isLoading={isLoading}
      isError={isError}
      error={error}
    />
  );
}
