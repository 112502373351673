export const browserPaths: { [key: string]: string } = {
  brave: "BraveSoftware\\Brave",
  chrome: "Google\\Chrome",
  edge: "Microsoft\\Edge",
  firefox: "Mozilla\\Firefox",
  vivaldi: "Vivaldi",
};

export const generateBrowserPath = (
  browser: string,
  extensionID: string,
  extensionIDFirefox: string = "",
): string => {
  const browserSubPath = browserPaths[browser.toLowerCase()];

  if (browser.toLowerCase() === "firefox") {
    return `${browserSubPath}\\3rdparty\\Extensions\\${extensionIDFirefox}`;
  }

  return `${browserSubPath}\\3rdparty\\extensions\\${extensionID}\\policy`;
};
