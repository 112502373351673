import dayjs from "dayjs";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";

import TrashIconButton from "@/components/buttons/TrashIconButton";
import TableSort from "@/components/sort/TableSort";
import DeleteSafeItemsConfirmationDialog from "@/pages/banners/preferences/DeleteSafeItemsConfirmationDialog";
import ModalAddSafeList from "@/pages/banners/preferences/ModalAddSafeList";
import { TypeCell } from "@/pages/banners/preferences/TypeCell";
import CustomTable, {
  HeaderFilterField,
  HeadersExtra,
} from "@/pages/banners/preferences/components/CustomTable";
import {
  ContactType,
  SafeContact,
} from "@/pages/banners/remote-apis/contact_types";

const sortingColumns = [
  { label: "Item", name: "item" },
  { label: "Added By", name: "added_by" },
  { label: "Added to list", name: "added_at" },
];

const filterColumns = ["item", "added_by", "added_at"];

type DataSafesListTableProps = {
  data: SafeContact[];
  contactType: ContactType;
};

export default function DataSafesListTable({
  data,
  contactType,
}: DataSafesListTableProps) {
  const { t } = useTranslation();
  const headers = [
    { label: t("Item"), alignment: "left" as const },
    { label: t("Type"), alignment: "left" as const },
    { label: t("Added By"), alignment: "left" as const },
    { label: t("Added to list"), alignment: "left" as const },
    { label: t("Action"), alignment: "left" as const },
  ];
  const [sorting, setSorting] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmationOpenAddItems, setConfirmationOpenAddItems] =
    useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);

  const handleDelete = ({ row }) => {
    setItemToDelete(row);
    setDeleteConfirmationDialogOpen(true);
  };
  const handleAddItems = (event) => {
    setConfirmationOpenAddItems(true);
  };

  const AddItems = (props: PropsWithChildren) => {
    return (
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={handleAddItems}
      >
        {props.children}
      </Button>
    );
  };

  const filteredValues = useMemo(() => {
    let filteredValues = [...data];
    if (searchTerm) {
      filteredValues = data.filter(function (row) {
        return Object.keys(row).some(function (attribute) {
          let value = row[attribute];
          if (attribute === "added_at") {
            value = value.toLocaleDateString();
          } else {
            try {
              value = row[attribute].toLowerCase();
            } catch {
              value = row[attribute];
            }
          }
          if (!filterColumns.includes(attribute)) {
            return false;
          }

          if (!row[attribute]) {
            return false;
          }

          return value.indexOf(searchTerm) > -1;
        });
      });
    }

    if (sorting) {
      let condition = 1;
      filteredValues.sort((p1, p2) => {
        for (let sortRule of sorting) {
          if (p1[sortRule.column] < p2[sortRule.column]) {
            return -1 * sortRule.direction;
          } else {
            return 1 * sortRule.direction;
          }
        }
        return condition;
      });
    }

    return filteredValues;
  }, [data, searchTerm, sorting]);

  return (
    <>
      <CustomTable
        headers={headers}
        HeadersExtra={
          <HeadersExtra>
            <Box sx={{ gap: ".5rem", display: "flex" }}>
              <HeaderFilterField
                setPage={setPage}
                setSearchTerm={setSearchTerm}
              />
              <TableSort
                setOrderBy={setSorting}
                columns={sortingColumns}
                defaultOrder={[{ column: "item", direction: 1 }]}
              />
            </Box>
            <Box>
              <AddItems>
                {contactType === ContactType.email_address
                  ? t("Add safe senders")
                  : t("Add safe domains")}
              </AddItems>
            </Box>
          </HeadersExtra>
        }
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={filteredValues.map((row, index) => {
          return (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                width={"20%"}
                component="th"
                scope="row"
                align={headers[0].alignment}
              >
                {row.item}
              </TableCell>
              <TableCell align={headers[1].alignment}>
                <TypeCell value={row.type} />
              </TableCell>
              <TableCell align={headers[2].alignment}>{row.added_by}</TableCell>
              <TableCell align={headers[3].alignment}>
                {dayjs(row.added_at).format("YYYY-MM-DD")}
              </TableCell>
              <TableCell align={headers[4].alignment}>
                <TrashIconButton
                  onClick={() => {
                    handleDelete({ row });
                  }}
                />
              </TableCell>

              <TableCell>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                ></Grid>
              </TableCell>
            </TableRow>
          );
        })}
      />
      <ModalAddSafeList
        confirmationDialogOpen={confirmationOpenAddItems}
        setConfirmationDialogOpen={setConfirmationOpenAddItems}
        contactType={contactType}
      />
      <DeleteSafeItemsConfirmationDialog
        target={itemToDelete}
        confirmationDialogOpen={deleteConfirmationDialogOpen}
        setConfirmationDialogOpen={setDeleteConfirmationDialogOpen}
      />
    </>
  );
}
