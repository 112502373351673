import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";
import DOMPurify from "dompurify";
import { JwtPayload } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";

type BackendHook = {
  data: any;
  error: any;
  isPending: boolean;
  fetchData: () => void;
};

type ExtendedJwtPayload = JwtPayload & {
  permissions: string[];
  "https://mantra.ms/email": string;
};

export type LanguageType = {
  code: string;
  label: string;
};
/**
 * Extract an error message from an Axios error
 */
export function extractErrorMessage(error: AxiosError): string {
  if (error.response == null) {
    return error.message;
  }

  if (typeof error.response.data == "object") {
    return JSON.stringify(error.response.data);
  }

  if (error.response.headers["content-type"] === "text/html") {
    return `${error.response.statusText} (${error.response.status})`;
  }

  if (typeof error.response.data == "string") {
    return error.response.data;
  }
}

export function useBackend(
  endpoint: string,
  method = "GET",
  body = null,
): BackendHook {
  const [data, setData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchResult = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    const option = {
      method: method,
      url: endpoint,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: body,
    };

    try {
      setIsPending(true);
      const response = await axios(option);
      setData(response.data);
    } catch (error) {
      setError(error as Error);
      console.error(error);
    } finally {
      setIsPending(false);
    }
  }, [body, endpoint, getAccessTokenSilently, method]);

  useEffect(() => {
    fetchResult();
  }, [fetchResult]);

  return { data, error, isPending, fetchData: fetchResult };
}

/**
 * Validate an email address
 * @param email
 */
export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Validate a domain name
 * @param domain
 */
export function validateDomainName(domain: string): boolean {
  const re = /^[-\w]+(?:\.[-\w]+)*(?:\.[a-z]+)+$/i;
  return re.test(domain);
}

export const parseJwt = (token: string): ExtendedJwtPayload | null => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const getEmailJwt = (token: string): string | undefined => {
  const decodedToken = parseJwt(token);
  if (decodedToken) {
    return decodedToken["https://mantra.ms/email"];
  }
};

export const getDomain = (email: string): string | null => {
  try {
    return email.split("@")[1];
  } catch (e) {
    return null;
  }
};

// this should be the single source of truth for simulation languages in the frontend
// NOTE: to get display name, always use the function below to avoid displaying "formal" to regular users
export const languageList: Array<LanguageType> = [
  { code: "cz", label: "Czech" },
  { code: "no", label: "Norwegian" },
  { code: "pl", label: "Polish" },
  { code: "pt", label: "Portuguese" },
  { code: "en", label: "English" },
  { code: "fr", label: "French" },
  { code: "it", label: "Italian" },
  { code: "es", label: "Spanish" },
  { code: "de", label: "German" },
  { code: "fr_FR@formal", label: "Formal French" },
  { code: "nl", label: "Dutch" },
  { code: "ro", label: "Romanian" },
  { code: "zh_CN", label: "Simplified Chinese" },
];

export const languageListWithoutFormal: LanguageType[] = languageList.filter(
  (lang) => !lang.code.includes("formal"),
);

export const languageDisplayName = (
  languageCode: string,
  isAllowedToSeeFormal: boolean,
): string => {
  const language = languageList.find((lang) => lang.code === languageCode);

  if (!language) {
    return "Unknown language";
  }

  const displayName = language.label;

  return isAllowedToSeeFormal
    ? displayName
    : displayName.replace(/ formal$/, "");
};

export const sanitizeHTML = (htmlString: string): string => {
  return DOMPurify.sanitize(htmlString, {
    FORBID_TAGS: ["script"],
  });
};

export const isElementVisible = (
  el: HTMLElement,
  relatedTo: HTMLElement,
): boolean => {
  const elRect = el.getBoundingClientRect();
  const relatedToRect = relatedTo.getBoundingClientRect();
  return (
    elRect.top >= relatedToRect.top &&
    elRect.bottom <= relatedToRect.bottom &&
    elRect.left >= relatedToRect.left &&
    elRect.right <= relatedToRect.right
  );
};

export const setIFrameHeight = (el: HTMLIFrameElement): number => {
  if (el == null || el.contentDocument == null) {
    return;
  }
  const iFrameHeight = el.contentDocument.body.scrollHeight;
  el.style.height = iFrameHeight + 25 + "px";
  const html = el.contentDocument.body.parentNode as HTMLElement;
  if (html?.style != null) {
    html.style.overflow = "hidden";
  }
};
export function useLearningDepartments() {
  const [departments, setDepartments] = useState([]);
  const getDepartments = useCallback(async () => {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_ENDPOINT_LEARNING}/departments`,
    );
    setDepartments(response.data);
  }, []);
  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  return { departments };
}
export function useSimulationDepartments() {
  const [departments, setDepartments] = useState([]);
  const getDepartments = useCallback(async () => {
    const response = await axios.get(
      `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/departments`,
    );
    setDepartments(response.data);
  }, []);
  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  return { departments };
}
