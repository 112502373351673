import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import LineChart from "@/pages/phishing-simulation/dashboard/LineChart";
import { useBackend } from "@/utils/misc";

import useSimulationDashboard from "./hooks/useSimulationDashboard";

export default function Stats({ isCorporateView }) {
  const { t } = useTranslation();

  const {
    department,
    activeUsersOnly,
    timeFrame,
    monthOrWeek,
    setMonthOrWeek,
    setDate,
  } = useSimulationDashboard();

  const timeFrameAsMonths = dayjs.duration(timeFrame).asMonths();
  let errorMessage = "";

  const updateMonthOrWeek = (_, value: "month" | "week" | null) => {
    if (value == null) {
      return;
    }
    setMonthOrWeek(value);
    setDate(null);
  };

  let endpointUrl = import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/stats";
  if (isCorporateView) {
    endpointUrl = endpointUrl + "/corporation";
  }
  // Add filters
  let params = new URLSearchParams();
  // Department filter
  if (department) {
    params.append("department", department);
  }
  // Date filter
  params.append("timeframe", String(timeFrameAsMonths));
  params.append("active_only", String(activeUsersOnly));
  endpointUrl = endpointUrl + "?" + params.toString();

  const { data, error, isPending } = useBackend(endpointUrl);

  if (error) {
    errorMessage = isCorporateView
      ? t("Your company is not assigned to a corporate group")
      : t("An error occurred");
    return <h3 className="error">{errorMessage}</h3>;
  }

  let title = isCorporateView
    ? t("Average entities click rate (clicks / opened emails)")
    : t("Click rate (clicks / opened emails)");

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Stack direction="row" spacing={1}>
            <ToggleButtonGroup
              value={monthOrWeek}
              onChange={updateMonthOrWeek}
              exclusive
            >
              <ToggleButton value="week">{t("Weekly")}</ToggleButton>
              <ToggleButton value="month">{t("Monthly")}</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        }
      ></CardHeader>
      <CardContent sx={{ px: 3, pt: 1, pb: 0 }}>
        {isPending && <div>Pending...</div>}
        {data && !isPending && (
          <LineChart
            isMonthly={monthOrWeek === "month"}
            setSelectedPeriod={setDate}
            dataset={data}
            showBanners={data?.metadata?.show_banner_stats}
            isCorporateView={isCorporateView}
          />
        )}
      </CardContent>
    </Card>
  );
}
