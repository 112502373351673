import {
  Align,
  ArcElement,
  Chart,
  DoughnutController,
  Legend,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material";

import { UserLevelsResponse } from "@/apis/usersApi";

Chart.register(ArcElement, DoughnutController);
Chart.register(ArcElement, DoughnutController, Legend, Tooltip);

interface UserPerLevelChartProps {
  levels: UserLevelsResponse;
}

export default function UserPerLevelChart({ levels }: UserPerLevelChartProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const chartData = {
    labels: [
      t("Beginner (click rate >= 20%)"),
      t("Intermediate (click rate < 20%)"),
      t("Expert (click rate = 0%)"),
    ],
    datasets: [
      {
        fill: true,
        data: [
          Math.round((levels?.Beginner ?? 0) * 100),
          Math.round((levels?.Intermediate ?? 0) * 100),
          Math.round((levels?.Expert ?? 0) * 100),
        ],
        backgroundColor: [
          theme.palette.charts.tertiary.main,
          theme.palette.charts.primary.light,
          theme.palette.charts.secondary.main,
        ],
      },
    ],
  };

  const chartConfig = {
    rotation: -90,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "center" as Align,
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => {
          return value + "%";
        },
        font: {
          weight: 600,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            return context.formattedValue + "%";
          },
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 5,
        bottom: 5,
      },
    },
  };

  return (
    <div style={{ height: "250px", alignItems: "center" }}>
      <Doughnut
        data={chartData}
        options={chartConfig}
        width={540}
        height={250}
        plugins={[ChartDataLabels] as any}
      />
    </div>
  );
}
