import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";

import SplashScreen from "@/components/SplashScreen";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { useUserContext } from "@/utils/contexts/UserContext";

type AuthenticatedComponentProps = {
  children: React.ReactNode;
  permissions?: Permission[];
  supervisorHasAccess?: boolean;
};

export function AuthenticatedComponent({
  children,
  permissions,
  supervisorHasAccess,
}: AuthenticatedComponentProps) {
  const {
    is_authenticated: isUserReady,
    permissions: userPermission,
    is_supervisor,
  } = useUserContext();

  if (!isUserReady) {
    return <SplashScreen />;
  } else if (supervisorHasAccess == null || permissions == null) {
    return <>{children}</>;
  } else if (supervisorHasAccess && is_supervisor) {
    return <>{children}</>;
  } else if (permissions.some((x) => userPermission.includes(x))) {
    return <>{children}</>;
  } else {
    return <Navigate to="/my-progress" />;
  }
}

export default withAuthenticationRequired(AuthenticatedComponent, {
  onRedirecting: () => <SplashScreen />,
});
