import { debounce } from "lodash";
import { useMemo, useState } from "react";

import { OrderBy } from "@/components/sort/SelectOrderByComponent";
import { useUserPreferences } from "@/utils/useUserPreferencesService";

import { useDataPerUsersQuery, useLazyDownloadUsersListQuery } from "../api";
import useSimulationDashboard from "./useSimulationDashboard";
import useSimulationDataPerUserHeaders from "./useSimulationDataPerUserHeaders";

const serializeOrderBy = (listOrderBy: OrderBy[]): string => {
  const orderByFormatted: string[] = [];
  for (const orderItem of listOrderBy) {
    if (orderItem.label) {
      orderByFormatted.push(
        (orderItem.order === "asc" ? "" : "-") + orderItem.label,
      );
    }
  }
  if (orderByFormatted.length === 0) {
    orderByFormatted.push("lastname");
  }
  return orderByFormatted.join(",");
};

type SortChoice = {
  name: string;
  label: string;
};

const deserializeOrderBy = (
  sortParam: string,
  sortChoices: SortChoice[],
): OrderBy[] => {
  return sortParam.split(",").map((v: string, index: number) => {
    const order = v[0] === "-" ? "desc" : "asc";
    const cleanedValue = order === "desc" ? v.slice(1) : v;
    return {
      id: index,
      column: {
        name: cleanedValue,
        // sometimes, name is not found in sortChoices, label will be undefined in this case
        label: sortChoices.find((x) => x.name === cleanedValue)?.label,
      },
      label: cleanedValue,
      order: order,
    };
  });
};

export const useSimulationDataPerUser = (campaignId?: number) => {
  const { dateFrom, dateTo, department, activeUsersOnly } =
    useSimulationDashboard();
  const { simulationPreferences, setSimulationDashboard } =
    useUserPreferences();
  const { sortChoices } = useSimulationDataPerUserHeaders();
  const [pageNumber, setPageNumber] = useState(0);

  const [search, setSearch] = useState(
    simulationPreferences.dashboard.dataPerUser.search,
  );
  const sort = useMemo(() => {
    const sort = simulationPreferences.dashboard.dataPerUser.sort;
    return deserializeOrderBy(sort, sortChoices);
  }, [simulationPreferences.dashboard.dataPerUser.sort, sortChoices]);

  const triggerFetchDebounce = useMemo(
    () =>
      debounce((value: typeof simulationPreferences.dashboard) => {
        setSimulationDashboard(value);
      }, 1000),
    [setSimulationDashboard, simulationPreferences],
  );

  const changeSearch = (search: string) => {
    setSearch(search);
    triggerFetchDebounce({ dataPerUser: { search } });
  };

  const changeSort = (orderBy: OrderBy[]) => {
    triggerFetchDebounce({ dataPerUser: { sort: serializeOrderBy(orderBy) } });
  };

  const dataPerUserParams = {
    date_from: campaignId ? undefined : dateFrom.toISOString(),
    date_to: campaignId ? undefined : dateTo.toISOString(),
    department: department,
    sort: simulationPreferences.dashboard.dataPerUser.sort,
    page_number: pageNumber + 1,
    only_active: activeUsersOnly,
    per_page: simulationPreferences.dashboard.dataPerUser.rowsPerPage,
    search: simulationPreferences.dashboard.dataPerUser.search,
    campaign_id: campaignId,
  };

  const { data, isError, isLoading, isFetching } =
    useDataPerUsersQuery(dataPerUserParams);

  const [triggerUsersDownload] = useLazyDownloadUsersListQuery();

  const onClickDownload = () => triggerUsersDownload(dataPerUserParams);

  return {
    search,
    changeSearch,
    sort,
    changeSort,
    rowsPerPage: simulationPreferences.dashboard.dataPerUser.rowsPerPage,
    setRowsPerPage: (rowsPerPage: number) =>
      setSimulationDashboard({ dataPerUser: { rowsPerPage } }),
    data,
    pageNumber,
    setPageNumber,
    isError,
    isLoading: isLoading || isFetching,
    onClickDownload,
  };
};
export default useSimulationDataPerUser;
