import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Chip, FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";

import { TimeFrameSelector } from "@/components/controls/TimeFrameSelector";
import { TitleBar } from "@/components/dashboard/TitleBar";
import BasicStats from "@/pages/phishing-simulation/dashboard/BasicStats/BasicStats";
import DepartmentSelector from "@/pages/phishing-simulation/dashboard/DepartmentSelector";
import Stats from "@/pages/phishing-simulation/dashboard/Stats";
import UserPerLevel from "@/pages/phishing-simulation/dashboard/UserPerLevel";
import UsersTable from "@/pages/phishing-simulation/dashboard/UsersTable";

import ClickRatePerDepartment from "./ClickRatePerDepartment";
import ClickRatePerLocation from "./ClickRatePerLocation";
import {
  useClickRatePerDepartmentQuery,
  useClickRatePerLocationQuery,
} from "./api";
import useSimulationDashboard from "./hooks/useSimulationDashboard";

export default function Dashboard({
  isCorporateView,
}: {
  isCorporateView: boolean;
}) {
  const { t } = useTranslation();
  const {
    timeFrame,
    activeUsersOnly,
    department,
    monthOrWeek,
    date,
    dateFrom,
    dateTo,
    setActiveUsersOnly,
    setTimeFrame,
    setDepartment,
    setDate,
  } = useSimulationDashboard();
  const { data: clickRatePerLocationData } = useClickRatePerLocationQuery({
    dateFrom,
    dateTo,
    department,
  });
  const {
    data: locationData,
    isLoading: isLoadingLocation,
    isError: isErrorLocation,
  } = useClickRatePerLocationQuery({
    dateFrom,
    dateTo,
    department,
  });
  const {
    data: departmentData,
    isLoading: isLoadingDepartment,
    isError: isErrorDepartment,
  } = useClickRatePerDepartmentQuery({
    dateFrom,
    dateTo,
    department,
  });

  const isClickRatePerLocationVisible = useMemo(
    () =>
      clickRatePerLocationData &&
      clickRatePerLocationData.length === 1 &&
      Object.keys(clickRatePerLocationData)[0] === "UNKNOW",
    [clickRatePerLocationData],
  );
  const gridItemSize = isClickRatePerLocationVisible ? 4 : 6;

  const resetPeriodValue = () => setDate(null);

  return (
    <>
      <TitleBar
        title={
          isCorporateView
            ? t("Aggregated Phishing Simulation Dashboard")
            : t("Phishing Simulation Dashboard")
        }
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={activeUsersOnly}
                onChange={(event) => setActiveUsersOnly(event.target.checked)}
              />
            }
            label={t("Show active users only")}
          />
        </FormGroup>
        {date && (
          <Chip
            style={{ width: "200px" }}
            label={
              monthOrWeek === "week"
                ? t("Selected week: {{date, datetime}}")
                : t(
                    "Selected month: {{date, datetime(year:numeric; month:2-digit)}}",
                  )
            }
            onDelete={resetPeriodValue}
            variant="outlined"
            color="primary"
          />
        )}

        {!isCorporateView && (
          <DepartmentSelector
            value={department}
            onChange={(department: string) => {
              setDepartment(department);
              resetPeriodValue();
            }}
          />
        )}
        <TimeFrameSelector value={timeFrame} onChange={setTimeFrame} />
      </TitleBar>

      <Grid container>
        <Grid item xs={12}>
          <Stats isCorporateView={isCorporateView} />
        </Grid>
        <Grid item xs={12}>
          <BasicStats
            dateFrom={dateFrom}
            dateTo={dateTo}
            department={department}
            isCorporateView={isCorporateView}
            activeUsersOnly={activeUsersOnly}
          />
        </Grid>

        {!isCorporateView && (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={gridItemSize}>
                  <UserPerLevel
                    department={department}
                    activeUsersOnly={activeUsersOnly}
                  />
                </Grid>
                <Grid item xs={gridItemSize}>
                  <ClickRatePerDepartment
                    data={departmentData}
                    isLoading={isLoadingDepartment}
                    isError={isErrorDepartment}
                  />
                </Grid>
                {isClickRatePerLocationVisible && (
                  <Grid item xs={gridItemSize}>
                    <ClickRatePerLocation
                      data={locationData}
                      isLoading={isLoadingLocation}
                      isError={isErrorLocation}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <UsersTable />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
