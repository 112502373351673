import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, CardActionArea, Grid } from "@mui/material";

import { CourseCardsContainer } from "@/pages/awareness/courses/courses-catalog/CourseCardsContainer";
import { ModalCourseAssignation } from "@/pages/awareness/courses/courses-catalog/ModalCourseAssignation";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { CourseType } from "@/types/awareness";
import { useUserContext } from "@/utils/contexts/UserContext";

export const CourseCardsContainerList = ({
  isPublicWebchat,
  courses,
  learningUserInfos,
}) => {
  const { permissions } = useUserContext();
  const isCourseCatalogAdmin = permissions.includes(
    Permission.AWARENESS_COURSE_CATALOG_ADMIN,
  );
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const onClickAssignment = (course: CourseType) => {
    setSelectedCourse(course);
    setOpenModal(true);
  };
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      {courses.map((course: CourseType) => (
        <Grid
          key={course.id}
          item
          xs={12}
          sx={{ marginLeft: "3rem", marginRight: "3rem" }}
        >
          {isCourseCatalogAdmin && !isPublicWebchat ? (
            <CardActionArea
              disableRipple
              sx={{ marginTop: "1rem" }}
              onClick={() => navigate(`/awareness/courses/${course.id}`)}
            >
              <CourseCardsContainer
                isPublicWebchat={isPublicWebchat}
                learningUserInfos={learningUserInfos}
                course={course}
                onClickAssignment={onClickAssignment}
              />
            </CardActionArea>
          ) : (
            <Box sx={{ marginTop: "1rem" }}>
              <CourseCardsContainer
                isPublicWebchat={isPublicWebchat}
                learningUserInfos={learningUserInfos}
                course={course}
                onClickAssignment={onClickAssignment}
              />
            </Box>
          )}
        </Grid>
      ))}
      {selectedCourse && (
        <ModalCourseAssignation
          openModal={openModal}
          learningUserInfos={learningUserInfos}
          setOpenModal={setOpenModal}
          selectedCourse={selectedCourse}
        />
      )}
    </Grid>
  );
};
