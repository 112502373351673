import React from "react";
import { useTranslation } from "react-i18next";

import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";

import { FullPageSpinner } from "@/components/Spinner";
import Attachments from "@/pages/threats/components/details/Attachments";
import Content from "@/pages/threats/components/details/Content";
import { DKIMStatus } from "@/pages/threats/components/details/DKIMStatus";
import Metadata from "@/pages/threats/components/details/Metadata";
import { DetailedThreat } from "@/pages/threats/service/types";

type ThreatsDetailsProps = {
  isLoading?: boolean;
  threat?: DetailedThreat;
};

const Details = ({ isLoading = false, threat = null }: ThreatsDetailsProps) => {
  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (threat == null) {
    return <NoDataAvailable />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        padding: ".5rem",
      }}
    >
      <Metadata threat={threat} />
      <Stack direction="row" justifyContent="space-between">
        <Attachments attachments={threat.details.attachments} />
        <DKIMStatus dkimStatus={threat.details.dkimStatus} />
      </Stack>
      <Divider />
      <Content threat={threat} />
    </Box>
  );
};

const NoDataAvailable = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography fontSize="20pt">
        {t("Please select a threat to view its details.")}
      </Typography>
    </Box>
  );
};

export default Details;
