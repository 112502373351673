import React from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { appDispatch, appSelector } from "@/store";

import {
  selectTeamsPoliciesReviewed,
  updateTeamsPoliciesReviewed,
} from "../../SetupChecklistSlice";
import { useUpdateTeamsPolicyMutation } from "../../awarenessChecklistApi";

const TeamsPolicies = () => {
  const { t } = useTranslation();
  const teamsPoliciesReviewed = appSelector(selectTeamsPoliciesReviewed);
  const [triggerUpdateTeamsPolicyMutation] = useUpdateTeamsPolicyMutation();
  const dispatch = appDispatch();

  const onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    triggerUpdateTeamsPolicyMutation({
      has_confirmed_awareness_teams_policies: checked,
    }).then(() => {
      dispatch(updateTeamsPoliciesReviewed(checked));
    });
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t("Teams Policies")}</Typography>
      <Box>
        <Typography component="div">
          <Trans>
            You can review the teams policies with the help of the{" "}
            <Link
              target="_blank"
              href="https://scribehow.com/page/Alex_Troubleshooting_Teams__RUR8K8bOQcmHczGT7phRkA"
            >
              documentation
            </Link>
          </Trans>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={teamsPoliciesReviewed}
              onChange={onClick}
              name="teams-policies"
            />
          }
          label={t("I have reviewed my teams policies")}
        />
      </Box>
    </Stack>
  );
};

export default TeamsPolicies;
