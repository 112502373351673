import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { useFetchListCustomCampaignsQuery } from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignApi";
import { resetState } from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignCreate/CustomCampaignSlice";
import CustomCampaignList from "@/pages/phishing-simulation/CustomCampaignV2/CustomCampaignList";

export default function CustomCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data, isFetching } = useFetchListCustomCampaignsQuery();

  const onClickCreate = () => {
    dispatch(resetState());
    navigate(location.pathname + "/create");
  };

  return (
    <Card>
      <CardHeader
        title={t("Custom Campaign")}
        action={
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={onClickCreate}
          >
            {t("Create new campaign")}
          </Button>
        }
      />
      <Divider />
      <CardContent style={{ height: "100%" }}>
        {data?.campaigns?.length ? (
          <>
            <Box height={"10px"}>{isFetching && <LinearProgress />}</Box>
            <CustomCampaignList listCampaigns={data.campaigns} />
          </>
        ) : (
          <Box
            display={"flex"}
            height={"80%"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            {isFetching ? (
              <CircularProgress />
            ) : (
              <>
                <IconButton
                  aria-label="create-new-campaign"
                  color="primary"
                  onClick={onClickCreate}
                >
                  <NoteAddOutlinedIcon sx={{ fontSize: "5rem" }} />
                </IconButton>
                <Typography sx={{ fontWeight: "unset", fontSize: "16px" }}>
                  {t("Click on")}{" "}
                  <strong>“{t("Create a new campaign")}”</strong>{" "}
                  {t("to create your first phishing campaign")}
                </Typography>
              </>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
