import { generateBrowserPath } from "../common";
import { userScriptContents } from "../content/legacy";

export function generateUserPowerShellScript(
  browser: string,
  extensionID: string,
  enterpriseToken: string,
): string {
  const browserSpecificPath = generateBrowserPath(browser, extensionID);
  return userScriptContents(enterpriseToken, browserSpecificPath);
}
