import React from "react";
import { useTranslation } from "react-i18next";

import { Chip, Tooltip } from "@mui/material";

export default function InstallTypeChip({ user, riskColors }) {
  const { t } = useTranslation();

  // t("extension was installed because of an administrative policy")
  // t("extension was installed normally from a store")
  // t("extension was loaded unpacked in developer mode")
  // t("extension was installed by other software on the machine")
  const cause = t(user.risk_level.cause);

  // t("admin")
  // t("development")
  // t("normal")
  // t("sideload")
  // t("other")
  const install_type = t(user.install_type);

  return (
    <Tooltip title={cause}>
      <Chip
        sx={{
          color: riskColors[user.risk_level.level].textColor,
          bgcolor: riskColors[user.risk_level.level].bgColor,
          borderColor: riskColors[user.risk_level.level].textColor,
          padding: "0 6px",
          width: "auto",
        }}
        style={{ cursor: "help" }}
        variant="outlined"
        label={install_type}
      />
    </Tooltip>
  );
}
