import React from "react";
import { useTranslation } from "react-i18next";

import { EnumChip } from "@/components/EnumChip";
import { ContactType } from "@/pages/banners/remote-apis/contact_types";

export function TypeCell({ value }: { value: ContactType }) {
  const { t } = useTranslation();

  const contactTypeChipConfig = {
    [ContactType.domain]: { color: "primary", label: t("Safe Domain") },
    [ContactType.email_address]: {
      color: "charts.secondary",
      label: t("Safe Senders"),
    },
  };

  return <EnumChip size="medium" value={value} defs={contactTypeChipConfig} />;
}
