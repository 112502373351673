import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { ConsentButton } from "@/pages/general-settings/Office/ConsentButton";
import { useUserContext } from "@/utils/contexts/UserContext";

const USER_SYNC_APP = {
  name: "UserSync",
  clientId: import.meta.env.VITE_APP_AZURE_MANTRA_CLIENT_ID,
};
const MAILER_APP = {
  name: "Mailer",
  clientId: import.meta.env.VITE_APP_AZURE_MAILER_CLIENT_ID,
};

const OFFICE_CONTROLLER_APP = {
  name: "OfficeController",
  clientId: import.meta.env.VITE_APP_AZURE_OFFICE_CONTROLLER_CLIENT_ID,
};

export function Consent() {
  // Perform the request to retrieve all the badges for the user
  const { getAccessTokenSilently } = useAuth0();
  const user = useUserContext();
  const [consent, setConsent] = useState({
    userSync: undefined,
    mailer: undefined,
    officeController: undefined,
  });
  const [secretNonce, setSecretNonce] = useState({
    UserSync: null,
    Mailer: null,
    OfficeController: null,
  });
  const { t } = useTranslation();

  useEffect(() => {
    // Retrieve key status
    const retrieveOfficeConsentStatus = async () => {
      try {
        const response = await axios.get(
          import.meta.env.VITE_APP_ENDPOINT_SIMULATION +
            "/users/sync/office/consent_status",
        );
        const allowedApps = response.data.apps || [];
        setSecretNonce(
          response.data.nonces || {
            UserSync: null,
            Mailer: null,
            OfficeController: null,
          },
        );
        setConsent({
          userSync: allowedApps.includes(USER_SYNC_APP.name),
          mailer: allowedApps.includes(MAILER_APP.name),
          officeController: allowedApps.includes(OFFICE_CONTROLLER_APP.name),
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    retrieveOfficeConsentStatus();
    const id = setInterval(() => retrieveOfficeConsentStatus(), 3000); // Run every 3 seconds

    return () => clearInterval(id);
  }, [getAccessTokenSilently]);

  let body;
  if (!user) {
    body = <div>{t("Loading")}...</div>;
  } else {
    const statusText = (consent) =>
      consent === true
        ? t("Already granted")
        : consent === false
          ? t("Not granted yet")
          : t("Unknown status");
    const statusColor = (consent) =>
      consent === true ? "green" : consent === false ? "red" : "gray";
    body = (
      <div>
        <div>
          <div>
            {t("Allow Mantra to retrieve your users and groups properties.")}
          </div>
          <Typography style={{ color: "black" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: ".5rem",
              }}
            >
              <ConsentButton
                app={USER_SYNC_APP}
                details={t("Users Sync")}
                nonce={secretNonce.UserSync}
              />
              <div
                className=" ml-3"
                style={{ color: statusColor(consent.userSync) }}
              >
                {statusText(consent.userSync)}
              </div>
            </div>
          </Typography>
        </div>
        <div className="mt-3">
          <div>{t("Allow Mantra direct connection to your users inbox.")}</div>
          <Typography style={{ color: "black" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: ".5rem",
              }}
            >
              <ConsentButton
                app={MAILER_APP}
                details={t("Direct Message Injection")}
                nonce={secretNonce.Mailer}
              />
              <div
                className=" ml-3"
                style={{ color: statusColor(consent.mailer) }}
              >
                {statusText(consent.mailer)}
              </div>
            </div>
          </Typography>
        </div>
        <div className={"mt-3"}>
          <div>{t("Allow Mantra to display banners on incoming emails.")}</div>
          <Typography style={{ color: "black" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: ".5rem",
              }}
            >
              <ConsentButton
                app={OFFICE_CONTROLLER_APP}
                details={"Email Process"}
                nonce={secretNonce.OfficeController}
              />
              <div
                className=" ml-3"
                style={{ color: statusColor(consent.officeController) }}
              >
                {statusText(consent.officeController)}
              </div>
            </div>
          </Typography>
        </div>
      </div>
    );
  }
  return body;
}
