import React from "react";

import { Box, Tooltip } from "@mui/material";

import { EnumChip } from "@/components/EnumChip";
import {
  ExtensionStatusType,
  useExtensionsStatusColors,
} from "@/pages/browser-defender/components/extentionStatus";

export default function ExtensionStatusChip({
  status,
}: {
  status: ExtensionStatusType;
}) {
  const statusColors = useExtensionsStatusColors();

  return (
    <Box sx={{ textAlign: "center" }}>
      <Tooltip title={statusColors[status].label}>
        <EnumChip value={status} defs={statusColors} />
      </Tooltip>
    </Box>
  );
}
