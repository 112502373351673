export enum MessagingPlatform {
  SLACK = "slack",
  TEAMS = "teams",
  WEBCHAT = "webchat",
}

export type AwarenessSetupChecklist = {
  messagingPlatform: MessagingPlatform | null;
  oneCourseAssigned: boolean;
  courseDeliveryActivated: boolean;
  messagingPlatformInstalled: boolean;
  isLoading: boolean;
  teamsPoliciesReviewed: boolean;
};

export enum AwarenessStepperStateKey {
  MESSAGING_PLATFORM,
  TEAMS_POLICIES,
  MESSAGING_PLATFORM_INSTALLED,
  ONE_COURSE_ASSIGNED,
  COURSE_DELIVERY_ACTIVATED,
}
