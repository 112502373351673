import { useSnackbar } from "material-ui-snackbar-provider";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";

import { useCloneCourseMutation } from "@/api";
import { ButtonSpinner } from "@/components/Spinner";
import CopyIcon from "@/components/icons/CopyIcon";

import { selectCourseId, selectEditMode } from "../courseSlice";

const CloneEditorHeader = () => {
  const courseId = useSelector(selectCourseId);
  const editMode = useSelector(selectEditMode);
  const [cloneCourse, { isLoading: isLoadingCloneCourse }] =
    useCloneCourseMutation();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const onClickCloneCourse = async () => {
    if (isLoadingCloneCourse) {
      return;
    }
    const response = await cloneCourse(courseId);

    // @ts-ignore
    if (response?.data?.id == null) {
      snackbar.showMessage("Error unable to clone course");
    }

    // @ts-ignore
    const id = response.data.id;
    snackbar.showMessage(t("course successfully cloned"));
    navigate(`/awareness/courses/${id}?cloned=true`);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      {!editMode ? (
        <Button
          size="small"
          variant="outlined"
          color="neutral"
          startIcon={isLoadingCloneCourse ? <ButtonSpinner /> : <CopyIcon />}
          disabled={isLoadingCloneCourse}
          onClick={onClickCloneCourse}
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("Clone course")}
        </Button>
      ) : (
        ""
      )}
    </Box>
  );
};

export default CloneEditorHeader;
