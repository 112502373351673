import React from "react";
import { useTranslation } from "react-i18next";

import CopyAllIcon from "@mui/icons-material/CopyAll";
import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import { EmailHeaderNameValue } from "@/pages/threats/service/domain";

const EmailHeader = ({ header }: { header: EmailHeaderNameValue }) => {
  return (
    <Typography>
      <strong>{header.name}</strong>: {header.value}
    </Typography>
  );
};

export const CopyHeadersToClipboardButton = ({
  headers,
}: {
  headers: EmailHeaderNameValue[];
}) => {
  const { t } = useTranslation();
  const copyToClipboard = () => {
    const headersToExport = headers.reduce(
      (key, header) => `${key}${header.name}:  ${header.value}\n`,
      "",
    );
    navigator.clipboard.writeText(headersToExport);
  };

  return (
    <IconButton title={t("Copy Headers to Clipboard")}>
      <CopyAllIcon onClick={copyToClipboard} />
    </IconButton>
  );
};

const EmailHeaders = ({ headers }: { headers: EmailHeaderNameValue[] }) => {
  return (
    <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
      {headers.map((header) => (
        <EmailHeader key={header.name + header.value} header={header} />
      ))}
    </Box>
  );
};

export default EmailHeaders;
