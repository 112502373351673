import React from "react";

import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Box, Divider, Tooltip, Typography } from "@mui/material";

import { GeneralInfoLine } from "@/pages/browser-defender/userDetails/GeneralInfoLine";

export function GeneralInfoTable({ data }) {
  const activeInfo = (
    <Tooltip
      title={data.active ? "Active" : "Inactive"}
      style={{ cursor: "help" }}
    >
      {data.active ? (
        <PersonIcon sx={{ color: "#1dc9b7" }} />
      ) : (
        <PersonOffIcon sx={{ color: "#EE97A7" }} />
      )}
    </Tooltip>
  );

  const groupsInfo = (
    <Tooltip title={data.groups.join(", ")} style={{ cursor: "help" }}>
      <Typography
        noWrap
        sx={{ display: "inline-block", textAlign: "right", width: "50%" }}
      >
        {data.groups.join(", ")}
      </Typography>
    </Tooltip>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <GeneralInfoLine
        label="Name"
        text={
          <>
            {activeInfo} {data.firstname} {data.lastname}
          </>
        }
      />
      <GeneralInfoLine label="Email" text={data.email} />

      <GeneralInfoLine label="Groups" value={groupsInfo} />

      <Divider sx={{ m: "2rem" }} />

      <GeneralInfoLine
        label="First installation"
        text={data.first_installation}
      />
      <GeneralInfoLine
        label="Latest installation"
        text={data.latest_installation}
      />
      <GeneralInfoLine label="Latest heartbeat" text={data.latest_heartbeat} />
    </Box>
  );
}
