import { ReactNode } from "react";

import { T } from "@/components/LocalizedString";

export type EngagementStatus =
  | "engaged"
  | "engage_started"
  | "disengaged"
  | "disengage_started"
  | "disabled";

export type BannersUserDisableReason =
  | "office-no-external-id"
  | "enabled"
  | "manually-disabled"
  | "mail-client-unsupported"
  | "invalid-provider-access"
  | "too-many-filters"
  | "temporary-disabled"
  | "unknown";

export interface APIBannerUser {
  firstname: string;
  lastname: string;
  email: string;
  departments: Array<string>;
  language: string;
  banners_engagement_status: EngagementStatus;
  disable_reason: BannersUserDisableReason;
}

export interface BannerToggleUsers {
  user_email: string;
  active: boolean;
}

export function bannersUserDisableReasonLabel(
  reason: BannersUserDisableReason,
): ReactNode {
  const labels = {
    "office-no-external-id": T(
      "BannersUserDisableReason.office-no-external-id",
    ),
    enabled: T("BannersUserDisableReason.enabled"),
    "manually-disabled": T("BannersUserDisableReason.manually-disabled"),
    "mail-client-unsupported": T(
      "BannersUserDisableReason.mail-client-unsupported",
    ),
    "invalid-provider-access": T(
      "BannersUserDisableReason.invalid-provider-access",
    ),
    "too-many-filters": T("BannersUserDisableReason.too-many-filters"),
    "temporary-disabled": T("BannersUserDisableReason.temporary-disabled"),
    unknown: T("BannersUserDisableReason.unknown"),
  };

  if (Object.keys(labels).includes(reason)) {
    return labels[reason];
  } else {
    return labels.unknown;
  }
}
