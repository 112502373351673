import React from "react";
import { useTranslation } from "react-i18next";

import { Warning } from "@mui/icons-material";
import { Switch, Tooltip } from "@mui/material";

import { ButtonSpinner } from "@/components/Spinner";
import {
  BannerToggleUsers,
  BannersUserDisableReason,
  EngagementStatus,
  bannersUserDisableReasonLabel,
} from "@/pages/banners/remote-apis/bannerUsers";

type EngageSwitchProps = {
  handleEngageOrDisengageUser: (params: BannerToggleUsers) => void;
  disabled: boolean;
  status: EngagementStatus;
  email: string;
  disable_reason: BannersUserDisableReason;
};

export const EngageSwitch = ({
  handleEngageOrDisengageUser,
  disabled = false,
  status,
  email,
  disable_reason = "unknown",
}: EngageSwitchProps) => {
  const { t } = useTranslation();
  const disableState = status === "disabled";

  const isTransientState =
    status === "engage_started" || status === "disengage_started";

  const switch_disabled = disabled || disableState || isTransientState;

  const shouldEngageOnCLick = status === "disengaged";
  const checked = status === "engaged" || status === "engage_started";

  const handleChange = () => {
    handleEngageOrDisengageUser({
      user_email: email,
      active: shouldEngageOnCLick,
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip
        title={
          disabled
            ? t("Banners Setup not finished: permissions not Granted")
            : t("Engage mailbox")
        }
      >
        <span>
          <Switch
            size="small"
            disabled={switch_disabled}
            checked={checked}
            onChange={handleChange}
          />
        </span>
      </Tooltip>
      {isTransientState && (
        <ButtonSpinner size="1rem" color="primary" thickness={8} />
      )}
      {disableState && (
        <Tooltip title={bannersUserDisableReasonLabel(disable_reason)}>
          <Warning color="disabled" />
        </Tooltip>
      )}
    </div>
  );
};
