import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FullPageSpinner } from "@/components/Spinner";
import {
  SetupChecklist,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import {
  selectIsLoading,
  selectMessagingPlatform,
  selectStepperFromChecklistState,
} from "@/pages/awareness/setup/SetupChecklistSlice";

import { MessagingPlatform } from "../SetupChecklistTypes";
import { useSetupStatusQuery } from "../awarenessChecklistApi";
import AssignCourse from "./stepComponents/AssignCourse";
import CourseDeliveryActivated from "./stepComponents/CourseDeliveryActivated";
import MessagingPlatformInstalled from "./stepComponents/MessagingPlatformInstalled";
import SelectedMessagingPlatform from "./stepComponents/SelectedMessagingPlatform";
import TeamsPolicies from "./stepComponents/TeamsPolicies";

const useAwarenessChecklist = () => {
  const { isError } = useSetupStatusQuery();
  const steps = useSelector(selectStepperFromChecklistState);
  const isLoading = useSelector(selectIsLoading);

  return {
    steps,
    isLoading,
    isError,
  };
};

const AwarenessCurrentComponent: React.FC = () => {
  const messagingPlatform = useSelector(selectMessagingPlatform);
  const checklist = useContext(SetupChecklistContext);

  const components = useMemo(() => {
    if (messagingPlatform === MessagingPlatform.TEAMS) {
      return [
        <SelectedMessagingPlatform key={0} />,
        <TeamsPolicies key={1} />,
        <MessagingPlatformInstalled key={2} />,
        <AssignCourse key={3} />,
        <CourseDeliveryActivated key={4} />,
      ];
    } else {
      return [
        <SelectedMessagingPlatform key={0} />,
        <MessagingPlatformInstalled key={1} />,
        <AssignCourse key={2} />,
        <CourseDeliveryActivated key={3} />,
      ];
    }
  }, [messagingPlatform]);

  const currentComponent = useMemo(
    () => components[checklist.activeIndex],
    [components, checklist.activeIndex],
  );

  return currentComponent;
};

const AwarenessSetupChecklist: React.FC = () => {
  const { steps, isLoading, isError } = useAwarenessChecklist();
  const { t } = useTranslation();

  const disableChangeStepOnClick = useMemo(() => {
    if (steps.length === 0) {
      return true;
    }
    return steps[0].status !== "succeeded";
  }, [steps]);

  return (
    <>
      {isLoading && !isError ? (
        <FullPageSpinner />
      ) : (
        <SetupChecklist
          steps={steps}
          disableGoToLastInvalidStep={!disableChangeStepOnClick}
          disableChangeStepOnClick={disableChangeStepOnClick}
        >
          <AwarenessCurrentComponent />
        </SetupChecklist>
      )}
      {isError ? t("An error occurred") : null}
    </>
  );
};

export default AwarenessSetupChecklist;
