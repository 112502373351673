import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button, Tooltip } from "@mui/material";

import {
  usePublishAllLocalizedCoursesMutation,
  usePublishLocalizedCourseMutation,
} from "@/api";
import { ButtonSpinner } from "@/components/Spinner";
import EyeIcon from "@/components/icons/EyeIcon";
import SaveIcon from "@/components/icons/SaveIcon";
import { PublishLocalizedCourseModal } from "@/pages/awareness/courses/course-editor/PublishLocalizedCourseModal";
import { useTestLocalizedCourse } from "@/pages/awareness/courses/course-editor/hooks";

import {
  selectAvailablePublishedLanguages,
  selectCourseId,
  selectCurrentLanguage,
  selectCurrentLocalizedCourse,
  selectDraftLocalizedCourses,
  selectHasInteractions,
  toggleEditMode,
} from "../courseSlice";

const SeeSaveEditorHeader = () => {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const courseId = useSelector(selectCourseId);
  const availablePublishedCourses = useSelector(
    selectAvailablePublishedLanguages,
  );
  const draftLocalizedCourses = useSelector(selectDraftLocalizedCourses);
  const hasInteractions = useSelector(selectHasInteractions);
  const dispatch = useDispatch();
  const localizedCourse = useSelector(selectCurrentLocalizedCourse);
  useTestLocalizedCourse();
  const [publishLocalized, { isLoading: isLoadingPublish }] =
    usePublishLocalizedCourseMutation();
  const [publishAll, { isLoading: isLoadingPublishAll }] =
    usePublishAllLocalizedCoursesMutation();
  const snackbar = useSnackbar();
  const [showPublishModal, setShowPublishModal] = useState(false);

  const canDeployChange =
    hasInteractions &&
    localizedCourse?.name?.length > 0 &&
    localizedCourse?.description?.length > 0;
  const { t } = useTranslation();

  const doPublishCourse = async (all: boolean = false) => {
    let response;
    try {
      if (all) {
        response = await publishAll({
          id: courseId,
        });
      } else {
        response = await publishLocalized({
          id: localizedCourse.id,
          language: localizedCourse.language,
        });
      }
      if (response.error !== undefined) {
        snackbar.showMessage(t("Unable to publish course"));
      } else {
        snackbar.showMessage(t("Course successfully published"));
      }
    } catch {
      snackbar.showMessage(t("Unable to publish course"));
    }
  };

  const onClickPublishCourse = async () => {
    const draftsNumbers = (Object.keys(draftLocalizedCourses) ?? []).length;
    if (draftsNumbers > 1) {
      setShowPublishModal(true);
    } else {
      doPublishCourse(false);
    }
  };

  return (
    <>
      <Tooltip
        title={
          !availablePublishedCourses.includes(currentLanguage)
            ? t(
                "you need a published version in order to see the current version",
              )
            : ""
        }
        arrow
      >
        <span>
          <Button
            size="small"
            variant="outlined"
            color="neutral"
            onClick={() => dispatch(toggleEditMode())}
            disabled={!availablePublishedCourses.includes(currentLanguage)}
            startIcon={<EyeIcon />}
          >
            {t("See current version")}
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={
          !canDeployChange
            ? t(
                "a course needs name, description and interactions to be deployed",
              )
            : ""
        }
        arrow
      >
        <span>
          <Button
            size="small"
            variant="outlined"
            color="neutral"
            startIcon={isLoadingPublish ? <ButtonSpinner /> : <SaveIcon />}
            onClick={onClickPublishCourse}
            disabled={!canDeployChange || isLoadingPublish}
          >
            {t("Deploy changes")}
          </Button>
        </span>
      </Tooltip>
      <PublishLocalizedCourseModal
        show={showPublishModal}
        close={() => setShowPublishModal(false)}
        action={(all) => {
          doPublishCourse(all);
          setShowPublishModal(false);
        }}
        isLoading={isLoadingPublish || isLoadingPublishAll}
      />
    </>
  );
};

export default SeeSaveEditorHeader;
