import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  SetupChecklist as SetupChecklistComponent,
  SetupChecklistContext,
} from "@/components/stepper/SetupChecklist";
import { SetupStep } from "@/components/stepper/Stepper";
import { StepperStateKey } from "@/pages/browser-defender/setup/checklist/ChecklistTypes";
import { ManagedDeployment } from "@/pages/browser-defender/setup/checklist/ManagedDeployment";
import { ManualDeployment } from "@/pages/browser-defender/setup/checklist/ManualDeployment";

const CurrentComponent: React.FC<{ inactiveFeatureCallback: () => void }> = ({
  inactiveFeatureCallback,
}) => {
  const checklist = useContext(SetupChecklistContext);

  switch (checklist.activeIndex) {
    case StepperStateKey.MANUAL_DEPLOY:
      return (
        <ManualDeployment inactiveFeatureCallback={inactiveFeatureCallback} />
      );
    case StepperStateKey.MANAGED_DEPLOY:
      return (
        <ManagedDeployment inactiveFeatureCallback={inactiveFeatureCallback} />
      );
  }
};

export const SetupChecklist: React.FC<{
  inactiveFeatureCallback: () => void;
}> = ({ inactiveFeatureCallback }) => {
  const { t } = useTranslation();

  const steps: SetupStep[] = [
    { label: t("Manual deployment"), status: "uncompleted", mandatory: false },
    { label: t("Managed deployment"), status: "uncompleted", mandatory: false },
  ];

  return (
    <SetupChecklistComponent steps={steps}>
      <CurrentComponent inactiveFeatureCallback={inactiveFeatureCallback} />
    </SetupChecklistComponent>
  );
};
