import axios from "axios";
import { debounce } from "lodash";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  AccountBox,
  AddPhotoAlternate,
  Business,
  Http,
} from "@mui/icons-material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { ButtonSpinner } from "@/components/Spinner";
import { CompanySignature } from "@/pages/phishing-simulation/company/companysignature/CompanySignature";
import { KeyCustomers } from "@/pages/phishing-simulation/company/keycustomers/KeyCustomers";
import { KeyPeople } from "@/pages/phishing-simulation/company/keypeople/KeyPeople";
import { extractErrorMessage, useBackend } from "@/utils/misc";

const BasicInfoListItem = ({ icon, value }) => (
  <ListItem button>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={value} />
  </ListItem>
);

const ListItemTextField = styled(TextField)`
  & input {
    padding: 10px;
    color: rgb(100, 108, 154);
  }
`;

const LogoListItem = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [isLoadingLogo, setIsLoadingLogo] = useState(true);
  const [hasLogo, setHasLogo] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onFileSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    const file = event.target.files[0];
    if (file == null) {
      console.error("no file selected");
      return;
    }
    if (file.size > 2097152) {
      return snackbar.showMessage(t("File is too large."));
    }

    formData.append("logo", file);
    try {
      setIsLoadingLogo(true);
      await axios.post(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/company/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      snackbar.showMessage(
        t(
          "Logo updated successfully. It might take a few minutes to take effect.",
        ),
      );
      setHasLogo(true);
      setIsDialogOpen(false);
    } catch (error) {
      formData.delete("logo");
      // reset the input element
      event.target.value = "";
      event.target.type = "";
      event.target.type = "file";
      snackbar.showMessage(extractErrorMessage(error));
      setHasLogo(false);
    } finally {
      setIsLoadingLogo(false);
    }
  };

  const handlePreviewLogo = async () => {
    const newWindow = window.open("", "_blank");
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/company/preview_logo`,
      );
      newWindow.location = response.data;
    } catch (error) {
      snackbar.showMessage(extractErrorMessage(error));
    }
  };

  useEffect(
    function checkLogoExists() {
      axios
        .get(`${import.meta.env.VITE_APP_ENDPOINT_SIMULATION}/company/logo`)
        .then(() => {
          setHasLogo(true);
        })
        .catch(() => {
          setHasLogo(false);
        })
        .finally(() => {
          setIsLoadingLogo(false);
        });
    },
    [setHasLogo, setIsLoadingLogo],
  );

  let logoNode: ReactNode;
  if (hasLogo) {
    logoNode = (
      <Tooltip
        title={t("The company logo has been uploaded successfully")}
        arrow
      >
        <span>
          <CheckCircleOutlinedIcon color="success" />
        </span>
      </Tooltip>
    );
  } else {
    logoNode = (
      <Tooltip title={t("The company logo is not present")} arrow>
        <span>
          <WarningAmberOutlinedIcon color="orange" />
        </span>
      </Tooltip>
    );
  }

  return (
    <>
      <ListItem button>
        <ListItemIcon>
          <AddPhotoAlternate />
        </ListItemIcon>
        <Box display="flex" gap=".5rem" alignItems="center">
          <a
            onClick={handlePreviewLogo}
            style={{ marginRight: 10 }}
            title={t("Might take a few minutes to update")}
          >
            {t("Preview logo")}
          </a>
          {isLoadingLogo ? <ButtonSpinner /> : logoNode}
          <Button
            onClick={() => setIsDialogOpen(true)}
            disabled={isLoadingLogo}
          >
            {hasLogo ? t("Upload a new logo") : t("Upload logo")}
          </Button>
        </Box>
      </ListItem>
      <Dialog open={isDialogOpen}>
        <DialogTitle>{t("Upload logo")}</DialogTitle>
        <DialogContent>
          <input
            type="file"
            name="file"
            id="file"
            accept="image/*"
            onChange={onFileSelected}
          />
          <Typography variant="body_small" color="error">
            ({t("2MB Max")})
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDialogOpen(false)}
            disabled={isLoadingLogo}
          >
            {"Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CompanyGeneralInformations = () => {
  const { data, error, isPending } = useBackend(
    import.meta.env.VITE_APP_ENDPOINT_SIMULATION + "/company",
  );
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [domain, setDomain] = useState("");
  useEffect(() => {
    if (data) {
      setDomain(data?.user_defined_domain ?? data.domain);
    }
  }, [data]);

  const handleDomainChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newDomain = event.target.value;
    if (newDomain.length > 80) return;

    setDomain(newDomain);
    updateUserDefinedDomain(newDomain);
  };

  const updateUserDefinedDomain = useMemo(
    () =>
      debounce(async (user_defined_domain: string) => {
        try {
          await axios.put(
            `${
              import.meta.env.VITE_APP_ENDPOINT_SIMULATION
            }/company/change_domain`,
            { company: { user_defined_domain } },
          );
          snackbar.showMessage("Domain saved");
        } catch (error) {
          snackbar.showMessage(extractErrorMessage(error));
        }
      }, 1000),
    [snackbar],
  );

  if (isPending || !data)
    return (
      <ButtonSpinner
        size="2.5rem"
        thickness={4}
        color="neutral"
        sx={{ marginTop: "2rem" }}
      >
        {t("Loading")}...
      </ButtonSpinner>
    );
  if (error) return <div>{t("An error occurred")}</div>;
  if (data) {
    const company = data;
    return (
      <Stack gap={2}>
        <Card>
          <CardHeader title={t("Company Info")} />
          <CardContent>
            <h5>{t("Basic Info")}</h5>
            <div>
              <List component="nav">
                <BasicInfoListItem icon={<AccountBox />} value={company.name} />
                <LogoListItem />
                <BasicInfoListItem icon={<Http />} value={company.website} />
                <ListItem button>
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemTextField
                    variant="outlined"
                    value={domain || ""}
                    onChange={handleDomainChange}
                  />
                </ListItem>
              </List>
            </div>
          </CardContent>
        </Card>

        <KeyPeople />
        <CompanySignature />
        <KeyCustomers />
      </Stack>
    );
  }
};

export default CompanyGeneralInformations;
