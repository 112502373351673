import { useTranslation } from "react-i18next";

import { EnumChipDef } from "@/components/EnumChip";

export type ExtensionStatusType =
  | "active"
  | "disabled"
  | "inactive"
  | "partially_configured"
  | "unknown";

export const useExtensionsStatusColors = (): Record<
  ExtensionStatusType,
  EnumChipDef
> => {
  const { t } = useTranslation();

  return {
    active: { color: "#1dc9b7", label: t("Active") },
    disabled: { color: "#949291", label: t("Disabled by user") },
    inactive: { color: "#ee97a7", label: t("Last seen +30 days ago") },
    partially_configured: { color: "#5cD85a", label: t("Active w/o password") },
    unknown: { color: "#ee97a7", label: t("Never installed") },
  };
};
