import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import StickyHeader from "@/components/StickyHeader";
import { ActivateBrowserDefender } from "@/pages/browser-defender/components/ActivateBrowserDefender";
import DoughnutGraph from "@/pages/browser-defender/components/DoughnutGraph";
import GenericCard from "@/pages/browser-defender/components/GenericCard";
import DataTable from "@/pages/browser-defender/users/DataTable";
import { useUserContext } from "@/utils/contexts/UserContext";
import { downloadAsCSV } from "@/utils/downloadFile";

export function Users() {
  const [activeFeature, setActiveFeature] = useState(true);
  const [usageInfo, setUsageInfo] = useState({
    values: [],
    filteredValues: [],
    loading: true,
  });

  const [stats, setStats] = useState({
    values: { statuses: {}, versions: [] },
    loading: true,
    error: false,
  });

  const [selectedUserStatus, setSelectedUserStatus] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const info_user = useUserContext();
  const { t } = useTranslation();
  const statusMapping = {
    active: { color: "#1dc9b7", label: t("Active") },
    with_partial_defense: { color: "#5CD85A", label: t("Active w/o password") },
    disabled: { color: "#949291", label: t("Disabled by user") },
    inactive: { color: "#ee97a7", label: t("Last seen +30 days ago") },
    unprotected: { color: "#e76b82", label: t("Never installed") },
  };

  const fetchExtensionUsage = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const path =
        info_user.email === "demo@acme.com"
          ? "/demo/extension_users"
          : "/extension_users";

      const url = `${import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST}${path}`;

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setActiveFeature(false);
          return;
        }
      }

      setUsageInfo({
        values: resource.data,
        filteredValues: resource.data,
        loading: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [getAccessTokenSilently, info_user]);

  const fetchStats = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      const url = `${
        import.meta.env.VITE_APP_BROWSER_DEFENDER_HOST
      }/stats/extension_usage`;

      let resource;
      try {
        resource = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (e) {
        if (e.response.status === 400) {
          setStats({
            values: { statuses: {}, versions: [] },
            loading: false,
            error: true,
          });
          setActiveFeature(false);
          return;
        }
      }

      setStats({
        values: resource.data,
        loading: false,
        error: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!info_user.email) {
      return;
    }

    fetchStats();
    fetchExtensionUsage();
  }, [fetchStats, fetchExtensionUsage, info_user]);

  function exportData() {
    const titleKeys = Object.keys(usageInfo.filteredValues[0]);

    const refinedData = [];
    refinedData.push(titleKeys);

    usageInfo.filteredValues.forEach((item) => {
      refinedData.push(
        Object.values({
          ...item,
          active_deployments: JSON.stringify(item.active_deployments),
        }),
      );
    });

    let csvContent = "";
    refinedData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    downloadAsCSV(csvContent, t("Browser Defender - extension usage.csv"));
  }

  if (!activeFeature) {
    return (
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <div
          style={{ position: "absolute", left: "0%", right: "0%", top: "0%" }}
        >
          <ActivateBrowserDefender />
        </div>
      </div>
    );
  }

  return (
    <>
      <Box>
        <StickyHeader>
          <Stack
            direction="row"
            gap={3}
            justifyContent="end"
            alignItems="center"
          >
            <Typography
              variant="h2"
              style={{
                marginRight: "auto",
                maxHeight: "2rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {t("Users")}
            </Typography>
          </Stack>
        </StickyHeader>
        <Grid container alignItems="stretch">
          <Grid item md={6} sm={6} xs={12}>
            <GenericCard
              title={t("Users status")}
              isLoading={usageInfo.loading}
            >
              <DoughnutGraph
                data={stats.values.statuses}
                labelMapping={statusMapping}
                excluded={["total_users", "total_extensions"]}
                onClickCallbackAction={(value) => setSelectedUserStatus(value)}
              />
            </GenericCard>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <GenericCard
              title={t("Extension Versions")}
              isLoading={usageInfo.loading}
            >
              <DoughnutGraph
                data={stats.values.versions.reduce((acc, item) => {
                  acc[item.name] = item.count;
                  return acc;
                }, {})}
              />
            </GenericCard>
          </Grid>
          <Grid item xs={12}>
            <GenericCard
              title={t("Extension Usage Status")}
              isLoading={usageInfo.loading}
              action={
                <Button variant="outlined" onClick={() => exportData()}>
                  {t("Export")}
                </Button>
              }
            >
              <DataTable
                data={usageInfo}
                setData={setUsageInfo}
                userStatus={selectedUserStatus}
                userStatusLabel={
                  statusMapping[selectedUserStatus]
                    ? statusMapping[selectedUserStatus].label
                    : ""
                }
                setUserStatus={setSelectedUserStatus}
              />
            </GenericCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
