import { useSnackbar } from "material-ui-snackbar-provider";
import React from "react";
import { useTranslation } from "react-i18next";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export default function SaaSSelector({
  department,
  departments,
  setDepartments,
  saasList,
}) {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const onChange = (saasItems) => {
    // replace current department in the list of departments
    let departmentsRefreshed = [];
    for (let i = 0; i < departments.length; i++) {
      const aDepartment = departments[i];
      if (aDepartment.id === department.id) {
        // we should add the department in the current component instance
        department.tags = saasItems;
        departmentsRefreshed.push(department);
      } else {
        // add the department of the departmentList
        departmentsRefreshed.push(aDepartment);
      }
    }
    setDepartments(departmentsRefreshed);
    snackbar.showMessage(t("Department updated"));
  };

  return (
    <Autocomplete
      multiple
      options={saasList}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      onChange={(event, saasItems) => {
        onChange(saasItems);
      }}
      noOptionsText={t("No options")}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={department.tags}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            style={{ width: "auto", alignItems: "center" }}
            label={option.name}
            deleteIcon={<CancelIcon />}
            {...getTagProps({ index })}
          />
        ))
      }
      loadingText={t("Loading") + "..."}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: 800 }}
      renderInput={(params) => (
        <TextField {...params} fullWidth label="" placeholder="" />
      )}
    />
  );
}
