import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FullPageSpinner } from "@/components/Spinner";
import StickyHeader from "@/components/StickyHeader";
import { CourseCardsContainerList } from "@/pages/awareness/courses/courses-catalog/CourseCardsContainerList";
import { CourseFilterBar } from "@/pages/awareness/courses/courses-catalog/CourseFilterBar";
import { ErrorCatalogMessage } from "@/pages/awareness/courses/courses-catalog/ErrorCatalogMessage";
import { ResearchBar } from "@/pages/awareness/courses/courses-catalog/ResearchBar";
import { SubscribeAllModal } from "@/pages/awareness/courses/courses-catalog/SubscribeAllModal";
import {
  selectCourseBySearch,
  selectIsDemoAcme,
  selectUserInfos,
} from "@/pages/awareness/courses/courses-catalog/courseCatalogSlice";
import { CourseCardsContainerListDraggable } from "@/pages/awareness/courses/courses-catalog/demo-course-catalog/CourseCardsContainerListDraggable";
import {
  AUTH_ERROR_COURSE_CATALOG_TRANSLATIONS,
  EMPTY_COURSE_CATALOG_TRANSLATIONS,
} from "@/pages/awareness/courses/utils/translations";
import { Permission } from "@/pages/general-settings/permissions-attribution/permissions";
import { useUserContext } from "@/utils/contexts/UserContext";

import { useCourseCatalog } from "./hooks";

type CourseCatalogProps = {
  isPublicWebchat: boolean;
};

export const CoursesCatalog = ({ isPublicWebchat }: CourseCatalogProps) => {
  const { permissions } = useUserContext();
  const isCourseCatalogAdmin = permissions.includes(
    Permission.AWARENESS_COURSE_CATALOG_ADMIN,
  );
  const { isLoading, error, isAuthError } = useCourseCatalog(isPublicWebchat);
  const userInfos = useSelector(selectUserInfos);
  const [openModal, setOpenModal] = useState(false);
  const filteredCourse = useSelector(selectCourseBySearch);
  const isDemoAcme: boolean = useSelector(selectIsDemoAcme);
  const { t } = useTranslation();
  const auth_error_catalog_translation = useMemo(
    () =>
      AUTH_ERROR_COURSE_CATALOG_TRANSLATIONS[userInfos.language] ||
      AUTH_ERROR_COURSE_CATALOG_TRANSLATIONS["en"],
    [userInfos.language],
  );
  const empty_catalog_translation = useMemo(
    () =>
      EMPTY_COURSE_CATALOG_TRANSLATIONS[userInfos.language] ||
      EMPTY_COURSE_CATALOG_TRANSLATIONS["en"],
    [userInfos.language],
  );

  const onClickSubscribeAll = async () => {
    setOpenModal(true);
  };

  if (isLoading) {
    return <FullPageSpinner />;
  }
  if (isAuthError) {
    return (
      <ErrorCatalogMessage
        error_catalog_message_text={auth_error_catalog_translation}
      />
    );
  }
  if (error && !isAuthError) {
    return <div>{t("Error: Unable to fetch courses")}</div>;
  }

  return (
    <>
      {isCourseCatalogAdmin && !isPublicWebchat && (
        <StickyHeader height="120px">
          <ResearchBar onClickSubscribeAll={onClickSubscribeAll} />
          <CourseFilterBar />
        </StickyHeader>
      )}
      {filteredCourse.length > 0 ? (
        <>
          {isDemoAcme ? (
            <CourseCardsContainerListDraggable
              isPublicWebchat={isPublicWebchat}
              courses={filteredCourse}
              learningUserInfos={userInfos}
            />
          ) : (
            <CourseCardsContainerList
              isPublicWebchat={isPublicWebchat}
              courses={filteredCourse}
              learningUserInfos={userInfos}
            />
          )}
        </>
      ) : (
        <ErrorCatalogMessage
          error_catalog_message_text={empty_catalog_translation}
        />
      )}
      <SubscribeAllModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        filteredCourse={filteredCourse}
      />
    </>
  );
};
